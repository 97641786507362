import React, { useCallback, useMemo, useRef, useState } from 'react'
import { IPropType } from '../../core/api/dto/InfoBlockDto'
import { IExecutorsState } from './FormExecutors/FormExecutors'
import { EntityDataType } from '../../core/api/dto/EntityDto'

export type formStateItemType =
  | string
  | number
  | null
  | number[]
  | IPropType
  | IExecutorsState
  | EntityDataType
  | File
  | FileList

interface IAppFormHookReturn {
  ref: React.Ref<HTMLInputElement>
  formSubmit: () => void
  formState: IFormState
  formErrorState: IFormErrorState
  setFormErrorStateHandler: (value: IFormErrorState) => void
  formStateChangeHandler: (
    newState: {
      [key: string]: formStateItemType
    },
    isInitState?: boolean,
  ) => void
  formValidationHandler: (formState: IFormState) => { isValid: boolean }
  formClearErrors: () => void
  isFormDirty: boolean
  resetFormDirtyState: () => void
}

export interface IAppFormHookFiled {
  fieldName: string | number
  required?: string
  inputTitle?: string
}

interface IAppFormHook {
  formConfig: IAppFormHookFiled[]
}

interface IFormState {
  [key: string]: formStateItemType
}

export interface IFormErrorState {
  [key: string]: string | boolean
}

interface IFormDirtyState {
  [key: string]: boolean
}

interface IFormConfig {
  [key: string]: {
    required: string | boolean
  }
}

export const useFormHook = ({
  formConfig,
}: IAppFormHook): IAppFormHookReturn => {
  const submitRef = useRef<HTMLInputElement>(null)
  const initStates = useMemo(() => {
    const initFormState = {} as IFormState
    const initErrorState = {} as IFormErrorState
    const initIsDirtyState = {} as IFormDirtyState
    const config = {} as IFormConfig
    formConfig.forEach((field) => {
      initFormState[field.fieldName] = ''
      initErrorState[field.fieldName] = false
      initIsDirtyState[field.fieldName] = false
      config[field.fieldName] = {
        required: !!field.required ? field.required : false,
      }
    })
    return { initFormState, initErrorState, initIsDirtyState, config }
  }, [formConfig])
  const [formState, setFormState] = useState(initStates.initFormState)
  const [formErrorState, setFormErrorState] = useState(
    initStates.initErrorState,
  )
  const [formIsDirtyState, setFormIsDirtyState] = useState(
    initStates.initIsDirtyState,
  )
  const makeValidation = useCallback(
    (state: IFormState) => {
      const newErrorState: IFormErrorState = {}
      const newDirtyState: { [key: string]: boolean } = {}
      let isValid = true
      Object.keys(state).forEach((propName) => {
        newDirtyState[propName] = true
        if (
          (!!initStates.config[propName]?.required && state[propName] === '') ||
          (!!initStates.config[propName]?.required && state[propName] === null)
        ) {
          newErrorState[propName] = initStates.config[propName].required
          isValid = false
        } else if (
          !!initStates.config[propName]?.required &&
          state[propName] !== ''
        ) {
          newErrorState[propName] = false
        } else if (!initStates.config[propName]?.required) {
          newErrorState[propName] = false
        }
      })

      if (Object.keys(newErrorState).length > 0) {
        setFormErrorState((prev) => {
          let willUpdate = false
          Object.keys(newErrorState).forEach((errorKey) => {
            if (!willUpdate && !!newErrorState[errorKey] !== !!prev[errorKey]) {
              willUpdate = true
            }
          })
          return willUpdate
            ? {
                ...prev,
                ...newErrorState,
              }
            : prev
        })
      }

      if (Object.keys(newDirtyState).length > 0) {
        setFormIsDirtyState((prev) => {
          let willUpdate = false
          Object.keys(newDirtyState).forEach((dirtyKey) => {
            if (!willUpdate && newDirtyState[dirtyKey] !== prev[dirtyKey]) {
              willUpdate = true
            }
          })
          return willUpdate
            ? {
                ...prev,
                ...newDirtyState,
              }
            : prev
        })
      }
      return { isValid }
    },
    [initStates.config],
  )

  const formStateChangeHandler = useCallback(
    (newState: { [key: string]: formStateItemType }, isInitState = false) => {
      if (!isInitState) {
        makeValidation(newState)
      }

      setFormState((prev) => {
        const currentState: { [key: string]: formStateItemType } = {}
        Object.keys(newState).forEach((key) => {
          if (Object.keys(initStates.config).indexOf(key) >= 0) {
            currentState[key] = newState[key]
          }
        })
        return {
          ...prev,
          ...currentState,
        }
      })
    },
    [makeValidation],
  )

  const formValidationHandler = useCallback(
    (formState) => {
      return makeValidation(formState)
    },
    [makeValidation],
  )

  const formSubmit = () => {
    submitRef.current!.click()
  }
  const formClearErrors = () => {
    setFormErrorState(initStates.initErrorState)
  }

  const setFormErrorStateHandler = useCallback(
    (newErrorState: IFormErrorState) => {
      setFormErrorState((prev) => ({
        ...prev,
        ...newErrorState,
      }))
    },
    [],
  )

  const resetFormDirtyState = useCallback(() => {
    setFormIsDirtyState((prev) => {
      const result = {} as IFormDirtyState
      Object.keys(prev).forEach((key) => {
        result[key] = false
      })
      return result
    })
  }, [])

  const isFormDirty = useMemo(() => {
    let result = false
    Object.keys(formIsDirtyState).forEach((key) => {
      if (!result) {
        result = formIsDirtyState[key]
      }
    })
    return result
  }, [formIsDirtyState])

  return {
    ref: submitRef,
    formSubmit,
    formState,
    formStateChangeHandler,
    formErrorState,
    setFormErrorStateHandler,
    formValidationHandler,
    formClearErrors,
    isFormDirty,
    resetFormDirtyState,
  }
}
