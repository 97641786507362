import React, { useCallback, useEffect, useState } from 'react'
import { AppDropdown } from './AppDropdown'
import { translationLabelTape } from '../AppTranslation/AppTranslation'
import { useGetReferenceValuesForeignQuery } from '../../../core/api/BaseApiEndpoints/Entity/EntityApi'
import { IReferenceValuesSimple } from '../../../core/api/dto/EntityDto'

interface IDataTypeSelect {
  defaultValue: IReferenceValuesSimple | number | null
  onChange: (value: IReferenceValuesSimple | null) => void
  error?: translationLabelTape | boolean
  valueRows?: number
  label?: string
  disabled?: boolean
  dataType: string
}

export const ElementDataSelector = ({
  defaultValue,
  onChange,
  error,
  valueRows,
  label,
  disabled,
  dataType,
}: IDataTypeSelect) => {
  const { data: referenceValuesForeign } = useGetReferenceValuesForeignQuery({
    ForeignKey: dataType,
  })

  const [isInitState, setIsInitState] = useState(true)
  const [currentValue, setCurrentValue] =
    useState<IReferenceValuesSimple | null>(null)

  const changeHandler = useCallback(
    (value: IReferenceValuesSimple | null) => {
      onChange(value ? value : null)
    },
    [onChange],
  )

  useEffect(() => {
    if (defaultValue && referenceValuesForeign) {
      const result = referenceValuesForeign.find(
        (item) =>
          item.id ===
          (typeof defaultValue === 'number' ? defaultValue : defaultValue.id),
      )
      setCurrentValue(result ? result : null)
      if (isInitState) {
        setIsInitState(false)
      }
    } else if (referenceValuesForeign && !defaultValue && !isInitState) {
      setCurrentValue(null)
      changeHandler(null)
    }
  }, [referenceValuesForeign, defaultValue, changeHandler])
  return (
    <AppDropdown
      data={referenceValuesForeign ? referenceValuesForeign : []}
      label={label ? label : 'info_blocks_create_page__txt_form_field_type'}
      propToShowInList={'title'}
      value={currentValue}
      error={error}
      onChange={changeHandler}
      valueRows={valueRows}
      disabled={disabled}
      withFilter={true}
    />
  )
}
