import React, { useMemo } from 'react'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { HeaderSearchInput } from '../../../common/components/HeaderSearchInput/HeaderSearchInput'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { AppTable } from '../../../common/components/AppTable/AppTable'
import { AppTableCell } from '../../../common/components/AppTable/AppTableCell'
import {
  ITask,
  ITaskPriority,
  ITaskStatus,
} from '../../../core/api/dto/TasksDto'
import { useGetTasksQuery } from '../../../core/api/BaseApiEndpoints/Tasks/TasksApi'
import { colorByStatus } from '../../../common/components/Tasks/TaskStatus'
import { TableExecutors } from '../../../common/components/Tasks/TableExecutors'
import { colorByPriority } from '../../../common/components/PriorityWidget/PriorityWidget'
import { TaskCountDown } from '../../../common/components/Tasks/TaskCountDown'
import { TaskStatusBar } from '../../../common/components/Tasks/TaskStatusBar'
import { useSearchParamsHelper } from '../../../helpers/setSearchParam'
import { NavLink} from 'react-router-dom'
import { AppFilterDropdown } from '../../../common/components/AppDropdown/AppFilterDropdown'
import { statusSelectData } from '../../../common/components/AppDropdown/TaskStatusSelect'
import { prioritySelectData } from '../../../common/components/AppDropdown/TaskPrioritySelect'
import { PaperClipOutlined } from "@ant-design/icons"
import NewTable from 'common/components/AppTable/NewTable'

const tableConfig = {
  width: [100, 380, 200, 100, 200, 200, 230, 300],
}

const tableHeaders = [
  '№',
  'Название',
  'Приоритет',
  'Ответственный',
  'Дата создания',
  'Крайний срок',
  'Состояние',
  '',
]

export const Tasks = () => {
  const { searchParamsString } = useSearchParamsHelper({
    withPagination: true,
    shouldResetPaginationByParamsChanged: true,
  })

  const { data } = useGetTasksQuery({ searchParamsString })

  const tableSelectors = useMemo(() => {
    return [
      {
        renderItem: ({ status, id }: ITask) => (
          <AppTableCell>
            <div className="table-cell-title-with-status">
              <div className={`status-color-label ${colorByStatus[status]}`} />
              <span className="value">{id}</span>
            </div>
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ name, id, task_document, task_media }: ITask) => (
          <AppTableCell>
            <NavLink to={`/tasks/${id}`} className="value">
            { (task_media.length > 0 || task_document.length > 0) && <PaperClipOutlined />}
            {' '}{name}
            </NavLink>
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ priority }: ITask) => (
          <AppTableCell>
            {priority && (
              <span className={`value ${colorByPriority[priority]}`}>
                <AppTranslation label={`task_priority_${priority}`} />
              </span>
            )}
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ executor }: ITask) => (
          <AppTableCell>
            <TableExecutors
              data={executor}
              maxWidth={Number(tableConfig.width[2])}
            />
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ date_start }: ITask) => (
          <AppTableCell>
            <div>
              <AppTranslation
                label={'task_section_widget_date_formatter__txt_title'}
                options={{ date: date_start && new Date(date_start) }}
              />
            </div>
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ date_end }: ITask) => (
          <AppTableCell>
            <div>
              <AppTranslation
                label={'task_section_widget_date_formatter__txt_title'}
                options={{ date: date_end && new Date(date_end) }}
              />
            </div>
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ date_end, status }: ITask) => (
          <AppTableCell>
            <>
            {status.toUpperCase() != 'DONE' ?
            <TaskCountDown date_end={date_end} /> :
            <p>Готово</p>}
            </>
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ date_end, date_start, status }: ITask) => (
          <AppTableCell>
            {status.toUpperCase() != 'DONE' ?
            <TaskStatusBar date_end={date_end} date_start={date_start} />
            : ' '}
          </AppTableCell>
        ),
      },
    ]
  }, [])

  if (!data) {
    return null
  }

  return (
    <>
      <div className={'back-office-header'}>
        <h1>
          <AppTranslation label={AllRoutes.tasksListing.name} options={{}} />
        </h1>
        <div className={'header-actions-block'}>
          <div className="actions-left-block">
            <HeaderSearchInput
              searchParam={'search'}
              placeholder={'general__txt_search'}
            />
            <div className={'header-filters-block'}>
            {localStorage.getItem('activeItem') != 'Новые' && localStorage.getItem('activeItem') != 'Готово' && localStorage.getItem('activeItem') != 'В работе' && localStorage.getItem('activeItem') != 'Просрочено' &&(
              <AppFilterDropdown
                searchParam={'status'}
                propToShowInList={'name'}
                valuePropName={'value'}
                data={statusSelectData}
                placeHolder={'Статус'}
                style={{ width: 200 }}
                withFilter={true}
              />
            )}

              <AppFilterDropdown
                searchParam={'priority'}
                propToShowInList={'name'}
                valuePropName={'value'}
                data={prioritySelectData}
                placeHolder={'Приоритет'}
                style={{ width: 200 }}
                withFilter={true}
              />
            </div>
          </div>
          <div className="actions-right-block">
            <AppButton
              path={AllRoutes.tasksCreate.path}
              icoClassName={'an-ico-plus-thin'}
              title={{
                label: 'tasks_create_page__txt_nav_button_title',
              }}
              color={AppButtonColor.lightBlue}
              size={AppButtonSize.small}
            />
          </div>
        </div>
      </div>
      {/*<AppTable
        headerTitles={tableHeaders}
        cellWidth={tableConfig.width}
        tableDataSelectors={tableSelectors}
        tableAsTasks={true}
        data={data ? data : null}
        lastColAlignLeft={true}
      />*/}
      <NewTable headerTitles={tableHeaders} tableDataSelectors={tableSelectors} dataTable={data ? data : null}/>
    </>
  )
}
