import { IRoleState } from '../../api/dto/AuthDto'
import { Role } from '../../api/dto/RolesDto'

export type credentialsByStoreType = IRoleState & {
  [key in Role]?: {
    credentials: {
      access: string | null
      refresh: string | null
    }
  }
}

export const authInitialState: credentialsByStoreType = {
  [`${Role.ROLE_ADMIN}`]: {
    credentials: {
      access: null,
      refresh: null,
    },
  },
  currentRole: Role.ROLE_ADMIN,
}
