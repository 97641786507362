import React, { useMemo } from 'react'
import { AppTable } from '../../../common/components/AppTable/AppTable'
import { AppTableCell } from '../../../common/components/AppTable/AppTableCell'
import { ITask } from '../../../core/api/dto/TasksDto'
import { useGetTasksQuery } from '../../../core/api/BaseApiEndpoints/Tasks/TasksApi'
import { colorByStatus, TaskStatus } from '../../../common/components/Tasks/TaskStatus'
import { TableExecutors } from '../../../common/components/Tasks/TableExecutors'
import { colorByPriority, PriorityWidget } from '../../../common/components/PriorityWidget/PriorityWidget'
import { TaskCountDown } from '../../../common/components/Tasks/TaskCountDown'
import { TaskStatusBar } from '../../../common/components/Tasks/TaskStatusBar'
import { AppTranslation } from '../AppTranslation/AppTranslation'
import { NavLink } from 'react-router-dom'
import { PaperClipOutlined } from "@ant-design/icons"

const tableConfig = {
  width: [400, 100, 250, 230, 'auto'],
}

const tableHeaders = [
  '№',
  'Название',
  'Приоритет',
  'Ответственный',
  'Дата создания',
  'Дата окончания',
  'Состояние',
  '',
]

export const UserTasks = () => {

  const { data } = useGetTasksQuery({ searchParamsString: 'priority=HIGH&limit=3&status=OVERDUE'},)

  const tableSelectors = useMemo(() => {
    return [
      {
        renderItem: ({ status, id }: ITask) => (
          <AppTableCell>
            <div className="table-cell-title-with-status">
              <div className={`status-color-label ${colorByStatus[status]}`} />
              <span className="value">{id}</span>
            </div>
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ name, id, task_document, task_media }: ITask) => (
          <AppTableCell>
            <NavLink to={`/tasks/${id}`} className="value">
            { (task_media.length > 0 || task_document.length > 0) && <PaperClipOutlined />}
            {' '}{name}
            </NavLink>
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ priority }: ITask) => (
          <AppTableCell>
            {priority && (
              <span className={`value ${colorByPriority[priority]}`}>
                <AppTranslation label={`task_priority_${priority}`} />
              </span>
            )}
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ executor }: ITask) => (
          <AppTableCell>
            <TableExecutors
              data={executor}
              maxWidth={Number(tableConfig.width[2])}
            />
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ date_start }: ITask) => (
          <AppTableCell>
            <div>
              <AppTranslation
                label={'task_section_widget_date_formatter__txt_title'}
                options={{ date: date_start && new Date(date_start) }}
              />
            </div>
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ date_end }: ITask) => (
          <AppTableCell>
            <div>
              <AppTranslation
                label={'task_section_widget_date_formatter__txt_title'}
                options={{ date: date_end && new Date(date_end) }}
              />
            </div>
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ date_end, status }: ITask) => (
          <AppTableCell>
            <>
            {status.toUpperCase() != 'DONE' ?
            <TaskCountDown date_end={date_end} /> :
            <p>Готово</p>}
            </>
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ date_end, date_start, status }: ITask) => (
          <AppTableCell>
            {status.toUpperCase() != 'DONE' ?
            <TaskStatusBar date_end={date_end} date_start={date_start} />
            : ' '}
          </AppTableCell>
        ),
      },
    ]
  }, [])

  if (!data) {
    return null
  }


  return (
    <>
      <div className={'back-office-header'}>
        <h2>
          Задачи, требующие внимания
        </h2>
      </div>
      <AppTable
        headerTitles={tableHeaders}
        cellWidth={tableConfig.width}
        tableDataSelectors={tableSelectors}
        tableAsTasks={true}
        data={data ? data : null}
        lastColAlignLeft={true}
      />
    </>
  )
}
