import React from 'react'

export const ProtectedImageIco = React.memo(
  (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
    const onErrorHandler = (e: React.SyntheticEvent) => {
      const target = e.target as HTMLImageElement
      target.src = `/assets/images/ico/warning.svg`
    }
    return <img {...props} alt={''} onError={onErrorHandler} />
  },
)

export const ProtectedAvaIco = React.memo(
  (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
    const onErrorHandler = (e: React.SyntheticEvent) => {
      const target = e.target as HTMLImageElement
      target.src = `/assets/images/ico/person.svg`
    }
    return <img {...props} alt={''} onError={onErrorHandler} />
  },
)
