import React, { useState } from 'react'
import { AppButton } from '../common/components/AppButton/AppButton'
import { useAppDispatch, useAppSelector } from '../core/store/store'
import { setCredentials } from '../core/store/auth/authSlice'
import { AppInput } from '../common/components/AppInput/AppInput'
import { useRefreshTokenMutation } from '../core/api/BaseApiEndpoints/Auth/Auth'

const validOutOfDateToken =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2NDkzNDU1NTQsImV4cCI6MTY0OTM0NTY1NCwicm9sZXMiOlsiUk9MRV9EUklWRVIiXSwiaWQiOjN9.Qj-Rbt43Rwoq800PuqvDGvGpsP9NCV-2mUkeTz86pQJlXOJPL0zh9osjT2RiuobWEm1tfqhadb1sIpVTknWNOJmxg5VakAvEf8H1jBaCJbLJmQK1uuGmUmB2zaEJTAYYwzADltdkwFwRw9tHKLy5goQkZVOjyfovFBIBEUPTGbVS3UlFeS88YT5CekUcwefVuULDJHdJ2C4zPUB1W9uNjdGE2O80jmpQnUmm2joV_noD28M449u62ZjvzmMKmFkqRqTsA5ug7a6ph9nTVSRp9HieAJYkkMYk1W5BQvKONKMe2KHWgEHJfNQtTjLoZwAUqfZAOeOdnnV5o1ou-LaMwrW55dTAJvr4vg9mbvhnlPc7QvBycizqi68Pzcog4M4QWGp1IDSaxbJDeU8jcmzv9Z4LEN_6Drf-GHLfB0hVGiG2YPfIvUqENX3CbhkoJmInBR97BaUYqMGctUHuPZrwtV_56-O42BuB3Wc1Q92BP4knQNx6LFH5e_e2G7ji0KaLDy5NJ-cg8rvO7870dOIqW08QL8lkv6iXwuhAlAdn3CKP6-pWCQFdMw2EgE2ocxks9E-7-Lw7Rrvz_M-k_Z7CHWuUMcHvuuzqleB1tmGdapOA919CUoDLL-GPmLNKmmwdI_h0R50QpuqUvImw_6nlRIgyyqOuuq1PiXkUruo72_c'

export const TestAuth = () => {
  const { currentRole } = useAppSelector((state) => state.mediaPlanAuth)

  const { refresh, access } = useAppSelector(
    (state) => state.mediaPlanAuth[currentRole]!.credentials,
  )

  const [tokenState, setTokenState] = useState({
    access,
    refresh,
  })
  const [getNewJwt] = useRefreshTokenMutation()
  const getNewToken = () => {
    getNewJwt({
      refresh: `${refresh}`,
    })
      .unwrap()
      .then((result) => dispatch(setCredentials(result)))
  }
  const [email, setEmail] = useState('')
  const dispatch = useAppDispatch()

  return (
    <div className={'container-xl'}>
      <div className="row text-a-c">
        <div className="col-12">
          <p className={'text-a-c'}>/ ******* GoToMainPage ******** \</p>
          <AppButton path={'/'} title={'MainPage'} />
          <p className={'text-a-c'}>\ ******* GoToMainPage ******** /</p>
        </div>
        <div className="col-12">
          <p className={'text-a-c'}>/ ******* ResetLimit ******** \</p>
          <div style={{ maxWidth: '50%', margin: '0 auto' }}>
            <AppInput
              label={'email'}
              onChange={(value) => setEmail(value)}
              value={email}
            />
          </div>
          <p className={'text-a-c'}>\ ******* ResetLimit ******** /</p>
        </div>
        <div className="col-12">
          <p className={'text-a-c'}>/ ******* TokenControlButtons ******** \</p>
          <AppButton onClick={() => getNewToken()} title={'refreshToken'} />
          <p className={'text-a-c'}>\ ******* TokenControlButtons ******** /</p>
        </div>
        <div className="col-12 text-a-l">
          <p className={'text-a-c'}>/ ******* TokenInformation ******** \</p>
          <p>token: {JSON.stringify(access)}</p>
          <p>refresh: {JSON.stringify(refresh)}</p>
          <p className={'text-a-c'}>\ ******* TokenInformation ******** /</p>
        </div>
        <div className="col-12">
          <p className={'text-a-c'}>/ ******* Change Token ******** \</p>
          <div className="test-form">
            <h3>valid outOfDate token:</h3>
            <p>{validOutOfDateToken}</p>
            <AppInput
              onChange={(value) => {
                setTokenState((prev) => {
                  return { ...prev, token: value }
                })
              }}
              label={'token'}
              value={tokenState.access}
            />
            <AppInput
              onChange={(value) => {
                setTokenState((prev) => {
                  return { ...prev, refresh: value }
                })
              }}
              label={'refresh'}
              value={tokenState.refresh}
            />
            <AppButton
              onClick={() => dispatch(setCredentials({ ...tokenState }))}
              title={'changeToken'}
            />
            <p className={'text-a-c'}>\ ******* Change Token ******** /</p>
          </div>
        </div>
        <div className="col-12">
          <p className={'text-a-c'}>/ ******* refreshToken test`s ******** \</p>
          <p className={'text-a-c'}>\ ******* refreshToken test`s ******** /</p>
        </div>
      </div>
    </div>
  )
}
