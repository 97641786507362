import React, { useCallback, useMemo, useState } from 'react'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { AppTable } from '../../../common/components/AppTable/AppTable'
import { HeaderSearchInput } from '../../../common/components/HeaderSearchInput/HeaderSearchInput'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import { AppTableCell } from '../../../common/components/AppTable/AppTableCell'
import {
  useDelGroupSourceMutation,
  useGetAllSourcesGroupsQuery,
} from '../../../core/api/BaseApiEndpoints/Sources/SourcesApi'
import { ISourceGroupDTO } from '../../../core/api/dto/SourcesDto'
import { AppCheckbox } from '../../../common/components/AppCheckbox/AppCheckbox'
import { useSearchParamsHelper } from '../../../helpers/setSearchParam'
import {
  ICheckedItems,
  MakeGroupFromCheckedSourcesPopup,
} from './MakeGroupFromCheckedSourcesPopup'
import {
  PageNavTabs,
  PageNavTabsType,
} from '../../../common/components/Breadcrumbs/PageNavTabs'

const tableConfig = {
  tableHeaders: ['Название', 'URL', 'Автор', 'Email', 'Доступ', 'Действия'],
  width: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
}

const subPages = [
  {
    name: 'Источники',
    path: `/${AllRoutes.sources.path}/${AllRoutes.sourcesList.path}`,
  },
  {
    name: 'Группы',
    path: `/${AllRoutes.sources.path}/${AllRoutes.sourcesGroups.path}`,
  },
]

export const SourcesGroups = () => {
  const { searchParamsString } = useSearchParamsHelper({})
  const { data } = useGetAllSourcesGroupsQuery({ searchParamsString })
  const [delSources] = useDelGroupSourceMutation()
  const [
    makeGroupFromCheckedSourcesPopupOpen,
    setMakeGroupFromCheckedSourcesPopupOpen,
  ] = useState(false)

  const tableSelectors = useMemo(() => {
    return [
      {
        renderItem: (item: ISourceGroupDTO) => (
          <AppTableCell>
            <AppTableCell>{item.name}</AppTableCell>
          </AppTableCell>
        ),
      },
      {
        renderItem: () => (
          <AppTableCell>
            <AppTableCell>item.url</AppTableCell>
          </AppTableCell>
        ),
      },
      {
        renderItem: (item: ISourceGroupDTO) => (
          <AppTableCell>
            <AppTableCell>{item.user.first_name}</AppTableCell>
          </AppTableCell>
        ),
      },
      {
        renderItem: (item: ISourceGroupDTO) => (
          <AppTableCell>
            <AppTableCell>
              <div
                className={'table-executors text-ellipsis'}
                style={{ maxWidth: 300 }}
              >
                {item.sources.map((source) => {
                  return (
                    <span
                      key={`executor-${source.id}`}
                      className={'table-executor-item'}
                    >
                      {`${source.name}`}
                    </span>
                  )
                })}
              </div>
            </AppTableCell>
          </AppTableCell>
        ),
      },
      {
        renderItem: (item: ISourceGroupDTO) => (
          <AppTableCell>
            <AppTableCell>
              <AppTranslation
                label={
                  item.is_public
                    ? 'sources_page__txt_public'
                    : 'sources_page__txt_private'
                }
              />
            </AppTableCell>
          </AppTableCell>
        ),
      },
      {
        renderItem: (item: ISourceGroupDTO) => (
          <AppTableCell>
            <AppButton
              title={'Удалить'}
              onClick={() => delBtnClickHandler(item)}
              icoClassName={'an-ico-trash'}
              size={AppButtonSize.small}
              color={AppButtonColor.aquaBlue}
            />
          </AppTableCell>
        ),
      },
    ]
  }, [])

  const delBtnClickHandler = useCallback(
    (item: ISourceGroupDTO) => {
      delSources({ ids: item.id.toString() })
        .unwrap()
        .then(() => {})
    },
    [delSources],
  )

  return (
    <>
      <div className={'back-office-header'}>
        <h1>
          <AppTranslation label={AllRoutes.sources.name} />
        </h1>
        <div className={'header-actions-block'}>
          <div className="actions-left-block">
            <HeaderSearchInput
              searchParam={'search'}
              placeholder={'general__txt_search'}
            />
          </div>
        </div>
        <div className={'header-actions-block'}>
          <div className="actions-left-block">
            <PageNavTabs
              data={subPages}
              propToShow={'name'}
              valuePropName={'path'}
              type={PageNavTabsType.navLink}
            />
          </div>
        </div>
      </div>
      <AppTable
        headerTitles={tableConfig.tableHeaders}
        cellWidth={tableConfig.width}
        tableDataSelectors={tableSelectors}
        data={data ? data : null}
        lastColAlignLeft={true}
      />
    </>
  )
}
