import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../core/store/store'
import { deleteCredentials } from '../../core/store/auth/authSlice'
import { useLogoutMutation } from '../../core/api/BaseApiEndpoints/Auth/Auth'

export const LogoutPage = () => {
  const dispatch = useAppDispatch()
  const [logout] = useLogoutMutation()
  const currentRole = useAppSelector((state) => state.mediaPlanAuth.currentRole)
  const { refresh } = useAppSelector(
    (state) => state.mediaPlanAuth[currentRole]!.credentials,
  )

  useEffect(() => {
    logout({
      refresh: `${refresh}`,
    }).then(() => dispatch(deleteCredentials({ role: currentRole })))
  }, [currentRole, dispatch, logout, refresh])
  return <div>LogOut</div>
}
