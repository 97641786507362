import React, { useCallback, useMemo, useState } from 'react'
import {
  AppTranslation,
  translationLabelTape,
} from '../AppTranslation/AppTranslation'

import { IBaseProfileDto } from '../../../core/api/dto/ProfilesDto'
import { AppFilterDropdown } from '../AppDropdown/AppFilterDropdown'
import {
  useGetEntityItemsQuery,
  useGetEntityTypesQuery,
} from 'core/api/BaseApiEndpoints/Entity/EntityApi'
import { onChangeExecutorsHandlerType } from '../FormExecutors/FormExecutors'
import { useGetProjectItemsQuery } from 'core/api/BaseApiEndpoints/Projects/ProjectsApi'

export const FormEntity = ({
  defaultValue,
  onChange,
  disabled,
  label,
  error,
}: any) => {
  const [entitySymbolKey, setEntitySymbolKey] =
    useState<string>('Медиакампания')
  const { data: entityNames } = useGetEntityTypesQuery()
  let entityNamesAll = entityNames ? [...entityNames] : []
  entityNamesAll[4] = { name: 'Проект', items_count: 99 }
  const { data: entityItems } = useGetEntityItemsQuery({
    entitySymbolKey: entitySymbolKey,
  })
  const { data: projectItems } = useGetProjectItemsQuery({})

  const сhangeValue = useCallback((project) => {
    setEntitySymbolKey(project[0].name)
  }, [])

  const onChangeHandler = useCallback(
    (entity) => {
      const result = { entity: entity }
      onChange(result)
    },
    [onChange],
  )

  return (
    <div className="back-office-input-wrapper ">
      <div className="app-input-wrapper full-width-input">
        <div className="app-input-label">
          <b className="title">
            <AppTranslation label={'task_create_page__form_entity'} />
          </b>
        </div>
        {/*<ExecutorsGroup
            executors={currentValue?.executor}
            onChange={onChangeHandler}
            executorsType={'executor'}
            disabled={disabled}
            label={'task_create_page__form_executor'}
        />*/}
        <div className="entity-block">
          <AppFilterDropdown
            searchParam={'entity'}
            propToShowInList={'name'}
            valuePropName={'name'}
            data={entityNames ? entityNamesAll : []}
            placeHolder={'Выберите сущность'}
            withFilter={true}
            onChange={сhangeValue}
          />
          {entitySymbolKey != 'Проект' ? (
            <AppFilterDropdown
              searchParam={'item'}
              propToShowInList={'title'}
              valuePropName={'title'}
              placeHolder={'Поиск'}
              data={entityItems?.items ? entityItems.items : []}
              withFilter={true}
              onChange={onChangeHandler}
            />
          ) : (
            <AppFilterDropdown
              searchParam={'item'}
              propToShowInList={'title'}
              valuePropName={'title'}
              placeHolder={'Поиск'}
              data={projectItems?.items ? projectItems.items : []}
              withFilter={true}
              onChange={onChangeHandler}
            />
          )}
        </div>
      </div>
    </div>
  )
}
