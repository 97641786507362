import React, { useState } from 'react'
import { AppInput } from '../../common/components/AppInput/AppInput'
import {
  AppButton,
  AppButtonColor,
} from '../../common/components/AppButton/AppButton'
import { NavLink } from 'react-router-dom'
import { ICustomErrorDto } from '../../core/api/BaseApi'
import { useAppDispatch } from '../../core/store/store'
import { setCredentials } from '../../core/store/auth/authSlice'
import { AllRoutes } from '../../core/routes/AllRoutes'
import { useLoginMutation } from '../../core/api/BaseApiEndpoints/Auth/Auth'
import { AppTranslation } from '../../common/components/AppTranslation/AppTranslation'

export const LoginPage = () => {
  const [formState, setFormState] = useState({ email: '', password: '' })
  const [error, setError] = useState('')
  const dispatch = useAppDispatch()
  const [makeLogin, { isLoading }] = useLoginMutation()

  const formStateChangeHandler = (propName: string, value: string) => {
    if (error) {
      setError('')
    }
    setFormState((prev) => {
      return {
        ...prev,
        [propName]: value,
      }
    })
  }
  const loginHandler = () => {
    if (formState.email === '' || formState.password === '') {
      return
    }
    makeLogin({ ...formState })
      .unwrap()
      .then((result) => {
        dispatch(setCredentials(result))
      })
      .catch((error: ICustomErrorDto) =>
        setError(
          typeof error.data === 'string' ? error.data : error.data.status_text,
        ),
      )
  }

  return (
    <React.Fragment>
      <h1>
        <AppTranslation label={'login_page__txt_title'} />
      </h1>
      {error && (
        <div className="form-error-msg">
          <small className="color-Red">
            <AppTranslation label={'login_page_txt_form_error_password'} />
          </small>
        </div>
      )}
      <AppInput
        onChange={(value) => formStateChangeHandler('email', value)}
        value={formState.email}
        type={'email'}
        placeholder={'login_page__txt_form_field_email'}
        error={!!error}
        fullWidth={true}
        onPressEnter={loginHandler}
      />
      <AppInput
        onChange={(value) => formStateChangeHandler('password', value)}
        value={formState.password}
        type={'password'}
        placeholder={'login_page__txt_form_field_password'}
        error={error}
        fullWidth={true}
        onPressEnter={loginHandler}
      />
      <AppButton
        title={'login_page__txt_form_submit_btn'}
        color={AppButtonColor.blue}
        onClick={loginHandler}
        disabled={
          isLoading || formState.email === '' || formState.password === ''
        }
      />
      <div className="action-more">
        <NavLink
          className="action-more"
          to={`../${AllRoutes.resetPassword.path}`}
        >
          <span className="color-Dark-blue">
            <AppTranslation label={'login_page__txt_reset_password'} />
          </span>
        </NavLink>
      </div>
    </React.Fragment>
  )
}
