import React, { useCallback, useMemo, useState } from 'react'
import { Breadcrumbs } from '../../../common/components/Breadcrumbs/Breadcrumbs'
import {
  useDelTaskMutation,
  useGetTaskByIdHistoryQuery,
  useGetTaskByIdQuery,
  useUpdateTaskMutation,
} from '../../../core/api/BaseApiEndpoints/Tasks/TasksApi'
import { useParams } from 'react-router'
import { TaskDetailWidget } from '../../../common/components/Tasks/TaskDetailWidget'
import { FromDatesWidget } from '../../../common/components/Tasks/FromDatesWidget'
import { SectionWidget } from '../../../common/components/SectionWidget/SectionWidget'
import { PersonsRepresentWidget } from '../../../common/components/PersonsRepresentWidget/PersonsRepresentWidget'
import { Link, useNavigate } from 'react-router-dom'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { ItemRepresentByDataType } from '../Entity/ItemRepresentByDataType'
import { Comments } from '../../../common/components/Comments/Comments'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import {
  AppPopup,
  popupType,
} from '../../../common/components/AppPopup/AppPopup'
import { ITaskCreateDTO } from 'core/api/dto/TasksDto'
import { PaperClipOutlined } from '@ant-design/icons'
import { HistoryMode } from '../Entity/HistoryMode'
import { AppTranslation } from 'common/components/AppTranslation/AppTranslation'

export const TaskDetail = () => {
  const { taskId } = useParams()
  const navigate = useNavigate()
  const { data } = useGetTaskByIdQuery({ taskId: Number(taskId) })
  const { data: historyItems } = useGetTaskByIdHistoryQuery({
    taskId: Number(taskId),
  })
  const [updateTask] = useUpdateTaskMutation()
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false)
  const [delTask] = useDelTaskMutation()
  const [isHistoryMode, setIsHistoryMode] = useState(false)

  const companiesBreadcrumbs = useMemo(() => {
    return [
      { name: 'tasks_page__txt_title', path: '../' },
      {
        name: {
          label: 'tasks_detail_page__txt_nav_button_title',
          options: { taskName: `${data ? data.name : ''}` },
        },
      },
    ]
  }, [data, taskId])

  const delBtnClickHandler = useCallback(() => {
    delTask({ taskId: taskId! })
      .unwrap()
      .then(() => {
        navigate(`/${AllRoutes.tasksListing.path}`)
      })
  }, [])

  const changeTaskStatus = (status: string) => {
    const newData: any = {
      status,
    }
    updateTask({ body: newData, taskId: taskId! })
  }

  if (!data) {
    return null
  }

  return (
    <React.Fragment>
      <div className={'back-office-header'}>
        {/*<Breadcrumbs breadcrumbs={companiesBreadcrumbs} />*/}
        <div
          style={{ display: 'flex', alignItems: 'center', marginTop: '24px' }}
        >
          <h1 className="text-ellipsis" style={{ maxWidth: '600px' }}>
            <AppTranslation label={data?.name as string} />
          </h1>
          <div className="dashboard-tab">
            <div
              onClick={() => {
                setIsHistoryMode(false)
              }}
              className={`dashboard-tab__item${
                !isHistoryMode ? ' dashboard-tab__item--active' : ''
              }`}
            >
              Информация
            </div>
            <div
              onClick={() => {
                setIsHistoryMode(true)
              }}
              className={`dashboard-tab__item${
                isHistoryMode ? ' dashboard-tab__item--active' : ''
              }`}
            >
              История изменений
            </div>
          </div>
        </div>
        {!isHistoryMode && (
          <div className={'header-actions-block header-actions-block_justify'}>
            <div>
              <AppButton
                path={`${AllRoutes.tasksEdit.path}`}
                color={AppButtonColor.aquaBlue}
                title={'Редактировать'}
                size={AppButtonSize.small}
              />

              {data?.status.toLocaleUpperCase() == 'NEW' && (
                <AppButton
                  color={AppButtonColor.lavender}
                  title={'Взять в работу'}
                  size={AppButtonSize.small}
                  onClick={() => changeTaskStatus('DOING')}
                />
              )}
              {data?.status.toLocaleUpperCase() != 'DONE' &&
                data?.status.toLocaleUpperCase() != 'NEW' && (
                  <AppButton
                    color={AppButtonColor.green}
                    title={'Готово'}
                    size={AppButtonSize.small}
                    onClick={() => changeTaskStatus('DONE')}
                  />
                )}
            </div>
            <AppButton
              color={AppButtonColor.red}
              title={'Удалить'}
              icoClassName={'an-ico-trash'}
              size={AppButtonSize.small}
              onClick={() => setShowDeleteConfirmPopup(true)}
            />
          </div>
        )}
      </div>
      {isHistoryMode ? (
        <HistoryMode items={historyItems?.history} />
      ) : (
        <div className="back-office-form">
          <div className="row">
            <div className="col-6">
              <div className="form-block">
                <div className="block-section">
                  <TaskDetailWidget task={data} />
                </div>
                <div className="block-section">
                  <p className="description-block">{data.description}</p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-12">
                  <div className="form-block">
                    <div className="block-section">
                      <SectionWidget
                        title={'task_section_widget_dates__txt_title'}
                        icoClassName={'an-ico-calendar'}
                      >
                        <FromDatesWidget
                          date_start={data.date_start}
                          date_end={data.date_end}
                        />
                      </SectionWidget>
                    </div>
                  </div>
                </div>
                {data.task_media.length > 0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SectionWidget title={'Медиа'}>
                          <>
                            {data.task_media.map((media) => {
                              return (
                                <p key={`media-${media.id}`}>
                                  <PaperClipOutlined
                                    style={{
                                      fontSize: '20px',
                                      color: '#52acc3',
                                    }}
                                  />{' '}
                                  <a href={media.file} target={'_blank'}>
                                    {media.file.split('media/')[1]}
                                  </a>
                                </p>
                              )
                            })}
                          </>
                        </SectionWidget>
                      </div>
                    </div>
                  </div>
                )}

                {data.task_document.length > 0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SectionWidget title={'Документы'}>
                          <>
                            {data.task_document.map((document) => {
                              return (
                                <p key={`document-${document.id}`}>
                                  <PaperClipOutlined
                                    style={{
                                      fontSize: '20px',
                                      color: '#52acc3',
                                    }}
                                  />{' '}
                                  <ItemRepresentByDataType
                                    itemData={document}
                                  />
                                </p>
                              )
                            })}
                          </>
                        </SectionWidget>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                {(data.executor.length > 0 || data.co_executor.length > 0) && (
                  <div className="col-12">
                    <div className="form-block">
                      {data.executor.length > 0 && (
                        <div className="block-section">
                          <PersonsRepresentWidget
                            data={data.executor.slice(0, 1)}
                            label={'Ответственный'}
                          />
                        </div>
                      )}
                      {data.co_executor.length > 0 && (
                        <div className="block-section flex-section">
                          <PersonsRepresentWidget
                            data={data.co_executor}
                            label={'Соисполнители'}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {Object.keys(data.entities).length > 0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SectionWidget title={'Связанные сущности'}>
                          <div className="vertical-list">
                            {Object.keys(data.entities).map((entityKey) => {
                              return (
                                <React.Fragment key={entityKey}>
                                  {data.entities[entityKey].map(
                                    (entityItem) => {
                                      return (
                                        <Link
                                          key={`${entityKey}-${entityItem.id}`}
                                          to={`/${AllRoutes.entity.path}/${entityKey}/${entityItem.id}`}
                                        >
                                          {entityItem.title}
                                        </Link>
                                      )
                                    },
                                  )}
                                </React.Fragment>
                              )
                            })}
                          </div>
                        </SectionWidget>
                      </div>
                    </div>
                  </div>
                )}
                {Object.keys(data.projects).length > 0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SectionWidget title={'Проекты'}>
                          <div className="vertical-list">
                            {Object.keys(data.projects).map((entityKey) => {
                              return (
                                <React.Fragment key={entityKey}>
                                  {data.projects.map((project: any) => {
                                    return (
                                      <Link
                                        key={`${project.id}`}
                                        to={`/${AllRoutes.projects.path}/${project.id}`}
                                      >
                                        {project.title}
                                      </Link>
                                    )
                                  })}
                                </React.Fragment>
                              )
                            })}
                          </div>
                        </SectionWidget>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-8">
              <div className="form-block">
                <div className="block-section">
                  <SectionWidget title={'Комментарии'}>
                    <Comments />
                  </SectionWidget>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showDeleteConfirmPopup ? (
        <AppPopup
          title={`entity_element_details__popup_delete_title`}
          onClose={() => setShowDeleteConfirmPopup(false)}
          type={popupType.danger}
          description={`entity_element_details__popup_delete_text`}
        >
          <div className="row delete-btns">
            <AppButton
              title={'entity_element_details__popup_delete_btn_yes'}
              onClick={delBtnClickHandler}
              color={AppButtonColor.red}
              size={AppButtonSize.base}
            />
            <AppButton
              title={'entity_element_details__popup_delete_btn_no'}
              onClick={() => setShowDeleteConfirmPopup(false)}
              color={AppButtonColor.blue}
              size={AppButtonSize.base}
            />
          </div>
        </AppPopup>
      ) : null}
    </React.Fragment>
  )
}
