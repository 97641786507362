import { PaperClipOutlined } from '@ant-design/icons'
import { AppTable } from 'common/components/AppTable/AppTable'
import { AppTableCell } from 'common/components/AppTable/AppTableCell'
import { AppTranslation } from 'common/components/AppTranslation/AppTranslation'
import { TableExecutors } from 'common/components/Tasks/TableExecutors'
import { TaskCountDown } from 'common/components/Tasks/TaskCountDown'
import { TaskStatusBar } from 'common/components/Tasks/TaskStatusBar'
import { useEffect, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'

const tableConfig = {
  width: [100, 380, 200, 500, 200, 200, 230, 300],
}

const tableHeaders = [
  'Автор изменения',
  'Поле изменения',
  'Значение до изменения',
  'Стало после изменения',
  'Дата изменения',
]

export const HistoryMode = ({ fields, items }: any) => {
  const [data, setData] = useState<any>({})

  useEffect(() => {
    let data: any = {}

    let calk = items?.map((items: any) =>
      items?.changes?.map((item: any) => ({
        ...item,
        date: items?.date,
        user: items?.user,
        changed: item?.new,
      })),
    )
    data['items'] = calk && [].concat(...calk)
    setData(data)
  }, [items])

  const tableSelectors = useMemo(() => {
    return [
      {
        renderItem: ({ user }: any) => (
          <AppTableCell>
            <div>
              {user.last_name + ' ' + user.first_name + ' ' + user.patronymic}
            </div>
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ field }: any) => (
          <AppTableCell>
            {field == 'entity_document'
              ? 'Документы'
              : field == 'entity_media'
              ? 'Медиа'
              : fields ? fields[field.toString()]?.label : field}
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ old }: any) => (
          <AppTableCell>
            {old == null ? (
              '--'
            ) : typeof old == 'string' ? (
              <AppTableCell>
                <div>
                  {old.slice(-1) == 'Z' ? (
                    <AppTranslation
                      label={'task_section_widget_date_formatter__txt_title'}
                      options={{
                        date: old && new Date(old),
                      }}
                    />
                  ) : (
                    old
                  )}
                </div>
              </AppTableCell>
            ) : Object.prototype.toString.call(old) === '[object Array]' ? (
              old?.map((it: any) => {
                return it.file ? it.file.slice(7, 15) + '..., ' : it.title + ', ' 
              })
            ) : (
              <div>{old.full_name ? old.full_name : old.id}</div>
            )}
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ changed }: any) => (
          <AppTableCell>
            {changed == null ? (
              '--'
            ) : typeof changed == 'string' ? (
              <AppTableCell>
                <div>
                  {changed.slice(-1) == 'Z' ? (
                    <AppTranslation
                      label={'task_section_widget_date_formatter__txt_title'}
                      options={{
                        date: changed && new Date(changed),
                      }}
                    />
                  ) : (
                    changed
                  )}
                </div>
              </AppTableCell>
            ) : typeof changed == 'number' ? (
              changed
            ) : Object.prototype.toString.call(changed) === '[object Array]' ? (
              changed?.map((it: any) => {
                return it.file ? it.file.slice(7, 14) + '..., ' : it.title + ', '
              })
            ) : (
              <div>{changed.full_name ? changed.full_name : changed.title}</div>
            )}
          </AppTableCell>
        ),
      },
      {
        renderItem: ({ date }: any) => (
          <AppTableCell>
            <div>
              <AppTranslation
                label={'task_section_widget_date_formatter__txt_title'}
                options={{ date: date && new Date(date) }}
              />
            </div>
          </AppTableCell>
        ),
      },
    ]
  }, [])
  return (
    <div className="dashboard">
      {data?.items?.length > 0 ?
      <AppTable
        headerTitles={tableHeaders}
        tableDataSelectors={tableSelectors}
        data={data ? data : null}
        lastColAlignLeft={true}
      />
      : "История изменений пуста"}
    </div>
  )
}
