import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  AppTranslation,
  IAppTranslation,
  translationLabelTape,
} from '../AppTranslation/AppTranslation'

interface IBreadcrumb {
  name: translationLabelTape | IAppTranslation
  path?: string
}

export const Breadcrumbs = ({
  breadcrumbs,
}: {
  breadcrumbs: IBreadcrumb[]
}) => {
  const arrLength = breadcrumbs.length
  return (
    <div className="breadcrumbs">
      {breadcrumbs.map((breadcrumb, index) =>
        index + 1 === arrLength && !breadcrumb.path ? (
          <div className="breadcrumb-item" key={`breadcrumbs-${index}`}>
            {typeof breadcrumb.name === 'object' ? (
              <AppTranslation
                label={breadcrumb.name.label}
                options={breadcrumb.name.options}
              />
            ) : (
              <AppTranslation label={breadcrumb.name} />
            )}
          </div>
        ) : (
          <NavLink
            to={breadcrumb.path!}
            className="breadcrumb-item"
            key={`breadcrumbs-${index}`}
          >
            {typeof breadcrumb.name === 'object' ? (
              <AppTranslation
                label={breadcrumb.name.label}
                options={breadcrumb.name.options}
              />
            ) : (
              <AppTranslation label={breadcrumb.name} />
            )}
          </NavLink>
        ),
      )}
    </div>
  )
}
