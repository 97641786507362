import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Group } from '@visx/group'
import { hierarchy, Tree } from '@visx/hierarchy'
import { LinearGradient } from '@visx/gradient'
import { Zoom } from '@visx/zoom'
import { localPoint } from '@visx/event'
import { RectClipPath } from '@visx/clip-path'
import getLinkComponent from 'common/components/StructureGraph/getLinkComponent'
import LinkControls from 'common/components/StructureGraph/LinkControls'
import useForceUpdate from 'common/components/StructureGraph/useForceUpdate'
import Avatar0 from './img/item__img.png'
import Avatar1 from './img/avatar1.png'

import { StructurePopup } from 'pages/AdminPage/Structure/StucturePopup'
import UserInfoTab from './userInfoTab'
import { useSearchParamsHelper } from 'helpers/setSearchParam'
import { useGetMembersQuery } from 'core/api/BaseApiEndpoints/Members/MembersApi'

interface TreeNode {
  name: string
  title?: string
  photo?: any
  id: number
  subs?: string
  level?: number
  repost?: any
  size?: number
  isExpanded?: boolean
  children?: TreeNode[]
  users?: TreeNode[]
  count?: number
}

const defaultMargin = { top: 15, left: 10, right: 30, bottom: 25 }

export type LinkTypesProps = {
  width: number
  height: number
  margin?: { top: number; right: number; bottom: number; left: number }
}
export const StructureGraph = ({ margin = defaultMargin }) => {
  const { searchParamsString } = useSearchParamsHelper({})
  const { data } = useGetMembersQuery({ searchParamsString })
  console.log('members', data?.items[0])
  const [layout, setLayout] = useState<string>('cartesian')
  const [orientation, setOrientation] = useState<string>('vertical')
  const [linkType, setLinkType] = useState<string>('step')
  const [stepPercent, setStepPercent] = useState<number>(1)
  const forceUpdate = useForceUpdate()
  const [addSourcePopupOpen, setAddSourcePopupOpen] = useState(false)
  const [structureItem, setStructureItem] = useState<any>(0)
  const addSourceBtnCLickHandler = useCallback(() => {
    setAddSourcePopupOpen(true)
    console.log('id', structureItem)
  }, [setAddSourcePopupOpen])
  const onCloseSourceBtnHandler = useCallback(() => {
    setAddSourcePopupOpen(false)
  }, [setAddSourcePopupOpen])

  /*const [data, setData] = useState<TreeNode>({
    name: 'main',
    id: 0,
    count: 0,
    children: [
      {
        id: 1,
        title: 'Руководство',
        name: 'Бельский А. Н.',
        photo: { Avatar0 },
        subs: 'Спикер ЗАКС',
        size: 16,
        users: [
          {
            id: 2,
            repost: 1,
            name: 'Вдовина Д. А.',
            photo: { Avatar1 },
            subs: 'Пресс-секретарь ЗАКС',
            size: 16,
            children: [],
            level: 1,
          },
          {
            id: 3,
            repost: 1,
            name: 'Павлович Л. Г.',
            photo: { Avatar2 },
            subs: 'Пресс-секретарь ЗАКС',
            size: 16,
            children: [],
            level: 1,
          },
          {
            id: 4,
            repost: 1,
            name: 'Сизов И. С.',
            photo: { Avatar3 },
            subs: 'Пресс-секретарь ЗАКС',
            size: 16,
            children: [],
            level: 1,
          },
        ],
        children: [
          {
            id: 5,
            title: 'Депутат',
            repost: 2,
            name: 'Авдеев Ю. В.',
            photo: { Avatar4 },
            subs: 'Депутат ЗАКС',
            size: 16,
            users: [
              {
                id: 9,
                repost: 2,
                name: 'Романчикова Е. А.',
                photo: { Avatar0 },
                subs: 'Помощник депутата',
                size: 16,
                children: [],
                level: 2,
              },
            ],
            level: 2,
          },
          {
            id: 6,
            title: 'Депутат',
            repost: 3,
            name: 'Алескеров А. Э.',
            photo: { Avatar5 },
            subs: 'Депутат ЗАКС',
            size: 16,
            users: [
              {
                id: 9,
                repost: 2,
                name: 'Артюшин С. М.',
                photo: { Avatar0 },
                subs: 'Помощник депутата',
                size: 16,
                children: [],
                level: 2,
              },
            ],
            level: 2,
          },
          {
            id: 7,
            title: 'Депутат',
            repost: 3,
            name: 'Амосов М. И.',
            photo: { Avatar6 },
            subs: 'Депутат ЗАКС',
            size: 16,
            users: [
              {
                id: 9,
                repost: 2,
                name: 'Сафонов В. К.',
                photo: { Avatar0 },
                subs: 'Помощник депутата',
                size: 16,
                children: [],
                level: 2,
              },
            ],
            level: 2,
          },
          {
            id: 8,
            title: 'Депутат',
            repost: 2,
            name: 'Астахова Н. В',
            photo: { Avatar7 },
            subs: 'Депутат ЗАКС',
            size: 16,
            users: [
              {
                id: 9,
                repost: 2,
                name: 'Баркова А. А',
                photo: { Avatar0 },
                subs: 'Помощник депутата',
                size: 16,
                children: [],
                level: 2,
              },
            ],
            level: 2,
          },
        ],
      },
    ],
  })*/
  const [structure, setStructure] = useState<any>({})

  const updateData = (value: TreeNode) => {
    setStructure(value)
  }

  useEffect(() => {
    let arr = {
      name: 'main',
      id: 0,
      count: 0,
      children: [
        {
          id: 1,
          title: 'Руководство',
          name: 'Иванов Семён Петрович',
          photo: { Avatar1 },
          subs: 'Генеральный директор',
          size: 16,
          users: [
            {
              id: 2,
              repost: 1,
              name: 'Мельник Софья Николаевна',
              photo: { Avatar0 },
              subs: 'Помощник директора',
              size: 16,
              children: [],
              level: 1,
            },
          ],
          children: [
            {
              id: 5,
              title: 'Отдел Маркетинга',
              repost: 2,
              name: 'Киселев Даниил',
              photo: { Avatar0 },
              subs: 'Руководитель Отдела Маркетинга',
              size: 16,
              users: [
                {
                  id: 9,
                  repost: 2,
                  name: 'Пирожниченко Леонид',
                  photo: { Avatar0 },
                  subs: 'Маркетолог',
                  size: 16,
                  children: [],
                  level: 2,
                },
                {
                  id: 10,
                  repost: 2,
                  name: 'Кравченко Евгений',
                  photo: { Avatar0 },
                  subs: 'Помощник маркетолога',
                  size: 16,
                  children: [],
                  level: 2,
                },
                {
                  id: 11,
                  repost: 2,
                  name: 'Петров Василий',
                  photo: { Avatar0 },
                  subs: 'SMM менеджер',
                  size: 16,
                  children: [],
                  level: 2,
                },
                {
                  id: 12,
                  repost: 2,
                  name: 'Иван Смирнов',
                  photo: { Avatar0 },
                  subs: 'SMM менеджер',
                  size: 16,
                  children: [],
                  level: 2,
                },
                {
                  id: 12,
                  repost: 2,
                  name: 'Августинов Петр',
                  photo: { Avatar0 },
                  subs: 'SMM менеджер',
                  size: 16,
                  children: [],
                  level: 2,
                },
              ],
              level: 2,
            },
            {
              id: 6,
              title: 'Отдел ИТ',
              repost: 3,
              name: 'Слепаков Семен Семенович',
              photo: { Avatar0 },
              subs: 'Руководитель',
              size: 16,
              users: [
                {
                  id: 9,
                  repost: 2,
                  name: 'Гаврилов Пётр Иванович',
                  photo: { Avatar0 },
                  subs: 'Инженер',
                  size: 16,
                  children: [],
                  level: 2,
                },
                {
                  id: 10,
                  repost: 2,
                  name: 'Петров Артемий Валентинович',
                  photo: { Avatar0 },
                  subs: 'Инженер',
                  size: 16,
                  children: [],
                  level: 2,
                },
              ],
              level: 2,
            },
            {
              id: 7,
              title: 'Бухгалтерия',
              repost: 3,
              name: 'Звездная Екатерина Олеговна',
              photo: { Avatar0 },
              subs: 'Главный бухгалтер',
              size: 16,
              users: [
                {
                  id: 9,
                  repost: 2,
                  name: 'Серова Ирина Олеговна',
                  photo: { Avatar0 },
                  subs: 'Бухгалтер',
                  size: 16,
                  children: [],
                  level: 2,
                },
              ],
              level: 2,
            },
            {
              id: 8,
              title: 'Склад',
              repost: 2,
              name: 'Фролов Дмитрий Николаевич',
              photo: { Avatar0 },
              subs: 'Начальник склада',
              size: 16,
              users: [
                {
                  id: 9,
                  repost: 2,
                  name: 'Семенов Иван Николаевич',
                  photo: { Avatar0 },
                  subs: 'Кладовщик',
                  size: 16,
                  children: [],
                  level: 2,
                },
              ],
              level: 2,
            },
          ],
        },
      ],
    }
    arr.children[0].children[0].users = data?.items
    setStructure(arr)
  }, [data])

  const totalWidth = document.documentElement.clientWidth + 100
  const totalHeight = document.documentElement.clientHeight - 70
  const zoomRef: any = useRef()

  let arrImg: any[] = []

  let innerWidth = totalWidth - margin.left - margin.right
  let innerHeight = totalHeight - margin.top - margin.bottom

  let origin: { x: number; y: number }
  let sizeWidth: number
  let sizeHeight: number

  if (layout === 'polar') {
    origin = {
      x: innerWidth / 2,
      y: innerHeight / 2,
    }
    sizeWidth = 2 * Math.PI
    sizeHeight = Math.min(innerWidth, innerHeight) / 2
  } else {
    if (orientation === 'vertical') {
      origin = { x: 0, y: -innerHeight / 4 }
      sizeWidth = innerWidth
      sizeHeight = innerHeight
    } else {
      origin = { x: -innerWidth / 4, y: 0 }
      sizeWidth = innerHeight
      sizeHeight = innerWidth
    }
  }

  const LinkComponent = getLinkComponent({ layout, linkType, orientation })
  const initialTransform = {
    scaleX: 0.8,
    scaleY: 0.8,
    translateX: 0,
    translateY: 0,
    skewX: 0,
    skewY: 0,
  }

  return totalWidth < 10 ? null : (
    <div>
      <LinkControls
        layout={layout}
        orientation={orientation}
        linkType={linkType}
        stepPercent={stepPercent}
        setLayout={setLayout}
        setOrientation={setOrientation}
        setLinkType={setLinkType}
        setStepPercent={setStepPercent}
      />

      <Zoom<SVGSVGElement>
        width={innerWidth}
        height={innerHeight}
        scaleXMin={0}
        scaleXMax={4}
        scaleYMin={0}
        scaleYMax={4}
        initialTransformMatrix={initialTransform}
      >
        {(zoom) => (
          <div id="invoice" className="relative">
            <div className="controls noPrint">
              <button
                type="button"
                className="btn btn-zoom"
                onClick={() => zoom.scale({ scaleX: 1.2, scaleY: 1.2 })}
              >
                +
              </button>
              <button
                type="button"
                className="btn btn-zoom btn-bottom"
                onClick={() => zoom.scale({ scaleX: 0.8, scaleY: 0.8 })}
              >
                -
              </button>
            </div>
            <svg
              width={totalWidth}
              height={'900'}
              style={{
                cursor: zoom.isDragging ? 'grabbing' : 'grab',
                touchAction: 'none',
              }}
              ref={zoom.containerRef}
            >
              <RectClipPath
                id="zoom-clip"
                width={totalWidth}
                height={totalHeight}
              />
              <LinearGradient id="links-gradient" from="#fd9b93" to="#fe6e9e" />
              <rect
                width={totalWidth}
                height={totalHeight}
                rx={14}
                fill="none"
                onTouchStart={zoom.dragStart}
                onTouchMove={zoom.dragMove}
                onTouchEnd={zoom.dragEnd}
                onMouseDown={zoom.dragStart}
                onMouseMove={zoom.dragMove}
                onMouseUp={zoom.dragEnd}
                onMouseLeave={() => {
                  if (zoom.isDragging) zoom.dragEnd()
                }}
                onDoubleClick={(event) => {
                  const point = localPoint(event) || { x: 0, y: 0 }
                  zoom.scale({ scaleX: 1.1, scaleY: 1.1, point })
                }}
              />
              <g transform={zoom.toString()}>
                <Group top={margin.top} left={margin.left}>
                  <Tree
                    root={hierarchy(structure, (d) =>
                      d.isExpanded ? null : d.children,
                    )}
                    size={[sizeWidth, sizeHeight]}
                    separation={(a, b) =>
                      (a.parent === b.parent ? 1.2 : 1.5) / a.depth
                    }
                  >
                    {(tree) => (
                      <Group top={origin.y} left={origin.x}>
                        <defs>
                          <linearGradient
                            id="gradient"
                            x1="10%"
                            y1="50%"
                            x2="10%"
                            y2="100%"
                          >
                            <stop offset="0%" stopColor="#00bc9b" />
                            <stop offset="100%" stopColor="#fff" />
                          </linearGradient>
                        </defs>
                        {tree
                          .links()
                          .slice(structure?.children?.length)
                          .map((link, i) => (
                            <LinkComponent
                              key={i}
                              data={link}
                              percent={stepPercent}
                              stroke="#c9c9c9"
                              strokeWidth="2"
                              fill="none"
                            />
                          ))}

                        {tree.descendants().map((node, key) => {
                          const width = 40
                          const height = 20

                          let top: number
                          let left: number
                          if (orientation === 'vertical') {
                            top = node.y
                            left = node.x
                          } else {
                            top = node.x
                            left = node.y
                          }

                          return (
                            <Group top={top} left={left} key={key}>
                              {node.depth === 0 && (
                                <circle
                                  r={12}
                                  fill="white"
                                  onClick={() => {
                                    node.data.isExpanded = !node.data.isExpanded
                                    console.log(node)
                                    forceUpdate()
                                  }}
                                />
                              )}

                              <use
                                xlinkHref={'#' + key + 'rect'}
                                strokeWidth=".5"
                                fill="white"
                                y={node?.data?.id % 2 == 0 ? '0px' : '0px'}
                                stroke={node.depth === 0 ? 'white' : 'black'}
                              />
                              {node.depth !== 0 && (
                                <a href="/employees">
                                  {/*onClick={addSourceBtnCLickHandler}>*/}
                                  <defs>
                                    <filter
                                      id="f1"
                                      x="0"
                                      y="0"
                                      width="150%"
                                      height="150%"
                                    >
                                      <feOffset
                                        result="offOut"
                                        in="SourceGraphic"
                                        dx="1"
                                        dy="1"
                                      />
                                      <feColorMatrix
                                        result="matrixOut"
                                        in="offOut"
                                        type="matrix"
                                        values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.5 0"
                                      />
                                      <feGaussianBlur
                                        result="blurOut"
                                        in="matrixOut"
                                        stdDeviation="10"
                                      />
                                      <feBlend
                                        in="SourceGraphic"
                                        in2="blurOut"
                                        mode="normal"
                                      />
                                    </filter>
                                  </defs>
                                  <rect
                                    filter="photo(#f1)"
                                    height="180px"
                                    width="350px"
                                    y="10"
                                    x="-170"
                                    fill="#fff"
                                    strokeWidth={1}
                                    strokeDasharray={
                                      node.data.children ? '0' : '2,2'
                                    }
                                    strokeOpacity={node.data.children ? 1 : 0.6}
                                    //onClick={() => {
                                    //  addSourceBtnCLickHandler()
                                    //  setStructureItem(node.data.id)
                                    //}}
                                  />
                                  <rect
                                    height="20px"
                                    width="350px"
                                    y="-10"
                                    x="-170"
                                    //fill="#fff"
                                    fill="#D3F1D2"
                                    strokeWidth={2}
                                    strokeDasharray={
                                      node.data.children ? '0' : '2,2'
                                    }
                                    strokeOpacity={node.data.children ? 1 : 0.6}
                                    //rx={node.data.children ? 0 : 10}
                                  />

                                  <foreignObject
                                    //onClick={addSourceBtnCLickHandler}
                                    className="user-title"
                                    y={-10}
                                    x={-175}
                                    width="350"
                                    height="70"
                                    fontSize={17}
                                    fontFamily="Arial"
                                    textAnchor="middle"
                                    style={{ pointerEvents: 'none' }}
                                    color={
                                      node.depth === 0
                                        ? 'white'
                                        : node.children
                                        ? 'black'
                                        : 'black'
                                    }
                                  >
                                    {node.data.title
                                      ? node.data.title
                                          .toString()
                                          .substring(0, 30)
                                      : ''}
                                  </foreignObject>

                                  <UserInfoTab data={node.data} position={0} />
                                  <rect
                                    height="0.5px"
                                    width="350px"
                                    y="70"
                                    x="-170"
                                    fill="#000"
                                    strokeWidth={2}
                                    strokeDasharray={
                                      node.data.children ? '0' : '2,2'
                                    }
                                  />

                                  {node.data.users
                                    ?.slice(0, 4)
                                    .map((user: any, index: number) => {
                                      return (
                                        <UserInfoTab
                                          data={user}
                                          position={index + 1}
                                        />
                                      )
                                    })}

                                  <foreignObject
                                    y={160}
                                    x={70}
                                    width="100"
                                    height="50"
                                    fontSize={14}
                                    fontFamily="Arial"
                                    style={{ pointerEvents: 'none' }}
                                    color="#52acc3"
                                  >
                                    Смотреть всех
                                  </foreignObject>
                                </a>
                              )}
                            </Group>
                          )
                        })}
                      </Group>
                    )}
                  </Tree>
                </Group>
              </g>
            </svg>
          </div>
        )}
      </Zoom>
      {addSourcePopupOpen && (
        <StructurePopup
          onClose={onCloseSourceBtnHandler}
          structureItem={structureItem}
        />
      )}
    </div>
  )
}
