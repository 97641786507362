import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { HeaderSearchInput } from '../../../common/components/HeaderSearchInput/HeaderSearchInput'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import { AppTable } from '../../../common/components/AppTable/AppTable'
import {
  useGetEntitiesReportQuery,
  useGetEntityItemsQuery,
  useGetEntitySchemaQuery,
} from '../../../core/api/BaseApiEndpoints/Entity/EntityApi'
import {
  AppTableCell,
  AppTableCellJustify,
} from '../../../common/components/AppTable/AppTableCell'
import { Link, NavLink } from 'react-router-dom'
import {
  EntityDataType,
  IReferenceValuesTypeObject,
} from '../../../core/api/dto/EntityDto'
import { ItemRepresentByDataType } from './ItemRepresentByDataType'
import { useSearchParamsHelper } from '../../../helpers/setSearchParam'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { PDF } from './PDF'
import NewTable from 'common/components/AppTable/NewTable'

export const EntityElements = () => {
  const { searchParamsString } = useSearchParamsHelper({})
  const { entitySymbolKey } = useParams()
  const { data: entitySchema } = useGetEntitySchemaQuery({
    entitySymbolKey: entitySymbolKey!,
  })

  const { data: entityItems } = useGetEntityItemsQuery({
    entitySymbolKey: entitySymbolKey!,
    searchParamsString,
  })
  const [active, setActive] = useState(false)

  const { data: reportPrevious } = useGetEntitiesReportQuery({
    type: 'previous',
  })

  const { data: reportCurrent } = useGetEntitiesReportQuery({
    type: 'current',
  })

  const tableConfig = useMemo(() => {
    const tableHeaders: string[] = ['ID']
    const width: (string | number)[] = []
    const tableSelectors: {
      renderItem: (item: any) => JSX.Element | string
    }[] = [
      {
        renderItem: (item) => {
          return <AppTableCell>{item['pk']}</AppTableCell>
        },
      },
    ]
    if (!entitySchema || !entityItems) {
      return { tableHeaders, width, tableSelectors }
    }
    if (entityItems && entitySchema) {
      let schemaKeys = [...entitySchema.headerKeys]
      schemaKeys.unshift(
        ...schemaKeys.splice(schemaKeys.indexOf('event_end_date'), 1),
      )
      schemaKeys.unshift(
        ...schemaKeys.splice(schemaKeys.indexOf('event_start_date'), 1),
      )
      schemaKeys.unshift(...schemaKeys.splice(schemaKeys.indexOf('title'), 1))
      schemaKeys.forEach((key) => {
        tableHeaders.push(entitySchema.fields[key]?.label)
        width.push('auto')
        if (entitySchema.schemaName !== entityItems.items[0]?.nameSchema) {
          tableSelectors.push({
            renderItem: () => {
              return <AppTableCell>NaN</AppTableCell>
            },
          })
        } else if (key === 'title') {
          tableSelectors.push({
            renderItem: (item) => {
              return (
                <AppTableCell>
                  <NavLink to={`${item['pk']}`} className="value">
                  <p className="project-title_name">{item[key]}</p>
                    <p className="project-title_draft">
                      {item.is_published == false && 'Черновик'}
                    </p>
                  </NavLink>
                </AppTableCell>
              )
            },
          })
        } else {
          switch (entitySchema.fields[key].field_type) {
            case 'ForeignKey':
              tableSelectors.push({
                renderItem: (item) => {
                  const currentItem: IReferenceValuesTypeObject = item[key]
                  return (
                    <AppTableCell>
                      <ItemRepresentByDataType
                        itemData={currentItem}
                        //tableOptions={{ responsiblePersonViewHideIco: true }}
                      />
                    </AppTableCell>
                  )
                },
              })
              break
            case 'DateTimeField':
              tableSelectors.push({
                renderItem: (item) => {
                  const currentItem: EntityDataType = item[key]
                  return (
                    <AppTableCell>
                      <div style={{ whiteSpace: 'normal' }}>
                        <AppTranslation
                          label={
                            'task_section_widget_date_formatter__txt_title'
                          }
                          options={{ date: new Date(`${currentItem}`) }}
                        />
                      </div>
                    </AppTableCell>
                  )
                },
              })
              break
            case 'ManyToManyField':
              tableSelectors.push({
                renderItem: (item) => {
                  const currentItem: IReferenceValuesTypeObject[] = item[key]
                  return (
                    <AppTableCell>
                      <div className={'table-cell-grouped-items'}>
                        {currentItem &&
                          currentItem.map((item, index) => {
                            return (
                              <ItemRepresentByDataType
                                key={`item-${key}-${index}`}
                                itemData={item}
                                isMulti={currentItem.length > 1}
                              />
                            )
                          })}
                      </div>
                    </AppTableCell>
                  )
                },
              })
              break
            case 'TextField':
              tableSelectors.push({
                renderItem: (item) => {
                  const currentItem: EntityDataType = item[key]
                  const isDescription = key === 'description'
                  return (
                    <AppTableCell>
                      <div
                        className={`${isDescription ? 'text-ellipsis' : ''}`}
                        style={isDescription ? { width: 200 } : {}}
                      >
                        {!!currentItem && typeof currentItem === 'string'
                          ? currentItem
                          : 'NaN'}
                      </div>
                    </AppTableCell>
                  )
                },
              })
              break
            default:
              tableSelectors.push({
                renderItem: () => {
                  return <AppTableCell>NaN</AppTableCell>
                },
              })
          }
        }
      })
      tableHeaders.push('')
      tableSelectors.push({
        renderItem: (item) => {
          return (
            <AppTableCell justifyCell={AppTableCellJustify.right}>
              <div className="entity-actions">
                <div
                  className="add-task"
                  onClick={(evt) => evt.stopPropagation()}
                >
                  <AppButton
                    path={`${item.pk}/${AllRoutes.entityRootCreateTask.path}/${AllRoutes.tasksListing.path}`}
                    icoClassName={'an-ico-plus-thin'}
                    title={{
                      label: 'tasks_create_page__txt_nav_button_title',
                      // options: { entityName: entitySymbolKey },
                    }}
                    color={AppButtonColor.lightBlue}
                    size={AppButtonSize.small}
                  />
                </div>
                {/*<div className="fav" onClick={(evt) => evt.stopPropagation()}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1 18.55L12 18.65L11.89 18.55C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5C9.04 5 10.54 6 11.07 7.36H12.93C13.46 6 14.96 5 16.5 5C18.5 5 20 6.5 20 8.5C20 11.39 16.86 14.24 12.1 18.55ZM16.5 3C14.76 3 13.09 3.81 12 5.08C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.41 2 8.5C2 12.27 5.4 15.36 10.55 20.03L12 21.35L13.45 20.03C18.6 15.36 22 12.27 22 8.5C22 5.41 19.58 3 16.5 3Z"
                      fill="#818181"
                    />
                  </svg>
                  </div>*/}
                <Link
                  to={`${item.pk}/edit`}
                  className="edit"
                  onClick={(evt) => evt.stopPropagation()}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13"
                      stroke="#818181"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.0418 3.02001L8.16183 10.9C7.86183 11.2 7.56183 11.79 7.50183 12.22L7.07183 15.23C6.91183 16.32 7.68183 17.08 8.77183 16.93L11.7818 16.5C12.2018 16.44 12.7918 16.14 13.1018 15.84L20.9818 7.96001C22.3418 6.60001 22.9818 5.02001 20.9818 3.02001C18.9818 1.02001 17.4018 1.66001 16.0418 3.02001Z"
                      stroke="#818181"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.9102 4.15002C15.5802 6.54002 17.4502 8.41002 19.8502 9.09002"
                      stroke="#818181"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            </AppTableCell>
          )
        },
      })
    }

    return { tableHeaders, width, tableSelectors }
  }, [entityItems, entitySchema])

  return (
    <>
      <div className={'back-office-header'}>
        <h1>
          <AppTranslation
            label={AllRoutes.entityElements.name}
            options={{ entityName: entitySymbolKey }}
          />
        </h1>
        <div className={'header-actions-block'}>
          <div className="actions-left-block">
            <HeaderSearchInput
              searchParam={'search'}
              placeholder={'general__txt_search'}
            />
          </div>
          {/*
            <AppFilterDropdown
              searchParam={''}
              propToShowInList={'name'}
              valuePropName={'value'}
              data={publishedList || []}
              placeHolder={'Отчёт'}
              withFilter={true}
              withoutSearch={true}
              style={{ width: 200, marginLeft: 50 }}
              onChange={onChangeHandler}
            />
          */}
          {/*entitySymbolKey == 'Инфоповод' && (
            <div
              className={`app-input-wrapper app-dropdown${
                active ? ' active' : ''
              }`}
              style={{ width: '200px', marginLeft: '50px' }}
            >
              <div className="dropdown-wrapper">
                <div className="app-input-wrapper ">
                  <div
                    className=" empty aqua-blue btn"
                    onClick={() => {
                      setActive(!active)
                    }}
                  >
                    Отчёт
                    <div className="separator"></div>
                  </div>
                </div>
                <div
                  className="dropdown-values-block"
                  style={{ maxHeight: '234px' }}
                >
                  <div className="value-item">
                    <PDFDownloadLink
                      document={
                        <PDF
                          data={reportPrevious}
                          title={'за прошлую неделю'}
                        />
                      }
                      fileName="Отчет_за_прошлую_неделю.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? 'Загрузка...' : 'за прошлую неделю'
                      }
                    </PDFDownloadLink>
                  </div>
                  <div className="value-item">
                    <PDFDownloadLink
                      document={
                        <PDF data={reportCurrent} title={'за текущую неделю'} />
                      }
                      fileName="Отчет_за_текущую_неделю.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? 'Загрузка...' : 'за текущую неделю'
                      }
                    </PDFDownloadLink>
                  </div>
                </div>
              </div>
            </div>
                    )*/}
          <div className="actions-right-block">
            <AppButton
              path={AllRoutes.elementCreate.path}
              icoClassName={'an-ico-plus-thin'}
              title={{
                label: 'entity_element_create__txt_nav_bnt_title',
                options: { entityName: entitySymbolKey },
              }}
              color={AppButtonColor.lightBlue}
              size={AppButtonSize.small}
            />
          </div>
        </div>
      </div>
      {/*<AppTable
        headerTitles={tableConfig.tableHeaders}
        cellWidth={tableConfig.width}
        tableDataSelectors={tableConfig.tableSelectors}
        data={entityItems ? entityItems : null}
        tableAsEntity={true}
        lastColAlignLeft={true}
            />*/}
      <NewTable
        headerTitles={tableConfig.tableHeaders}
        tableDataSelectors={tableConfig.tableSelectors}
        dataTable={entityItems ? entityItems : null}
      />
    </>
  )
}
