import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { Breadcrumbs } from '../../../common/components/Breadcrumbs/Breadcrumbs'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { useGetInfoBlockByNameQuery } from '../../../core/api/BaseApiEndpoints/InfoBlocks/InfoBlocksApi'
import { AppTable } from '../../../common/components/AppTable/AppTable'
import { AppTableCell } from '../../../common/components/AppTable/AppTableCell'
import { HeaderSearchInput } from '../../../common/components/HeaderSearchInput/HeaderSearchInput'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import { NavLink } from 'react-router-dom'

const pageBreadcrumbs = [
  { name: 'info_blocks_page__txt_title', path: '../' },
  { name: 'info_block_details_page__txt_title' },
]

export const InfoBlockEditPage = () => {
  const { infoBlockSymbolLink } = useParams()

  const { data } = useGetInfoBlockByNameQuery(
    { infoBlockSymbolLink: infoBlockSymbolLink! },
    { skip: !infoBlockSymbolLink },
  )

  const tableConfig = useMemo(() => {
    const tableHeaders: any[] = []
    const newWidth: any[] = []
    if (data?.items[0]) {
      Object.keys(data?.items[0]).forEach((el) => {
        tableHeaders.push(el)
        newWidth.push('auto')
      })
    }
    return {
      tableHeaders,
      width: newWidth,
    }
  }, [data])

  const tableSelectors = useMemo(() => {
    const newSelectors: any[] = []
    if (data?.items[0]) {
      Object.keys(data?.items[0]).forEach((el, index) => {
        newSelectors.push({
          renderItem: (item: any) => {
            return (
              <AppTableCell>
                {index === 0 ? (
                  <NavLink to={`${item.id}`}>
                    <AppTableCell>
                      {typeof item[el] === 'object'
                        ? JSON.stringify(item[el])
                        : item[el]}
                    </AppTableCell>
                  </NavLink>
                ) : (
                  <AppTableCell>
                    {typeof item[el] === 'object'
                      ? JSON.stringify(item[el])
                      : item[el]}
                  </AppTableCell>
                )}
              </AppTableCell>
            )
          },
        })
      })
    }
    return newSelectors
  }, [data])

  if (!data) {
    return null
  }

  return (
    <>
      <div className={'back-office-header'}>
        <Breadcrumbs breadcrumbs={pageBreadcrumbs} />
        <h1>
          <AppTranslation label={AllRoutes.elementEdit.name} />:{' '}
          {infoBlockSymbolLink}
        </h1>
        <div className={'header-actions-block'}>
          <div className="actions-left-block">
            <HeaderSearchInput
              searchParam={'filters[search]'}
              placeholder={'general__txt_search'}
            />
          </div>
          <div className="actions-right-block">
            <AppButton
              path={AllRoutes.elementCreate.path}
              icoClassName={'an-ico-plus-thin'}
              title={{
                label: 'info_block_element_create_page__txt_nav_button_title',
                options: { projectName: infoBlockSymbolLink },
              }}
              color={AppButtonColor.lightBlue}
              size={AppButtonSize.small}
            />
          </div>
        </div>
      </div>
      <AppTable
        headerTitles={tableConfig.tableHeaders}
        cellWidth={tableConfig.width}
        tableDataSelectors={tableSelectors}
        data={data ? data : null}
        lastColAlignLeft={true}
      />
      {/*<div>{JSON.stringify(data)}</div>*/}
    </>
  )
}
