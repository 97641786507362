import React from 'react'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { Breadcrumbs } from '../../../common/components/Breadcrumbs/Breadcrumbs'
import { ProtectedAvaIco } from '../../../common/components/ProtectedImageIco/ProtectedImageIco'
import { useParams } from 'react-router'
import Avatar from '../../../common/components/UserBlocks/img/avatar.png'
import { useGetMembersQuery } from 'core/api/BaseApiEndpoints/Members/MembersApi'


const employeeBreadcrumbs = [
  { name: 'employees_page__txt_title', path: '../' },
  { name: 'employee_edit_page__txt_title' },
]

export const EmployeeEditPage = () => {
  const { employeeId } = useParams()
  const { data } = useGetMembersQuery({})
  const user = employeeId && data?.items && data.items.find((it:any) => it.id == employeeId)
  return (
    <>
      <div className={'back-office-header'}>
        <Breadcrumbs breadcrumbs={employeeBreadcrumbs} />
        <h1>
          <AppTranslation label={AllRoutes.employeeEdit.name} /> id:{' '}
          {employeeId}
        </h1>
      </div>
      <div className="employee-header-block">
        <div className="employee-ava">
          <ProtectedAvaIco src={user?.photo ? user?.photo : `/assets/images/ico/person.svg`}/>
        </div>
        <div className="employee-description">
          <div className="employee-description-main">
            <h2>{user?.last_name + ' ' + user?.first_name+ ' ' + `${user?.patronymic || ''}`}</h2>
            <div className="business-unit-name">{user?.post}</div>
          </div>
          <div className="employee-description-rows">
            <div className="employee-description-col">
              <div className="employee-description-value">
                <i className="an-ico an-ico-pin"></i>Россия, Санкт-Петербург
              </div>
              <div className="employee-description-value">
                {user?.phone && <a href={'tel:' + user?.phone}><i className="an-ico an-ico-phone"></i>{user?.phone}</a>}
              </div>
            </div>
            <div className="employee-description-col">
              <div className="employee-description-value">
                <i className="an-ico an-ico-bag"></i>{user?.organization_structure}
              </div>
              <div className="employee-description-value">
                {user?.email && <a href={'mailto:' + user?.email}><i className="an-ico an-ico-email"></i>{user?.email}</a>}
              </div>
            </div>
            {/*<div className="employee-description-col">
              <div className="work-experience-block">
                <div className="work-experience-img">
                  <i className="an-ico an-ico-portfolio"></i>
                </div>
                <div className="work-experience-description">
                  <div className="work-experience-title">Опыт работы</div>
                  <div className="work-experience-value">
                    <span>2</span> года
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
      {/*<div className="employee-main-info-block">
        <div className="row">
          <div className="col-3">
            <h2>Соцсети</h2>
            <p>sotrdnk</p>
            <p>sotrdnk_1</p>
            <p>sotrudnik_page</p>
            <h2>Знание языков</h2>
            <div className="tags-block">
              <div className="tag-view blue">Русский</div>
              <div className="tag-view blue">English</div>
              <div className="tag-view blue">Deutsch</div>
            </div>
          </div>
          <div className="col-3">
            <h2>О сотруднике</h2>
            <p>
              Являюсь главным менеджером в отделе маркетинга. Совершенствуюсь и
              развиваюсь в своем направлении деятельности ежедневно
            </p>
            <p>
              Работаю всего 2 года, но за это время смог проявить себя в важных
              российских проектах: http://portfolio.com
            </p>
            <h2>Документы</h2>
            <div className="doc-view">
              <i className="an-ico an-ico-docs"></i>
              <a href="/">myportfolio.pdf</a>
            </div>
            <div className="doc-view">
              <i className="an-ico an-ico-docs"></i>
              <a href="/">данные об участии во Всероссийском конкурсе</a>
            </div>
          </div>
          <div className="col-6">
            <h2>Навыки</h2>
            <div className="tags-block">
              <div className="tag-view blue">Маркетинг</div>
              <div className="tag-view blue">
                Реклама и связь с общественностью
              </div>
              <div className="tag-view blue">SMM</div>
              <div className="tag-view blue">Цифровой маркетинг</div>
              <div className="tag-view blue">Менеджер проектов</div>
              <div className="tag-view blue">
                Базовые знания программирования
              </div>
            </div>
            <h2>Сфера деятельности</h2>
            <div className="tags-block">
              <div className="tag-view blue">Продажи</div>
              <div className="tag-view blue">Менеджмент</div>
              <div className="tag-view blue">Startup Adviser</div>
              <div className="tag-view blue">Работа с клиентами</div>
              <div className="tag-view blue">Ведение интернет-проектов</div>
              <div className="tag-view blue">
                Базовые знания программирования
              </div>
              <div className="tag-view blue">Аналитика рынка</div>
              <div className="tag-view blue">
                Исследование рекламной деятельности
              </div>
              <div className="tag-view blue">Изучение конкурентов на рынке</div>
            </div>
          </div>
        </div>
      </div>*/}
    </>
  )
}
