import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from 'common/components/AppButton/AppButton'
import { AppTranslation } from 'common/components/AppTranslation/AppTranslation'
import { UserMainInfo } from 'common/components/UserBlocks/UserMainInfo'
import { UserNotification } from 'common/components/UserBlocks/UserNotification'
import { UserEvents } from 'common/components/UserBlocks/UserPage'
import { UserProjects } from 'common/components/UserBlocks/UserProjects'
import { UserReadyMaterials } from 'common/components/UserBlocks/UserReadyMaterials'
import { UserStatistic } from 'common/components/UserBlocks/UserStatistic'
import { UserTasks } from 'common/components/UserBlocks/UserTasks'
import { AllRoutes } from 'core/routes/AllRoutes'
import { Tasks } from '../Tasks/Tasks'

export const UserPage = () => {
  return (
    <div className="user-page">
      <div className={'back-office-header'}>
        <h1>
          <AppTranslation label={AllRoutes.user.name} />
        </h1>
      </div>

      <div className="top_div">
        <UserMainInfo />
        {/* <UserNotification /> */}
      </div>
      {/*<UserReadyMaterials />*/}

      <div className="statistics">
        {/*<UserStatistic />*/}
        <div className="event_main_div">
          <div className="event_main_div_all_sides event_main_div_left">
            <UserProjects />
          </div>
          {/* <div className="event_main_div_right event_main_div_all_sides">
            <UserEvents />
          </div> */}
        </div>
      </div>
      <UserTasks />
    </div>
  )
}
