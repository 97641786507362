import React, { useCallback, useEffect, useState } from 'react'
import { translationLabelTape } from '../AppTranslation/AppTranslation'
import { useGetEntityTypesQuery, useGetReferenceValuesForeignQuery } from '../../../core/api/BaseApiEndpoints/Entity/EntityApi'
import { IReferenceValuesSimple } from '../../../core/api/dto/EntityDto'
import { MultiselectDropdown } from './MultiselectDropdown'

interface IEntityItemMultiselect {
  defaultValue: IReferenceValuesSimple[] | number[] | null
  onChange: (value: IReferenceValuesSimple[] | null) => void
  error?: translationLabelTape | boolean
  valueRows?: number
  label?: string
  disabled?: boolean
  dataType: string
  withFilter?: boolean
}

export const EntityItemMultiselect = ({
  defaultValue,
  onChange,
  error,
  valueRows,
  label,
  disabled,
  dataType,
  withFilter,
}: IEntityItemMultiselect) => {
  const { data: referenceValuesForeign } = useGetReferenceValuesForeignQuery({
    ForeignKey: dataType,
  })

  const { data: entityNames } = useGetEntityTypesQuery()
  const entityItems: any[] = []
  entityNames?.map((item, id) => entityItems[id] = {id, title: item.name})

  const [isInitState, setIsInitState] = useState(true)
  const [currentValue, setCurrentValue] = useState<IReferenceValuesSimple[]>([])

  const changeHandler = useCallback(
    (value: IReferenceValuesSimple[] | null) => {
      onChange(value ? value : [])
    },
    [onChange],
  )

  useEffect(() => {
    if (defaultValue && referenceValuesForeign) {
      const result: IReferenceValuesSimple[] = []

      defaultValue.forEach((value) => {
        const findResult = referenceValuesForeign.find(
          (item) => item.id === (typeof value === 'number' ? value : value.id),
        )
        if (findResult) {
          result.push(findResult)
        }
      })

      setCurrentValue(result ? result : [])
      if (isInitState) {
        setIsInitState(false)
      }
    }
    else if (defaultValue && dataType == 'prohibited_entities') {
      const result: IReferenceValuesSimple[] = []

      defaultValue?.forEach((value) => {
        const findResult = entityItems.find(
          (item) => item.id === (typeof value === 'number' ? value : value.id),
        )
        if (findResult) {
          result.push(findResult)
        }
      })

      setCurrentValue(result ? result : [])
      if (isInitState) {
        setIsInitState(false)
      }
    }
    else if (referenceValuesForeign && !defaultValue && !isInitState && dataType != 'prohibited_entities') {
      setCurrentValue([])
      changeHandler([])
    }
  }, [referenceValuesForeign, defaultValue, changeHandler])

  return (
    <MultiselectDropdown
      label={label}
      data={dataType != 'prohibited_entities' ? referenceValuesForeign ? referenceValuesForeign : [] : entityItems}
      propToShowInList={'title'}
      valuePropName={'id'}
      value={currentValue}
      onChange={changeHandler}
      error={error}
      disabled={disabled}
      valueRows={valueRows}
      withFilter={withFilter}
    />
  )
}
