import React, { useState } from 'react'
import { AppInput } from '../../common/components/AppInput/AppInput'
import { AppButton } from '../../common/components/AppButton/AppButton'
import { AppTable } from '../../common/components/AppTable/AppTable'
import { AppTableCell } from '../../common/components/AppTable/AppTableCell'
import {
  PageNavTabs,
  PageNavTabsType,
} from '../../common/components/Breadcrumbs/PageNavTabs'
import {
  TabsNavigation,
  tabsNavigationType,
} from '../../common/components/TabsNavigation/TabsNavigation'
import { AppDropdown } from '../../common/components/AppDropdown/AppDropdown'
import {
  ITask,
  ITaskPriority,
  ITasksDto,
  ITaskStatus,
} from '../../core/api/dto/TasksDto'
import { TaskStatus } from '../../common/components/Tasks/TaskStatus'
import { TableExecutors } from '../../common/components/Tasks/TableExecutors'
import { PriorityWidget } from '../../common/components/PriorityWidget/PriorityWidget'
import { TaskCountDown } from '../../common/components/Tasks/TaskCountDown'
import { TaskStatusBar } from '../../common/components/Tasks/TaskStatusBar'
import { IWithPaginationResponse } from '../../core/api/dto/BaseDto'
import { NavLink } from 'react-router-dom'
import { ResponsiblePersonView } from '../../common/components/AppTable/ResponsiblePersonView'
import { AppTranslation } from '../../common/components/AppTranslation/AppTranslation'
import AppDatePicker from '../../common/components/AppDatePicker/AppDatePicker'

const tasksTableConfig = {
  width: [400, 100, 250, 230, 'auto'],
}

const tableConfig = {
  tableHeaders: [
    'employees_page__txt_table_header_name',
    'employees_page__txt_table_header_businessUnit',
  ],
  width: ['auto', '50%'],
}

interface IEmployeeDTO {
  id: number
  name: string
  businessUnit: string
}

interface IProtocolsResponseDTO extends IWithPaginationResponse {
  items: IEmployeeDTO[]
}

const tableData: IProtocolsResponseDTO = {
  count: 1000,
  limit: 10,
  page: 1,
  total: 10,
  items: [
    {
      id: 1,
      name: 'Важный сотрудник',
      businessUnit: 'Отдел маркетинга',
    },
    {
      id: 2,
      name: 'Важный сотрудник',
      businessUnit: 'Отдел маркетинга',
    },
    {
      id: 3,
      name: 'Важный сотрудник',
      businessUnit: 'Отдел маркетинга',
    },
    {
      id: 4,
      name: 'Важный сотрудник',
      businessUnit: 'Отдел маркетинга',
    },
    {
      id: 5,
      name: 'Важный сотрудник',
      businessUnit: 'Отдел маркетинга',
    },
    {
      id: 6,
      name: 'Важный сотрудник',
      businessUnit: 'Отдел маркетинга',
    },
  ],
}

const yesterday = new Date()
yesterday.setDate(yesterday.getDate() - 1)
const tenAgo = new Date()
tenAgo.setDate(tenAgo.getDate() - 10)
const tenPlus = new Date()
tenPlus.setDate(tenPlus.getDate() + 10)
const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)

const tasksTableData: ITasksDto = {
  limit: 10,
  count: 2,
  page: 1,
  total: 2,
  items: [
    {
      id: 1,
      name: 'NEW LOW',
      status: ITaskStatus.NEW,
      priority: ITaskPriority.LOW,
      description: 'example example example',
      date_start: yesterday,
      date_end: tomorrow,
      executor: [{ id: 1, first_name: 'first_name', last_name: 'last_name', full_name: 'full_name' }],
      co_executor: [],
      table_connected_id: null,
      table: null,
      task_document: [],
      task_media: [],
      entities: {},
    },
    {
      id: 2,
      name: 'DOING MEDIUM',
      status: ITaskStatus.DOING,
      priority: ITaskPriority.MEDIUM,
      description: 'example example example',
      date_start: tenAgo,
      date_end: yesterday,
      executor: [{ id: 1, first_name: 'first_name', last_name: 'last_name', full_name: 'full_name' }],
      co_executor: [],
      table_connected_id: null,
      table: null,
      task_document: [],
      task_media: [],
      entities: {},
    },
    {
      id: 3,
      name: 'OVERDUE HIGH',
      status: ITaskStatus.OVERDUE,
      priority: ITaskPriority.HIGH,
      description: 'example example example',
      date_start: tomorrow,
      date_end: tenPlus,
      executor: [{ id: 1, first_name: 'first_name', last_name: 'last_name', full_name: 'full_name' }],
      co_executor: [],
      table_connected_id: null,
      table: null,
      task_document: [],
      task_media: [],
      entities: {},
    },
    {
      id: 4,
      name: 'DONE HIGH',
      status: ITaskStatus.DONE,
      priority: ITaskPriority.HIGH,
      description: 'example example example',
      date_start: tenAgo,
      date_end: tomorrow,
      executor: [{ id: 1, first_name: 'first_name', last_name: 'last_name', full_name: 'full_name' }],
      co_executor: [],
      table_connected_id: null,
      table: null,
      task_document: [],
      task_media: [],
      entities: {},
    },
  ],
}

const demoData = [
  { id: 1, value: 'значение-1' },
  { id: 2, value: 'значение-2' },
  { id: 3, value: 'значение-3' },
  { id: 4, value: 'значение-4' },
  { id: 5, value: 'значение-5' },
  { id: 6, value: 'значение-6' },
]

const comparison = false

export const UiPage = () => {
  const [password, setPassword] = useState('test')
  const [textIco, setTextIco] = useState('test')
  const [text, setText] = useState('test')
  const [textArea, setTextArea] = useState('test')
  const [dropdownValue, setDropdownValue] = useState(demoData[0])

  const [date, setDate] = useState<null | Date>(null)

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <AppInput
          onChange={(e) => setTextIco(e)}
          value={textIco}
          type={'text'}
          inputIcoClassName={'an-ico-username'}
        />
      </div>
      <div>
        <AppInput
          onChange={(e) => setPassword(e)}
          value={password}
          type={'password'}
          inputIcoClassName={'an-ico-password'}
        />
      </div>
      <div>
        <AppInput
          onChange={(e) => setText(e)}
          value={text}
          label={'simple text'}
        />
      </div>
      <div>
        <AppInput
          onChange={(e) => console.log(e)}
          value={'disabled'}
          type={'text'}
          inputIcoClassName={'an-ico-location-double'}
          disabled={true}
        />
      </div>
      <div>
        <AppInput
          onChange={(e) => console.log(e)}
          value={'Label'}
          type={'text'}
          inputIcoClassName={'an-ico-location-double'}
          label={'Label'}
        />
      </div>
      <div>
        <AppInput
          onChange={(value) => setTextArea(value)}
          isTextArea={true}
          label={'textArea'}
          value={textArea}
          rows={2}
        />
      </div>
      <div>
        <AppInput
          onChange={(e) => console.log(e)}
          value={'Error'}
          type={'text'}
          inputIcoClassName={'an-ico-location-double'}
          label={'Error'}
          error={'error msg'}
        />
        <div>
          <AppDropdown
            data={demoData}
            propToShowInList={'value'}
            value={dropdownValue}
            onChange={(value) => setDropdownValue(value)}
          />
        </div>
      </div>
      <div>
        <AppInput
          onChange={(e) => console.log(e)}
          value={'rightTextMsg'}
          type={'text'}
          inputIcoClassName={'an-ico-location-double'}
          rightTextMsg={'kWh'}
          label={'rightTextMsg'}
        />
      </div>
      <div className={'btn-block'}>
        <AppButton title={'Btn'} />
        <AppButton title={'BtnLink'} path={'/ui'} />
        <AppButton title={'BtnLinkDisabled'} path={'/ui'} disabled={true} />
        <AppButton icoClassName={'an-ico-eye'} />
      </div>
      <div className="back-office-header">
        <PageNavTabs
          data={[
            { name: 1, value: 1 },
            { name: 2, value: 2 },
          ]}
          propToShow={'name'}
          valuePropName={'value'}
          searchParam={'filters[state]'}
          type={PageNavTabsType.searchParams}
        />
        <TabsNavigation
          data={[
            { name: 10, value: 10 },
            { name: 20, value: 20 },
          ]}
          type={tabsNavigationType.filter}
          propToShow={'name'}
          valuePropName={'value'}
          searchParam={'tabs-filter'}
        />
      </div>
      <AppTable
        data={tasksTableData}
        cellWidth={tasksTableConfig.width}
        tableDataSelectors={[
          {
            renderItem: ({ name, status, id }: ITask) => (
              <AppTableCell>
                <TaskStatus name={name} status={status} taskId={id} />
              </AppTableCell>
            ),
          },
          {
            renderItem: ({ executor }: ITask) => (
              <AppTableCell>
                <TableExecutors
                  data={executor}
                  maxWidth={Number(tableConfig.width[1])}
                />
              </AppTableCell>
            ),
          },
          {
            renderItem: ({ priority }: ITask) => (
              <AppTableCell>
                <PriorityWidget priority={priority} />
              </AppTableCell>
            ),
          },
          {
            renderItem: ({ date_end }: ITask) => (
              <AppTableCell>
                <TaskCountDown date_end={date_end} />
              </AppTableCell>
            ),
          },
          {
            renderItem: ({ date_end, date_start }: ITask) => (
              <AppTableCell>
                <TaskStatusBar date_end={date_end} date_start={date_start} />
              </AppTableCell>
            ),
          },
        ]}
        tableAsTasks={true}
        lastColAlignLeft={true}
      />
      <AppTable
        headerTitles={tableConfig.tableHeaders}
        tableDataSelectors={[
          {
            renderItem: (item: IEmployeeDTO) => (
              <NavLink to={`${item.id}`}>
                <ResponsiblePersonView name={item.name} />
              </NavLink>
            ),
          },
          {
            renderItem: (item: IEmployeeDTO) => (
              <AppTableCell>
                <AppTableCell>{item.businessUnit}</AppTableCell>
              </AppTableCell>
            ),
          },
        ]}
        data={tableData}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xDays`}
        options={{ count: 1 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xHours`}
        options={{ count: 1 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xMinutes`}
        options={{ count: 1 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xMonths`}
        options={{ count: 1 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xSeconds`}
        options={{ count: 1 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xYears`}
        options={{ count: 1 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xDays`}
        options={{ count: 2 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xHours`}
        options={{ count: 2 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xMinutes`}
        options={{ count: 2 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xMonths`}
        options={{ count: 2 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xSeconds`}
        options={{ count: 2 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xYears`}
        options={{ count: 2 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xDays`}
        options={{ count: 4 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xHours`}
        options={{ count: 5 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xMinutes`}
        options={{ count: 7 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xMonths`}
        options={{ count: 8 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xSeconds`}
        options={{ count: 9 }}
      />
      <AppTranslation
        label={`task_count_down_${
          comparison ? 'in_time' : 'fucked'
        }_date_xYears`}
        options={{ count: 10 }}
      />
      <div>
        <AppDatePicker
          value={date}
          onChange={(value) => setDate(value)}
          label={'general_date_start__txt_default_label'}
          error={false}
          disabled={false}
          fullWidth={true}
        />
      </div>
    </div>
  )
}
