import React, {
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
  memo,
  useMemo,
  CSSProperties,
  useCallback,
} from 'react'
import { AppInput } from '../AppInput/AppInput'
import {
  AppTranslation,
  translationLabelTape,
} from '../AppTranslation/AppTranslation'
import { useTranslation } from 'react-i18next'

interface IAppDropdown<T, TKey> {
  data: T[] // data - может быть объектом с ключами, в этом случае мы можем передать propToShowInList и propToShowInInput
  value: T | null | boolean
  propToShowInList?: TKey // если data массив строк, то не передаем propToShowInList и propToShowInInput
  propToShowInInput?: TKey
  label?: translationLabelTape
  emptyLabel?: translationLabelTape
  placeholder?: translationLabelTape
  onChange: (value: T) => void
  // onBlur?: (event: ChangeEvent<HTMLInputElement>) => void
  resetValueHandler?: () => void // используется для сброса фильтрации
  inputSearchFn?: (str: string) => void
  white?: boolean
  disabled?: boolean
  error?: translationLabelTape | boolean
  showInListRepresent?: (item: T) => JSX.Element
  customInput?: (value: T | null) => JSX.Element | null
  isOutSideOpen?: boolean // нужно в составе suggests, когда мы хотим оставлять дропдаун открытым до момента выбора корректного адреса
  tagView?: boolean // представление в виде тегов.
  fullWidth?: boolean
  withFilter?: boolean
  withoutSearch?: boolean
  style?: CSSProperties | undefined
  valueRows?: number
  dontHideListOnChange?: boolean
}

export const AppDropdown = <T, TKey extends keyof T>({
  data,
  onChange,
  propToShowInList,
  value = null,
  placeholder = '',
  resetValueHandler,
  propToShowInInput,
  inputSearchFn,
  error = false,
  showInListRepresent,
  customInput,
  disabled,
  isOutSideOpen = false,
  label,
  emptyLabel = 'general__txt_dropdown_no_data',
  tagView,
  fullWidth,
  withFilter,
  withoutSearch,
  style,
  valueRows = 5,
  dontHideListOnChange,
}: PropsWithChildren<IAppDropdown<T, TKey>>) => {
  const [currentData, setCurrentData] = useState(data ? data : [])
  const [active, setActive] = useState(false)
  const [inputValue, setInputValue] = useState<string | null | boolean>(null)
  const [valuesBlockHeight, setValuesBlockHeight] = useState(0)

  const [keyboardFocusedInput, setKeyboardFocusedInput] = useState<
    null | number
  >(null)
  const dataLength = currentData.length

  const currentValue = useMemo(() => {
    return typeof value === 'boolean'
      ? value
      : !!value
      ? propToShowInInput
        ? `${value[propToShowInInput]}`
        : propToShowInList
        ? `${value[propToShowInList]}`
        : `${value}`
      : null
  }, [value, propToShowInInput, propToShowInList])

  useEffect(() => {
    if (data) {
      setCurrentData(data)
    }
  }, [data])

  useEffect(() => {
    if (withFilter) {
      const result: T[] = []
      data.forEach((item) => {
        const currentValue: string = (
          propToShowInList
            ? (item[propToShowInList] as unknown as string)
            : (item as unknown as string)
        )?.toLowerCase()
        if (
          !inputValue ||
          currentValue?.indexOf(
            typeof inputValue !== 'boolean' ? inputValue?.toLowerCase() : '',
          ) >= 0
        ) {
          result.push(item)
        }
      })

      setCurrentData(result)
    }
  }, [inputValue, data, propToShowInList, withFilter])

  useEffect(() => {
    setInputValue(currentValue)
  }, [currentValue])

  const getDropDownDimensions = () => {
    if (refDropDownItems.current) {
      setValuesBlockHeight(
        (refDropDownItems.current.children.length > 0
          ? refDropDownItems.current.children[0].clientHeight *
            (resetValueHandler ? 1 : valueRows)
          : 50 * valuesBlockHeight) +
          (resetValueHandler && refDropDownItems.current.children.length > 1
            ? refDropDownItems.current.children[1].clientHeight * valueRows
            : 0),
      )

      let isBeforeFocusedByKeyboardItem = true
      let verticalTrackHeight = 0
      let activeItemDepth = 0
      const dropdownWrapperHeight = refDropDownItems.current.clientHeight
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < refDropDownItems.current.children.length; i++) {
        verticalTrackHeight += refDropDownItems.current.children[i].clientHeight
        activeItemDepth = isBeforeFocusedByKeyboardItem
          ? verticalTrackHeight
          : activeItemDepth
        isBeforeFocusedByKeyboardItem =
          isBeforeFocusedByKeyboardItem &&
          refDropDownItems.current.children[i].classList.contains('focused')
            ? false
            : isBeforeFocusedByKeyboardItem
      }
      if (
        !isBeforeFocusedByKeyboardItem &&
        activeItemDepth - dropdownWrapperHeight > 0
      ) {
        refDropDownItems.current.scrollTop =
          activeItemDepth - dropdownWrapperHeight
      } else {
        refDropDownItems.current.scrollTop = 0
      }
    }
  }
  const useOutSideOpen = !isOutSideOpen
  const refDropDown = useRef<HTMLDivElement>(null)
  const refInput = useRef<HTMLDivElement>(null)
  const refDropDownItems = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (refInput.current && active) {
      refInput.current.focus()
    }
  }, [active])

  useEffect(() => {
    if (useOutSideOpen && !isOutSideOpen && active) {
      setActive(false)
      setKeyboardFocusedInput(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOutSideOpen, useOutSideOpen])

  useEffect(() => {
    if (!active && inputValue !== currentValue) {
      setInputValue(currentValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  const outSideClickHandler = useCallback(
    (e: any) => {
      e.stopPropagation()
      if (refDropDown.current && !refDropDown.current.contains(e.target)) {
        setActive(false)
        setKeyboardFocusedInput(null)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentValue, setActive, setKeyboardFocusedInput, setInputValue],
  )

  useEffect(() => {
    if (active) {
      document.addEventListener('click', outSideClickHandler, false)
      document.addEventListener('touchend', outSideClickHandler, false)
    }
    return () => {
      if (active) {
        document.removeEventListener('click', outSideClickHandler, false)
        document.removeEventListener('touchend', outSideClickHandler, false)
      }
    }
  }, [active, outSideClickHandler])

  const onChangeHandler = (item: any) => {
    if (item === value && item!=null) {
      setInputValue(currentValue)
    } else if (item) {
      onChange(item)
    } else if (item==null) {
      onChange(item)
    } else if (resetValueHandler) {
      resetValueHandler()
    }
    if (!isOutSideOpen && !dontHideListOnChange) {
      setActive(false)
      setKeyboardFocusedInput(null)
    }
  }

  useEffect(() => {
    if (!!keyboardFocusedInput && keyboardFocusedInput > dataLength - 1) {
      setKeyboardFocusedInput(dataLength - 1)
    } else if (dataLength === 0) {
      setKeyboardFocusedInput(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLength])

  useEffect(() => {
    getDropDownDimensions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyboardFocusedInput])

  const changeFocusedItemByKeyboard = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    switch (event.code) {
      case 'ArrowUp':
        event.preventDefault()
        setKeyboardFocusedInput((prev) =>
          !prev ? 0 : prev - 1 < 0 ? 0 : prev - 1,
        )
        break
      case 'ArrowDown':
        event.preventDefault()
        setKeyboardFocusedInput((prev) => {
          if (prev !== null) {
            return prev + 1 > dataLength - 1 ? dataLength - 1 : prev + 1
          } else {
            return 0
          }
        })
        break
      case 'Enter':
        event.preventDefault()
        if (keyboardFocusedInput !== null) {
          onChangeHandler(currentData[keyboardFocusedInput])
          refInput.current && refInput.current.blur()
        }
        break
      case 'Tab':
        event.preventDefault()
        refInput.current && refInput.current.blur()
        setActive(false)
        break
      default:
        break
    }
  }

  useEffect(() => {
    getDropDownDimensions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, currentData])

  const { t } = useTranslation()

  return (
    <div
      className={`app-input-wrapper app-dropdown${active ? ' active' : ''} ${
        fullWidth ? 'full-width-input' : ''
      }`}
      style={style}
      ref={refDropDown}
    >
      <div className="dropdown-wrapper">
        <AppInput
          onClick={
            disabled
              ? null
              : () => {
                  setActive((prev) => !prev)
                  if (withFilter) {
                    setInputValue('')
                  }
                }
          }
          onChange={(newValue) => {
            if (!active) {
              setActive(true)
            }
            return inputSearchFn
              ? inputSearchFn(newValue)
              : setInputValue(newValue ? newValue : null)
          }}
          refInput={refInput}
          customInput={
            withFilter && active
              ? null
              : customInput
              ? customInput(typeof value !== 'boolean' ? value : null)
              : null
          }
          disableInput={!withFilter && !inputSearchFn}
          dropdownInput={true}
          disabled={disabled}
          label={label}
          value={
            inputSearchFn
              ? value
                ? `${value}`
                : ''
              : inputValue && inputValue != true
              ? t(`${inputValue}`)
              : ''
          }
          fullWidth={fullWidth}
          error={!!error}
          placeholder={placeholder}
          keyDirectionFn={!disabled ? changeFocusedItemByKeyboard : null}
        />
        {active && !!currentData && (
          <div
            className={`dropdown-values-block${tagView ? ' tag-view' : ''}`}
            style={{ maxHeight: valuesBlockHeight }}
            ref={refDropDownItems}
          >
            {resetValueHandler && !withoutSearch && (
              <div
                className={'value-item reset-filter'}
                onClick={() => onChangeHandler(null)}
              >
                <AppTranslation label={'dropdown_filter__txt_reset_filter'} />{' '}
                <i className="an-ico an-ico-cross" />
              </div>
            )}
            {currentData.map((item, index) => {
              const itemValue = showInListRepresent ? (
                showInListRepresent(item)
              ) : propToShowInList ? (
                <AppTranslation label={`${item[propToShowInList]}`} />
              ) : (
                <AppTranslation label={`${item}`} />
              )
              return (
                <div
                  className={`value-item${
                    keyboardFocusedInput === index ? ' focused' : ''
                  }`}
                  key={`active-dropdown-${index}`}
                  onClick={() => onChangeHandler(item)}
                >
                  {itemValue}
                </div>
              )
            })}
            {currentData.length === 0 && (
              <div className={`value-item`}>
                <AppTranslation label={emptyLabel} />
              </div>
            )}
          </div>
        )}
      </div>
      {error && typeof error === 'string' && (
        <div className="error-msg">
          <AppTranslation label={error} />
        </div>
      )}
    </div>
  )
}

type IFn = <T>(fn: T) => T
const typedMemo = memo as IFn
export const AppDropdownMemo = typedMemo(AppDropdown)
