import React from 'react'

const IconComment: React.FC = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.8242 1.32422V11.8242L9.5 9.5H1.32422C1.01432 9.5 0.740885 9.38151 0.503906 9.14453C0.285156 8.90755 0.175781 8.63411 0.175781 8.32422V1.32422C0.175781 1.01432 0.285156 0.75 0.503906 0.53125C0.740885 0.294271 1.01432 0.175781 1.32422 0.175781H10.6758C10.9857 0.175781 11.25 0.294271 11.4688 0.53125C11.7057 0.75 11.8242 1.01432 11.8242 1.32422Z" />
    </svg>
  )
}

export default IconComment
