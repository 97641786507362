import React from 'react'

const IconReply: React.FC = () => {
  return (
    <svg width="13" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="m.72 10 11.662-5L.721 0 .715 3.889 9.05 5 .715 6.111.721 10z" />
    </svg>
  )
}

export default IconReply
