import React, { useCallback } from 'react'
import { ru } from 'date-fns/locale'
import { formatDistanceToNowStrict } from 'date-fns'
import { AppTranslation } from '../AppTranslation/AppTranslation'

interface ITaskCountDown {
  date_end: Date
}

export const TaskCountDown = ({ date_end }: ITaskCountDown) => {
  const getFormattedDistanceToNow = useCallback(
    (date: Date | number): string => {
      const options = {
        locale: {
          ru,
          formatDistance: (
            unit: string,
            count: number,
            { comparison }: { comparison: number },
          ) => {
            return (
              <AppTranslation
                label={`task_count_down_${
                  comparison > 0 ? 'in_time' : 'fucked'
                }_date_${unit}`}
                options={{ count }}
              />
            )
          },
        },
      }

      return formatDistanceToNowStrict(date, options)
    },
    [],
  )

  return (
    <div className="task-count-down">
      <span className="value">
        {getFormattedDistanceToNow(new Date(date_end))}
      </span>
    </div>
  )
}
