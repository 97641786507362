import React, { useCallback, useMemo, useState } from 'react'
import {
  IFormRowData,
  inputControllerType,
} from '../../../common/components/PageFormController/PageFormController'
import { Breadcrumbs } from '../../../common/components/Breadcrumbs/Breadcrumbs'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { IFormErrorDto } from '../../../core/api/BaseApi'
import { PageFormController } from '../../../common/components/PageFormController/PageFormController'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import { useGoBack } from '../../../common/components/HeaderBackButton/HeaderBackButton'
import { useParams } from 'react-router'
import { useGetCompanyByIdQuery } from '../../../core/api/BaseApiEndpoints/Companies/CompaniesApi'

const companiesBreadcrumbs = [
  { name: 'companies_page__txt_title', path: '../' },
  { name: 'company_create_page__txt_title' },
]

export const companyFormConfig: IFormRowData[] = [
  {
    title: null,
    rowItems: [
      {
        type: inputControllerType.input,
        name: 'name',
        label: 'company_create_page__txt_form_company_name',
        colClassName: 'col-4',
        required: 'general__txt_required_field',
      },
      {
        type: inputControllerType.input,
        name: 'description',
        label: 'company_create_page__txt_form_company_description',
        colClassName: 'col-4',
        required: 'general__txt_required_field',
      },
      {
        type: inputControllerType.input,
        name: 'purpose',
        label: 'company_create_page__txt_form_company_purpose',
        colClassName: 'col-4',
        required: 'general__txt_required_field',
      },
      {
        type: inputControllerType.input,
        name: 'risks',
        label: 'company_create_page__txt_form_company_risks',
        colClassName: 'col-4',
        required: 'general__txt_required_field',
      },
      {
        type: inputControllerType.input,
        name: 'start_date',
        label: 'general_date_start__txt_default_label',
        colClassName: 'col-4',
        required: 'general__txt_required_field',
      },
      {
        type: inputControllerType.input,
        name: 'start_end',
        label: 'general_date_end__txt_default_label',
        colClassName: 'col-4',
        required: 'general__txt_required_field',
      },
      {
        type: inputControllerType.input,
        name: 'media',
        label: 'company_create_page__txt_form_company_media',
        colClassName: 'col-4',
        required: 'general__txt_required_field',
      },
      {
        type: inputControllerType.input,
        name: 'infopods',
        label: 'company_create_page__txt_form_company_infopods',
        colClassName: 'col-4',
        required: 'general__txt_required_field',
      },
      {
        type: inputControllerType.input,
        name: 'responsible_user',
        label: 'company_create_page__txt_form_company_responsible_user',
        colClassName: 'col-4',
        required: 'general__txt_required_field',
      },
      {
        type: inputControllerType.input,
        name: 'threat',
        label: 'company_create_page__txt_form_company_threat',
        colClassName: 'col-4',
        required: 'general__txt_required_field',
      },
      {
        type: inputControllerType.input,
        name: 'document',
        label: 'company_create_page__txt_form_company_document',
        colClassName: 'col-4',
        required: 'general__txt_required_field',
      },
      {
        type: inputControllerType.input,
        name: 'collaborator_user',
        label: 'company_create_page__txt_form_company_collaborator_user',
        colClassName: 'col-4',
        required: 'general__txt_required_field',
      },
    ],
  },
]

export const CompanyEditPage = () => {
  const { companyId } = useParams()

  const { data } = useGetCompanyByIdQuery({ companyId: companyId! })

  console.log('data => ', data)

  const [willSubmit, setWillSubmit] = useState(false)
  const [formError, setFormError] = useState<IFormErrorDto | null>(null)
  const [isFormDirty, setIsFormDirt] = useState(false)

  const { goBackHandler } = useGoBack()
  const backBtnHandler = useCallback(() => {
    if (!isFormDirty) {
      goBackHandler()
    } else alert('Есть несохраненные данные')
  }, [isFormDirty, goBackHandler])

  const initFormState = useMemo(() => {
    // const newState = {}
    // if(data) {
    //   Object.keys(data).forEach((key) => {
    //     if(key !== 'id') {
    //
    //     }
    //   })
    // }
    return data ? data : {}
  }, [data])

  const formDataChangeHandler = useCallback(
    ({ id, ...data }, isValid: boolean) => {
      if (isValid) {
      }
      setWillSubmit(false)
    },
    [],
  )

  const dirtyFormCallBack = useCallback(
    (value: boolean) => {
      setIsFormDirt(value)
    },
    [setIsFormDirt],
  )

  return (
    <React.Fragment>
      <div className={'back-office-header'}>
        <Breadcrumbs breadcrumbs={companiesBreadcrumbs} />
        <h1>
          <AppTranslation label={AllRoutes.companyCreate.name} />
        </h1>
      </div>
      <PageFormController
        formData={companyFormConfig}
        willSubmit={willSubmit}
        formDataChangeHandler={formDataChangeHandler}
        fetchError={formError}
        dirtyFormCallBack={dirtyFormCallBack}
        initFormState={initFormState}
      />
      <div className={'col-4 btn-block'} style={{ marginTop: '30px' }}>
        <AppButton
          onClick={() => setWillSubmit(true)}
          color={AppButtonColor.aquaBlue}
          size={AppButtonSize.base}
          title={'general__button_save'}
        />
        <div style={{ width: '20px' }} />
        <AppButton
          onClick={backBtnHandler}
          color={AppButtonColor.lightRed}
          size={AppButtonSize.base}
          title={'general__button_cancel'}
        />
      </div>
    </React.Fragment>
  )
}
