import React, { useMemo } from 'react'
import { useGetInfoBlocksQuery } from '../../../core/api/BaseApiEndpoints/InfoBlocks/InfoBlocksApi'
import { AppTableCell } from '../../../common/components/AppTable/AppTableCell'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { HeaderSearchInput } from '../../../common/components/HeaderSearchInput/HeaderSearchInput'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import { AppTable } from '../../../common/components/AppTable/AppTable'
import { IInfoBlocksListing } from '../../../core/api/dto/InfoBlockDto'
import { NavLink } from 'react-router-dom'

const cellPadding = 30

const tableConfig = {
  tableHeaders: [
    'info_blocks_page__txt_table_header_name',
    'info_blocks_page__txt_table_header_count',
    'general__table_action',
  ],
  width: ['auto', 200 + cellPadding, 440 + cellPadding],
}

export const InfoBlocksListingPage = () => {
  const { data } = useGetInfoBlocksQuery()
  const tableSelectors = useMemo(() => {
    return [
      {
        renderItem: (item: IInfoBlocksListing) => (
          <AppTableCell>
            <NavLink to={`/${AllRoutes.projects.path}/${item.symbolLink}`}>
              {item.name}
            </NavLink>
          </AppTableCell>
        ),
      },
      {
        renderItem: (item: IInfoBlocksListing) => (
          <AppTableCell>{item.count}</AppTableCell>
        ),
      },
      {
        renderItem: (item: IInfoBlocksListing) => (
          <AppTableCell>
            <>
              <NavLink to={`${item.symbolLink}`}>
                <React.Fragment>
                  <i
                    className={'an-ico an-ico-eye'}
                    style={{
                      marginLeft: 20,
                      marginRight: 5,
                      display: 'inline-block',
                      fontSize: 12,
                    }}
                  />
                  Редактировать Инфоблок
                </React.Fragment>
              </NavLink>
              <NavLink
                to={`/${AllRoutes.projects.path}/${item.symbolLink}/${AllRoutes.elementCreate.path}`}
              >
                <React.Fragment>
                  <i
                    className={'an-ico an-ico-plus'}
                    style={{
                      marginLeft: 20,
                      marginRight: 5,
                      display: 'inline-block',
                      fontSize: 12,
                    }}
                  />
                  Создать элемент
                </React.Fragment>
              </NavLink>
            </>
          </AppTableCell>
        ),
      },
    ]
  }, [])
  return (
    <>
      <div className={'back-office-header'}>
        <h1>
          <AppTranslation label={AllRoutes.infoBlocks.name} />
        </h1>
        <div className={'header-actions-block'}>
          <div className="actions-left-block">
            <HeaderSearchInput
              searchParam={'filters[search]'}
              placeholder={'general__txt_search'}
            />
          </div>
          <div className="actions-right-block">
            <AppButton
              path={AllRoutes.elementCreate.path}
              icoClassName={'an-ico-plus-thin'}
              title={'info_blocks_create_page__txt_nav_button_title'}
              color={AppButtonColor.lightBlue}
              size={AppButtonSize.small}
            />
          </div>
        </div>
      </div>
      <AppTable
        headerTitles={tableConfig.tableHeaders}
        cellWidth={tableConfig.width}
        tableDataSelectors={tableSelectors}
        data={
          data
            ? { count: 1000, limit: 10, page: 1, total: 10, items: data }
            : null
        }
        lastColAlignLeft={true}
      />
    </>
  )
}
