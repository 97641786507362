import React, { ChangeEvent, useCallback } from 'react'
import { AppTranslation } from '../AppTranslation/AppTranslation'

interface IAppCheckbox {
  onChange?: (value: boolean) => void
  value: boolean
  disabled?: boolean
  label?: string
  rightLabel?: string
  uniqId: string
}

export const AppCheckbox = ({
  onChange,
  value,
  disabled,
  label,
  rightLabel,
  uniqId,
}: IAppCheckbox) => {
  const changeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.checked)
      }
    },
    [onChange],
  )
  return (
    <div className="app-input-wrapper option-wrapper">
      {label && (
        <div className={`app-input-label${disabled ? ' disabled' : ''}`}>
          <b>
            <AppTranslation label={label} />
          </b>
        </div>
      )}
      <div className={`check option${disabled ? ' disabled' : ''}`}>
        <input
          type="checkbox"
          className="checkbox"
          id={uniqId}
          checked={value}
          onChange={changeHandler}
        />
        {!onChange ? (
          <div className={'label'}>
            {rightLabel && (
              <span>
                <AppTranslation label={rightLabel} />
              </span>
            )}
          </div>
        ) : (
          <label className={'label'} htmlFor={uniqId}>
            {rightLabel && (
              <span>
                <AppTranslation label={rightLabel} />
              </span>
            )}
          </label>
        )}
      </div>
    </div>
  )
}
