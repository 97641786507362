import LeftArrowImg from './img/left-arrow.svg'
import RightArrowImg from './img/right-arrow1.svg'
import GroupImg from './img/Group.svg'
import Group127Img from './img/Group127.svg'
import VkImg from './img/vk.svg'
import VectorImg from './img/Vector.svg'
import Vector3Img from './img/Vector3.png'
import Vector4Img from './img/Vector4.png'
import Post from '../Post/Post'
import { mockData } from './mockData'
import { DatePicker, Space } from 'antd'
import newMockData from './mock.json'
import { useEffect, useState } from 'react'

const { RangePicker } = DatePicker

const networksList = [{name: 'vk', id: '1'}, {name: 'tw', id: '2'}, {name: 'yt', id: '8'}, {name: 'ig', id: ''}, {name: 'tg', id: '5'}, {name: 'fb', id: ''}, {name: 'gs', id: '4'}]

export const MaterialAnalytics = () => {
  const [selectedNetworks, setSelectedNetworks] = useState<string[]>(networksList.map(it => it.name))
  const [selectedReport, setSelectedReport] = useState<string>('publications')
  const [reportLink, setReportLink] = useState<string>('#')
  const [filteredPosts, setFilteredPosts] = useState(newMockData.posts)
  const counts = {
    vk: newMockData.posts.filter(it => it.network_name === 'vk').length,
    tw: newMockData.posts.filter(it => it.network_name === 'tw').length,
    yt: newMockData.posts.filter(it => it.network_name === 'yt').length,
    ig: newMockData.posts.filter(it => it.network_name === 'ig').length,
    tg: newMockData.posts.filter(it => it.network_name === 'tg').length,
    fb: newMockData.posts.filter(it => it.network_name === 'fb').length,
    gs: newMockData.posts.filter(it => it.network_name === 'gs').length,
  }

  const getNetworkFilter = (): string => {
    let result = ''

    selectedNetworks.forEach((it, idx) => {
      const currentNetwork = networksList.find(item => item.name === it)
      if (currentNetwork?.id) {
        result += `&filter[network_id][${idx}]=${currentNetwork.id}`
      }
    })

    return result
  }

  const selectNetworkHandler = (val: string) => {
    setSelectedNetworks(prevState => selectedNetworks.includes(val) ? prevState.filter(it => it !== val) : [...prevState, val])
  }

  useEffect(() => {
    let result = '#'
    if (selectedReport === 'publications') {
      result = `https://api.glassen-it.com/component/socparser/content/getReportBulletin?thread_id=6153&from=2023-09-01%2000:00:00&to=2023-10-29%2023:59:59&reference_ids[]=2595&${getNetworkFilter()}&sort=0`
    }
    if (selectedReport === 'posts') {
      result = `https://api.glassen-it.com/component/socparser/content/posts_excell?thread_id=6153&from=2023-09-01%2000:00:00&to=2023-10-29%2023:59:59&sort[type]=date&sort[order]=desc&filter[repostoption]=whatever${getNetworkFilter()}&filter[referenceFilter][0]=2595`
    }
    if (selectedReport === 'comments') {
      result = `https://api.glassen-it.com/component/socparser/content/comments_excell?thread_id=6153&from=2023-09-01&to=2023-10-29&referenceFilter[]=2595`
    }

    setReportLink(result)
  }, [selectedReport, selectedNetworks])

  useEffect(() => {
    setFilteredPosts(newMockData.posts.filter(it => selectedNetworks.includes(it.network_name)))
  }, [selectedNetworks])

  return (
    <div className="mysection__content">
      <div className="content__top">
        <h2>Лента публикаций</h2>
        <p>
          Публикации по интересующим Вас субъектам и событиям из СМИ и
          социальных сетей.
        </p>
      </div>
      <div className="content__statistics">
        <div className="content__statistics-left">
          {/* <div className="content__topb">
            <h2>Аналитика</h2>
            <p>
              3 сентября в парке “Остров Фронтов” пройдет фестиваль “Рокштадт”
            </p>
          </div> */}
          <div className="statistics-left__content">
            <div className="statistics-left__contents">
              <h3>
                Количество источников: <span>110</span>
              </h3>
              <div className="statistics-left__statc">
                <div className="statistics-left__statc-flexb">
                  <div className="statistics-left__statc-flex">
                    <div className="statc-flex__left">
                      <h3>Общее количество публикаций по Готовому материалу</h3>
                    </div>
                    <div className="statc-flex__right">
                      <div className="statc-flex__right-norm">
                        <div className="statc-flex__right-green statc-flex__right-green1">
                          <b>110</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="statistics-left__statc-flex">
                    <div className="statc-flex__left">
                      <h3>Общее количество публикаций в социальных сетях</h3>
                    </div>
                    <div className="statc-flex__right">
                      <div className="statc-flex__right-norm">
                        <div className="statc-flex__right-green statc-flex__right-green2">
                          <b>98</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="statistics-left__statc-flex">
                    <div className="statc-flex__left">
                      <h3>Нейтральных публикаций</h3>
                    </div>
                    <div className="statc-flex__right">
                      <div className="statc-flex__right-norm">
                        <div className="statc-flex__right-green statc-flex__right-green3">
                          <b>98</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="statistics-left__statc-flexb">
                  <div className="statistics-right__statc-flex-right">
                    <div className="statc-flex-right__left">
                      <h3>Общее количество публикаций из СМИ</h3>
                    </div>
                    <div className="statc-flex-right__right">
                      <div className="statc-flex-right__right-norm">
                        <div className="statc-flex-right__right-green statc-flex-right__right-green1">
                          <b>12</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="statistics-right__statc-flex-right">
                    <div className="statc-flex-right__left">
                      <h3>Положительных публикаций</h3>
                    </div>
                    <div className="statc-flex-right__right">
                      <div className="statc-flex-right__right-norm">
                        <div className="statc-flex-right__right-green statc-flex-right__right-green2">
                          <b>10</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="statistics-right__statc-flex-right">
                    <div className="statc-flex-right__left">
                      <h3>Отрицательных публикаций</h3>
                    </div>
                    <div className="statc-flex-right__right">
                      <div className="statc-flex-right__right-norm">
                        <div className="statc-flex-right__right-green statc-flex-right__right-green3">
                          <b>2</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="statistics-left-buttom">
            <p>Социальные сети</p>
            <div className="statistics-left-buttom__box">
              <div className="statistics-left-buttom__flex1">
                <div className="statistics-left-buttom__flexbox">
                  <p>Публикаций из Телеграмма</p>
                  <h1>20</h1>
                </div>
                <div className="statistics-left-buttom__flexbox">
                  <p>Публикаций из Одноклассники</p>
                  <h1>10</h1>
                </div>
                <div className="statistics-left-buttom__flexbox">
                  <p>Публикаций из Фейсбука</p>
                  <h1>9</h1>
                </div>
              </div>
              <div className="statistics-left-buttom__flex2">
                <div className="statistics-left-buttom__flexbox">
                  <p>Публикаций из Ютуба</p>
                  <h1>11</h1>
                </div>
                <div className="statistics-left-buttom__flexbox">
                  <p>Публикаций из Вконтакте</p>
                  <h1>50</h1>
                </div>
                <div className="statistics-left-buttom__flexbox">
                  <p>Публикаций из Инстаграмма</p>
                  <h1>8</h1>
                </div>
                <div className="statistics-left-buttom__flexbox">
                  <p>Публикаций из Твиттера</p>
                  <h1>0</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content__statistics-right">
          <div className="statistics-right1">
            <div className="statistics-right1__title">
              <p>
                Приблизительный охват: <span>953 815</span>
              </p>
            </div>
            <div className="statistics-right1__boxes">
              <div className="statistics-right1__box1">
                <div className="statistics-right1__num">
                  <p>300</p>
                  <p>250</p>
                  <p>200</p>
                  <p>150</p>
                  <p>100</p>
                  <p>50</p>
                </div>
                <div className="statistics-right1__linerbox statistics-right1__linerbox1">
                  <p>160</p>
                </div>
              </div>
              <div className="statistics-right1__box1">
                <div className="statistics-right1__num">
                  <p>300</p>
                  <p>250</p>
                  <p>200</p>
                  <p>150</p>
                  <p>100</p>
                  <p>50</p>
                </div>
                <div className="statistics-right1__linerbox statistics-right1__linerbox2">
                  <p>111</p>
                </div>
              </div>
            </div>
            <div className="statistics-right1__boxes">
              <div className="statistics-right1__box1">
                <div className="statistics-right1__num">
                  <p>600</p>
                  <p>500</p>
                  <p>400</p>
                  <p>300</p>
                  <p>200</p>
                  <p>100</p>
                </div>
                <div className="statistics-right1__linerbox statistics-right1__linerbox3">
                  <p>553</p>
                </div>
              </div>
              <div className="statistics-right1__box1">
                <div className="statistics-right1__num">
                  <p>30 000</p>
                  <p>25 000</p>
                  <p>20 000</p>
                  <p>15 000</p>
                  <p>10 000</p>
                  <p>5000</p>
                </div>
                <div className="statistics-right1__linerbox statistics-right1__linerbox4">
                  <p>25 180</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', columnGap: '20px' }}>
        <div>
          {/* <div className="sectionheader">
            <div className="sectionheader__nav">
              <form action="">
                <div className="sectionheader__location">
                  <div>
                    <p
                      style={{
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '16px',
                        color: '#383838',
                        marginBottom: '10px',
                      }}
                    >
                      Лента публикаций
                    </p>
                    <div className="sectionheader__adress">
                      <select name="События" id="">
                        <option value="">Субъекты</option>
                        <option value="">События</option>
                        <option value="">Сферы</option>
                      </select>
                      <select
                        style={{ width: '80%', border: 'none' }}
                        name="Благоустройство дорог Санкт-Петербурга"
                        id=""
                      >
                        <option value="">
                          Благоустройство дорог Санкт-Петербурга
                        </option>
                        <option value="">
                          Благоустройство дорог Санкт-Петербурга
                        </option>
                        <option value="">
                          Благоустройство дорог Санкт-Петербурга
                        </option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <p
                      style={{
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '16px',
                        color: '#383838',
                        marginBottom: '10px',
                      }}
                    >
                      Сфера
                    </p>
                    <div className="sectionheader__Sphere">
                      <select
                        style={{ border: 'none' }}
                        name="Не учитывать"
                        id=""
                      >
                        <option value="">Не учитывать</option>
                        <option value="">Не учитывать</option>
                        <option value="">Не учитывать</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <p
                      style={{
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '16px',
                        color: '#383838',
                        marginBottom: '10px',
                      }}
                    >
                      Период публикаций
                    </p>
                    <RangePicker />
                  </div>
                  <div
                    style={{
                      alignSelf: 'flex-end',
                      marginBottom: '5px',
                    }}
                  >
                    <button className="sectionheader__btn">Найти</button>
                  </div>
                </div>
              </form>
            </div>
          </div> */}
          <div className="sectionfooter">
            <div className="sectionfooter__body">
              <h1>
                Публикации <span>109</span>
              </h1>
              <div className="sectionfooter__box">
                <div className="sectionfooter__boxHeader">
                  <p>
                    <b>Все</b> <span>{newMockData.count}</span>
                  </p>
                  <p>Вконтакте <span>{counts.vk}</span></p>
                  <p>Twitter <span>{counts.tw}</span></p>
                  <p>Facebook <span>{counts.fb}</span></p>
                  <p>Telegram <span>{counts.tg}</span></p>
                  <p>Instagram <span>{counts.ig}</span></p>
                  <p>YouTube <span>{counts.yt}</span></p>
                  <p>СМИ <span>{counts.gs}</span></p>
                  <select
                    style={{
                      background: '#FFFFFF',
                      border: '1px solid rgba(0, 0, 0, 0.1)',
                      borderRadius: '5px',
                      width: '200px',
                      height: '36px',
                    }}
                    name="Сначала новые"
                    id=""
                  >
                    <option value="">Сначала новые</option>
                    <option value="">Сначала старые</option>
                  </select>
                </div>
                <div className="post-list">
                  {filteredPosts.map((post) => {

                    return(
                      <Post post={{id: post.id,
                        trust: post.trust.trust ? post.trust.trust : 0,
                        ownerId: post.owner_id,
                        text: post.text,
                        title: post.title,
                        createdDate: post.created_date,
                        isRemoved: false,
                        isManual: !!post.is_manual,
                        uri: post.uri,
                        smiType: post.smi_type,
                        attendance: post.attendance,
                        supercoefficient: post.supercoefficient,
                        author: {
                          name: post.author,
                          logo: post.author_logo,
                          url: post.author_url
                        },
                        network: {
                          id: post.network_id,
                          name: post.network_name
                        },
                        statistics: {
                          likes: post.likes,
                          reposts: post.reposts,
                          comments: post.comments,
                          viewed: post.viewed
                        },
                        media: {
                          images: post.images,
                          audio: post.audio,
                          video: post.video
                        }
                      }} key={post.id} />
                    )
                  }
                  )}
                </div>
              </div>
              <div className="sectionfooter__footerboxes">
                <img src={RightArrowImg} alt="" />
                <div className="footerboxes__box footerboxes__boxm">1</div>
                <div className="footerboxes__box">2</div>
                <div className="footerboxes__box">3</div>
                <div className="footerboxes__box">4</div>
                <div className="footerboxes__box">5</div>
                <img src={Group127Img} alt="" />
                <div className="footerboxes__box">539</div>
                <img src={LeftArrowImg} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="sectionRight">
            <form action="">
              <div className="sectionRight123">
                <div className="sectionRight__box">
                  <h2>Фильтры</h2>
                  <div className="sectionRight__boxx">
                    <label htmlFor="">Источники</label>
                    <div className="sectionRight__check-flex">
                      <div>
                        <div className="sectionRight__check">
                          <input type="checkbox" id="" onChange={() => selectNetworkHandler('vk')} checked={selectedNetworks.includes('vk')} />
                          <p>Вконтакте</p>
                        </div>
                        <div className="sectionRight__check">
                          <input type="checkbox" id="" onChange={() => selectNetworkHandler('tw')} checked={selectedNetworks.includes('tw')} />
                          <p>Twitter</p>
                        </div>
                        <div className="sectionRight__check">
                          <input type="checkbox" id="" onChange={() => selectNetworkHandler('fb')} checked={selectedNetworks.includes('fb')} />
                          <p>Facebook</p>
                        </div>
                        <div className="sectionRight__check">
                          <input type="checkbox" id="" onChange={() => selectNetworkHandler('ig')} checked={selectedNetworks.includes('ig')} />
                          <p>Instagram</p>
                        </div>
                        <div className="sectionRight__check">
                          <input type="checkbox" id="" onChange={() => selectNetworkHandler('tg')} checked={selectedNetworks.includes('tg')} />
                          <p>Telegram</p>
                        </div>
                        <div className="sectionRight__check">
                          <input type="checkbox" id="" onChange={() => selectNetworkHandler('yt')} checked={selectedNetworks.includes('yt')} />
                          <p>YouTube</p>
                        </div>
                        <div className="sectionRight__check">
                          <input type="checkbox" id="" onChange={() => selectNetworkHandler('gs')} checked={selectedNetworks.includes('gs')} />
                          <p>СМИ</p>
                        </div>
                      </div>
                      <div className="sectionRight__check">
                        <input type="checkbox" id="" onChange={() => setSelectedNetworks(networksList.map(it => it.name))} checked={selectedNetworks.length === 7} />
                        <p>Все</p>
                      </div>
                    </div>

                    <div
                      style={{ marginTop: '10px' }}
                      className="sectionRight__boxx"
                    >
                      <label htmlFor="">Тональность</label>
                      <div className="sectionRight__check-flex">
                        <div>
                          <div className="sectionRight__check">
                            <input type="checkbox" id="" />
                            <p>Положительная</p>
                          </div>
                          <div className="sectionRight__check">
                            <input type="checkbox" id="" />
                            <p>Нейтральная</p>
                          </div>
                          <div className="sectionRight__check">
                            <input type="checkbox" id="" />
                            <p>Негативная</p>
                          </div>
                        </div>
                        <div className="sectionRight__check">
                          <input type="checkbox" id="" />
                          <p>Все</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sectionRight__minbox">
                <h1>Сводная по публикациям</h1>
                <p>Тип сводки</p>
                <div>
                  <input type="radio" name="reports" id="publications" onChange={() => setSelectedReport('publications')} checked={selectedReport === 'publications'} />
                  <label htmlFor="publications">
                    Публикации - <span>word</span>
                  </label>
                </div>
                <div>
                  <input type="radio" name="reports" id="posts" onChange={() => setSelectedReport('posts')} checked={selectedReport === 'posts'} />
                  <label htmlFor="posts">
                    Посты - <span>exel</span>
                  </label>
                </div>
                <div>
                  <input type="radio" name="reports" id="comments" onChange={() => setSelectedReport('comments')} checked={selectedReport === 'comments'} />
                  <label htmlFor="comments">
                    Комментарии - <span>exel</span>
                  </label>
                </div>
                <a href={reportLink} className='btn' download={true}>Скачать</a>
              </div>
            </form>
            <div className="sectionRight__two">
              <div className="sectionRight__twobox">
                <img src={Vector3Img} alt="" />
              </div>
              <div className="sectionRight__twobox">
                <img src={Vector4Img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
