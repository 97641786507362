import { useSearchParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'

type IValue = { searchParamName: string; value: string }

interface IDefaultSearchParams {
  defaultSearchParams?: IValue[]
  searchParamsNotEmpty?: boolean
}

interface IProps<T, TKey extends keyof T> extends IDefaultSearchParams {
  whiteList?: (TKey | undefined)[]
}
interface IWithPagination<T, TKey extends keyof T> extends IProps<T, TKey> {
  withPagination: true
  shouldResetPaginationByParamsChanged: boolean
}
interface IWithOutPagination<T, TKey extends keyof T> extends IProps<T, TKey> {
  withPagination?: false
  shouldResetPaginationByParamsChanged?: never
}

type IUseSearchParamsHelper<T, TKey extends keyof T> =
  | IWithPagination<T, TKey>
  | IWithOutPagination<T, TKey>

const isPaginationResetReg = /(page=\d+&limit=\d+)|[&]/g

export const useSearchParamsHelper = <T, TKey extends keyof T>({
  defaultSearchParams,
  searchParamsNotEmpty,
  shouldResetPaginationByParamsChanged,
}: IUseSearchParamsHelper<T, TKey>) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [initState, setInitState] = useState(true)

  const searchParamsString = searchParams.toString()

  const [state, setState] = useState(searchParamsString)

  const set = useCallback(
    (value: IValue[]) => {
      value.forEach((value) => {
        const isParameterEntry = !!searchParams.get(value.searchParamName)
        if (!isParameterEntry) {
          searchParams.append(value.searchParamName, value.value)
        } else {
          searchParams.set(value.searchParamName, value.value)
        }
      })
    },
    [searchParams],
  )

  const setNewSearchParams = useCallback(
    (arrValue: IValue[], blackList?: string[]) => {
      if (blackList) {
        blackList.forEach((key) => {
          searchParams.delete(key)
        })
      }

      set(arrValue)
      setSearchParams(searchParams.toString())
    },
    [set, searchParams, setSearchParams],
  )

  const removeSearchParams = useCallback(
    (arrValue: string[]) => {
      if (arrValue) {
        arrValue.forEach((key) => {
          searchParams.delete(key)
        })
      }

      setSearchParams(searchParams.toString())
    },
    [set, searchParams, setSearchParams],
  )

  const getCurrentSearchParamValue = useCallback(
    (searchParam: string) => {
      return searchParams.get(searchParam)
    },
    [searchParams],
  )

  const resetPaginationByParamsChanged = useCallback(() => {
    searchParams.delete('limit')
    searchParams.delete('page')
    setSearchParams(searchParams.toString())
  }, [searchParams, setSearchParams])

  const searchParamsWithoutPagination = searchParamsString.replace(
    isPaginationResetReg,
    '',
  )

  useEffect(() => {
    if (!!shouldResetPaginationByParamsChanged) {
      resetPaginationByParamsChanged()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParamsWithoutPagination])

  useEffect(() => {
    if (defaultSearchParams && initState) {
      const paramsToSet = defaultSearchParams.filter(
        (value) => !searchParams.get(value.searchParamName),
      )
      setNewSearchParams(paramsToSet)
      setInitState(false)
    } else if (
      searchParamsNotEmpty &&
      defaultSearchParams &&
      searchParamsString === ''
    ) {
      const paramsToSet = defaultSearchParams.filter(
        (value) => !searchParams.get(value.searchParamName),
      )
      setNewSearchParams(paramsToSet)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSearchParams, initState, setNewSearchParams])

  useEffect(() => {
    setState(searchParams.toString())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParamsString])

  return {
    setNewSearchParams,
    getCurrentSearchParamValue,
    searchParamsString: state,
    removeSearchParams,
    initState,
  }
}
