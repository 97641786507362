import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  AppNotification,
  NotificationType,
} from '../../../common/components/Notification/Notification'
import { useTranslation } from 'react-i18next'
import { useCreateNewInfoBlockMutation } from '../../../core/api/BaseApiEndpoints/InfoBlocks/InfoBlocksApi'
import { Breadcrumbs } from '../../../common/components/Breadcrumbs/Breadcrumbs'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import { AppInput } from '../../../common/components/AppInput/AppInput'
import { DataTypeSelect } from '../../../common/components/AppDropdown/DataTypeSelect'
import {
  IInfoBlockCreatePropType,
  IPropOptions,
  IPropType,
} from '../../../core/api/dto/InfoBlockDto'
import { FormOptions } from '../../../common/components/FormOptions/FormOptions'

const projectsBreadcrumbs = [
  { name: 'info_blocks_page__txt_title', path: '../' },
  { name: 'info_blocks_create_page__txt_title' },
]

interface ICreateElementState extends IPropOptions {
  name: string
  type: IPropType | null
  error_name: boolean
  error_type: boolean
}

const initRowState = {
  name: '',
  type: null,
  error_name: false,
  error_type: false,
  options: {
    multi: false,
    required: false,
    showInListingPage: false,
    useAsFilterProp: false,
  },
}

export const InfoBlockCreatePage = () => {
  const navigate = useNavigate()

  const [state, setState] = useState<ICreateElementState[]>([
    {
      ...initRowState,
    },
  ])

  const [createNewInfoBlock] = useCreateNewInfoBlockMutation()

  const [infoBlockName, setInfoBlockName] = useState({
    value: '',
    error: false,
  })
  const [infoBlockOptions, setInfoBlockOptions] = useState({
    showTitleInListing: false,
    isPrivate: false,
  })

  const onChange = (value: any, propName: string, index: number) => {
    setState((prev) => {
      const newState = [...prev]
      newState[index] = {
        ...newState[index],
        [propName]: value,
        [`error_${propName}`]: '',
      }
      return newState
    })
  }

  const setNewRow = () => {
    setState((prev) => {
      const newState = [...prev]
      newState.push({
        ...initRowState,
      })
      return newState
    })
  }

  const delRow = (index: number) => {
    setState((prev) => {
      const newState = [...prev]
      newState.splice(index, 1)
      return newState
    })
  }

  const submitHandler = () => {
    let hasErrors = false
    const newState = [...state]
    newState.forEach((lineState) => {
      console.log('lineState => ', lineState)
      if (!lineState[`name`]) {
        hasErrors = true
        lineState.error_name = true
      }
      if (!lineState[`type`]) {
        hasErrors = true
        lineState.error_type = true
      }
    })
    if (hasErrors) {
      setState(newState)
    }
    if (!infoBlockName.value) {
      hasErrors = true
      setInfoBlockName((prev) => {
        return { ...prev, error: true }
      })
    }
    if (!hasErrors) {
      const data: IInfoBlockCreatePropType[] = []
      state.forEach((lineState, index) => {
        data.push({
          description: lineState.name,
          typeId: lineState.type!.id,
          sort: 100,
          options: { ...lineState.options },
        })
      })

      createNewInfoBlock({
        name: infoBlockName.value,
        ...infoBlockOptions,
        data,
      })
        .unwrap()
        .then((item) => {
          AppNotification({
            msg: [t('general__msg_saved')],
            type: NotificationType.success,
          })
          console.log('item => ', item)
          navigate(`../${item.name}`)
        })
        .catch((error) => console.log(error))
    }
  }

  const { t } = useTranslation()

  return (
    <React.Fragment>
      <div className={'back-office-header'}>
        <Breadcrumbs breadcrumbs={projectsBreadcrumbs} />
        <h1>
          <AppTranslation label={AllRoutes.infoBlockCreate.name} />
        </h1>
      </div>
      <div className="back-office-form">
        <div className="row">
          <div className="col-8">
            <AppInput
              onChange={(value) =>
                setInfoBlockName(() => {
                  return {
                    value,
                    error: false,
                  }
                })
              }
              label={'info_blocks_create_page__txt_form_title'}
              value={infoBlockName.value}
              fullWidth={true}
              error={infoBlockName.error ? 'general__txt_required' : false}
            />
          </div>
          <div className="col-4">
            <FormOptions
              values={infoBlockOptions}
              labelPrefix={'info_block_settings__'}
              onChange={({ propName, newValue }) =>
                setInfoBlockOptions((prev) => {
                  return { ...prev, [propName]: newValue }
                })
              }
            />
          </div>
          {state.map((lineState, index) => {
            return (
              <React.Fragment key={`${index}`}>
                <div className="col-3">
                  <AppInput
                    onChange={(value) => onChange(value, `name`, index)}
                    label={'info_blocks_create_page__txt_form_field_name'}
                    value={lineState[`name`]}
                    fullWidth={true}
                    error={
                      lineState[`error_name`] ? 'general__txt_required' : false
                    }
                  />
                </div>
                <div className={`col-2`}>
                  <DataTypeSelect
                    defaultValue={lineState[`type`] ? lineState[`type`] : null}
                    onChange={(value) => onChange(value, `type`, index)}
                    error={
                      lineState[`error_type`] ? 'general__txt_required' : false
                    }
                    valueRows={7}
                  />
                </div>
                <div className="col-6">
                  <FormOptions
                    values={lineState.options}
                    labelPrefix={'info_block_settings__'}
                    onChange={({ propName, newValue }) =>
                      onChange(
                        { ...lineState.options, [propName]: newValue },
                        `options`,
                        index,
                      )
                    }
                  />
                </div>
                <div className={'col'}>
                  <div className={'line-form-action'}>
                    {index !== 0 && (
                      <AppButton
                        onClick={() => delRow(index)}
                        color={AppButtonColor.red}
                        size={AppButtonSize.square}
                        icoClassName={'an-ico-cross'}
                      />
                    )}
                    <AppButton
                      onClick={setNewRow}
                      color={AppButtonColor.blue}
                      size={AppButtonSize.square}
                      icoClassName={'an-ico-plus'}
                    />
                  </div>
                </div>
              </React.Fragment>
            )
          })}
          <div className={'col-12'}>
            <AppButton
              onClick={submitHandler}
              color={AppButtonColor.blue}
              size={AppButtonSize.small}
              title={'general__button_add'}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
