import React, { useMemo } from 'react'
import { AppTranslation } from '../../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../../core/routes/AllRoutes'
import { HeaderSearchInput } from '../../../../common/components/HeaderSearchInput/HeaderSearchInput'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../../common/components/AppButton/AppButton'
import { AppTable } from '../../../../common/components/AppTable/AppTable'
import {
  AppTableCell,
  AppTableCellJustify,
} from '../../../../common/components/AppTable/AppTableCell'
import { IWithPaginationResponse } from '../../../../core/api/dto/BaseDto'
import { ResponsiblePersonView } from '../../../../common/components/AppTable/ResponsiblePersonView'

const cellPadding = 30

const tableConfig = {
  tableHeaders: [
    'protocols_page__txt_table_header_protocol_name',
    'protocols_page__txt_table_header_responsible',
    'protocols_page__txt_table_header_period',
  ],
  width: ['auto', 400 + cellPadding, 250 + cellPadding],
}

interface IProtocolsDTO {
  name: string
  responsiblePerson: {
    name: string
  }
  period: {
    dateFrom: string
    dateTo: string
  }
}

interface IProtocolsResponseDTO extends IWithPaginationResponse {
  items: IProtocolsDTO[]
}

const data: IProtocolsResponseDTO = {
  count: 1000,
  limit: 10,
  page: 1,
  total: 10,
  items: [
    {
      name: 'Протокол БГМП №123 от 31.03.022',
      responsiblePerson: { name: 'Пикалев В.И.' },
      period: { dateFrom: '01.05.22', dateTo: '06.05.22' },
    },
    {
      name: 'Протокол БГМП №123 от 31.03.022',
      responsiblePerson: { name: 'Пикалев В.И.' },
      period: { dateFrom: '01.05.22', dateTo: '06.05.22' },
    },
    {
      name: 'Протокол БГМП №123 от 31.03.022',
      responsiblePerson: { name: 'Пикалев В.И.' },
      period: { dateFrom: '01.05.22', dateTo: '06.05.22' },
    },
    {
      name: 'Протокол БГМП №123 от 31.03.022',
      responsiblePerson: { name: 'Пикалев В.И.' },
      period: { dateFrom: '01.05.22', dateTo: '06.05.22' },
    },
    {
      name: 'Протокол БГМП №123 от 31.03.022',
      responsiblePerson: { name: 'Пикалев В.И.' },
      period: { dateFrom: '01.05.22', dateTo: '06.05.22' },
    },
    {
      name: 'Протокол БГМП №123 от 31.03.022',
      responsiblePerson: { name: 'Пикалев В.И.' },
      period: { dateFrom: '01.05.22', dateTo: '06.05.22' },
    },
    {
      name: 'Протокол БГМП №123 от 31.03.022',
      responsiblePerson: { name: 'Пикалев В.И.' },
      period: { dateFrom: '01.05.22', dateTo: '06.05.22' },
    },
    {
      name: 'Протокол БГМП №123 от 31.03.022',
      responsiblePerson: { name: 'Пикалев В.И.' },
      period: { dateFrom: '01.05.22', dateTo: '06.05.22' },
    },
    {
      name: 'Протокол БГМП №123 от 31.03.022',
      responsiblePerson: { name: 'Пикалев В.И.' },
      period: { dateFrom: '01.05.22', dateTo: '06.05.22' },
    },
  ],
}

export const ProtocolsListingPage = () => {
  const tableSelectors = useMemo(() => {
    return [
      {
        renderItem: (item: IProtocolsDTO) => (
          <AppTableCell>{item.name}</AppTableCell>
        ),
      },
      {
        renderItem: (item: IProtocolsDTO) => (
          <AppTableCell>
            <ResponsiblePersonView name={item.responsiblePerson.name} />
          </AppTableCell>
        ),
      },
      {
        renderItem: (item: IProtocolsDTO) => (
          <AppTableCell>{`${item.period.dateFrom} - ${item.period.dateTo}`}</AppTableCell>
        ),
      },
    ]
  }, [])
  return (
    <>
      <div className={'back-office-header'}>
        <h1>
          <AppTranslation label={AllRoutes.protocols.name} />
        </h1>
        <div className={'header-actions-block'}>
          <div className="actions-left-block">
            <HeaderSearchInput
              searchParam={'filters[search]'}
              placeholder={'general__txt_search'}
            />
          </div>
          <div className="actions-right-block">
            <AppButton
              path={AllRoutes.protocolsCreate.path}
              icoClassName={'an-ico-plus-thin'}
              title={'protocols_create_page__txt_nav_button_title'}
              color={AppButtonColor.lightBlue}
              size={AppButtonSize.small}
            />
          </div>
        </div>
      </div>
      <AppTable
        headerTitles={tableConfig.tableHeaders}
        cellWidth={tableConfig.width}
        tableDataSelectors={tableSelectors}
        data={data ? data : null}
        lastColAlignLeft={true}
      />
    </>
  )
}
