import {
  PersistConfig,
  persistReducer,
  persistStore,
  createMigrate,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { rootReducer, RootState } from './rootReducer'
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { api } from '../api/BaseApi'
import { rtkQueryErrorLogger } from './middleware/rtkQueryErrorLogger'
import { setupListeners } from '@reduxjs/toolkit/query'

const migrations = {
  0: (state: any) => {
    return {
      ...state,
    }
  },
}

const persistConfig: PersistConfig<RootState> = {
  key: 'mediaPlanAuth',
  storage,
  version: 0,
  whitelist: ['mediaPlanAuth'],
  migrate: createMigrate(migrations, { debug: false }),
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      //}).concat([api.middleware, rtkQueryErrorLogger]),
    }).concat([api.middleware]),
  devTools: process.env.REACT_APP_ENV !== 'production',
})

setupListeners(store.dispatch)

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
