import { authBgTypes, AuthPageLayout } from '../../pages/Auth/AuthPageLayout'
import { RouteObject } from 'react-router'
import { LoginPage } from '../../pages/Auth/LoginPage'
import { ResetPasswordLayout } from '../../pages/Auth/ResetPassword/ResetPasswordLayout'
import { ResetPasswordPage } from '../../pages/Auth/ResetPassword/ResetPasswordPage'
import { ResetPasswordCheckEmail } from '../../pages/Auth/ResetPassword/ResetPasswordCheckEmail'
import { ResetPasswordConfirm } from '../../pages/Auth/ResetPassword/ResetPasswordConfirm'
import { UiPage } from '../../pages/UiPage/UiPage'
import { Navigate, useLocation } from 'react-router-dom'
import { LogoutPage } from '../../pages/Auth/LogoutPage'
import { BackOfficePageLayout } from '../../pages/BackOfficePageLayout'
import React from 'react'
import { AdminPageMainLayout } from '../../pages/AdminPage/AdminPageMainLayout'
import { workDirPaths } from '../api/dto/RolesDto'
import { AllRoutes } from './AllRoutes'
import { AcceptInvitationLayout } from '../../pages/Auth/AcceptInvitation/AcceptInvitationLayout'
import { UiPageLayout } from '../../pages/UiPage/UiPageLayout'
import { UiDropdownsPage } from '../../pages/UiPage/UiDropdownsPage'
import { UiImages } from '../../pages/UiPage/UiImages'
import { UiInputs } from '../../pages/UiPage/UiInputs'
import { UiTime } from '../../pages/UiPage/UiTime'
import { TestAuth } from '../../pages/TestAuth'
import { ProtocolsListingPage } from '../../pages/AdminPage/MediaPlaning/Protocols/ProtocolsListingPage'
import { MediaPlanning } from '../../pages/AdminPage/MediaPlaning/MediaPlanning'
import { ProtocolsCreatePage } from '../../pages/AdminPage/MediaPlaning/Protocols/ProtocolsCreatePage'
import { EmployeesListingPage } from '../../pages/AdminPage/Employees/EmployeesListingPage'
import { EmployeeEditPage } from '../../pages/AdminPage/Employees/EmployeeEditPage'
import { InfoBlocksListingPage } from '../../pages/AdminPage/InfoBlocks/InfoBlocksListingPage'
import { InfoBlockEditPage } from '../../pages/AdminPage/InfoBlocks/InfoBlockEditPage'
import { InfoBlockCreatePage } from '../../pages/AdminPage/InfoBlocks/InfoBlockCreatePage'
import { ElementCRUDPage } from '../../pages/AdminPage/Projects/ElementCRUDPage'
import { CompaniesListingPage } from '../../pages/AdminPage/Companies/CompaniesListingPage'
import { CompanyCreatePage } from '../../pages/AdminPage/Companies/CompanyCreatePage'
import { CompanyEditPage } from '../../pages/AdminPage/Companies/CompanyEditPage'
import { ElementsListingPage } from '../../pages/AdminPage/Projects/ElementsListingPage'
import { Tasks } from '../../pages/AdminPage/Tasks/Tasks'
import { TaskDetail } from '../../pages/AdminPage/Tasks/TaskDetail'
import { TaskCreateUpdate } from '../../pages/AdminPage/Tasks/TaskCreateUpdate'
import { EntityElements } from '../../pages/AdminPage/Entity/EntityElements'
import { EntityElementCreateUpdate } from '../../pages/AdminPage/Entity/EntityElementCreateUpdate'
import { EntityDetails } from '../../pages/AdminPage/Entity/EntityDetails'
import { StructureListingPage } from 'pages/AdminPage/Structure/StuctureListingPage'
import { ReportsListingPage } from 'pages/AdminPage/Reports/ReportsListingPage'
import EventCalendarPage from 'pages/AdminPage/EventCalendar/EventCalendarPage'
import { MonitoringFrame } from 'pages/AdminPage/Monitoring/MonitoringFrame'
import TasksCalendarPage from 'pages/AdminPage/Tasks/TasksCalendarPage'
import { Sources } from '../../pages/AdminPage/Sources/Sources'
import { SourcesGroups } from '../../pages/AdminPage/Sources/SourcesGroups'
import { UserPage } from 'pages/AdminPage/User/UserPage'
import { ProjectsList } from 'pages/AdminPage/NewProjects/ProjectsList'
import { ProjectDetail } from 'pages/AdminPage/NewProjects/ProjectDetail'
import { ProjectCreateUpdate } from 'pages/AdminPage/NewProjects/ProjectCreateUpdate'

interface IRedirect {
  path?: string
}

export const AppRedirect = ({ path = '/' }: IRedirect) => {
  const location = useLocation()
  return <Navigate to={path} state={{ from: location }} replace />
}

export const loginRoutes: RouteObject = {
  path: '/',
  children: [
    {
      index: true,
      element: <AppRedirect path={AllRoutes.login.path} />,
    },
    {
      path: AllRoutes.login.path,
      element: <AuthPageLayout bgType={authBgTypes.auth} />,
      children: [{ index: true, element: <LoginPage /> }],
    },
    {
      path: AllRoutes.acceptInvitation.path,
      element: <AuthPageLayout bgType={authBgTypes.reset} />,
      children: [
        {
          element: <AcceptInvitationLayout />,
          children: [{ index: true, element: <ResetPasswordConfirm /> }],
        },
      ],
    },
    {
      path: AllRoutes.resetPassword.path,
      element: <AuthPageLayout bgType={authBgTypes.reset} />,
      children: [
        {
          element: <ResetPasswordLayout />,
          children: [
            {
              index: true,
              element: <ResetPasswordPage />,
            },
            {
              path: AllRoutes.emailCheck.path,
              element: <ResetPasswordCheckEmail />,
            },
            {
              path: AllRoutes.confirmNewPassword.path,
              element: <ResetPasswordConfirm />,
            },
          ],
        },
      ],
    },
    { path: '*', element: <AppRedirect path={AllRoutes.login.path} /> },
  ],
}

const uiRoutes = [
  {
    path: AllRoutes.ui.path,
    element: <UiPageLayout />,
    children: [
      { path: AllRoutes.jwtTest.path, element: <TestAuth /> },
      { path: AllRoutes.uiAll.path, element: <UiPage /> },
      { path: AllRoutes.uiButtons.path, element: <UiPage /> },
      { path: AllRoutes.uiDropdowns.path, element: <UiDropdownsPage /> },
      { path: AllRoutes.uiTime.path, element: <UiTime /> },
      { path: AllRoutes.uiInputs.path, element: <UiInputs /> },
      { path: AllRoutes.uiNavigation.path, element: <UiPage /> },
      { path: AllRoutes.uiDriverWidgets.path, element: <UiPage /> },
      { path: AllRoutes.uiPopupForms.path, element: <UiPage /> },
      { path: AllRoutes.uiImages.path, element: <UiImages /> },
    ],
  },
]

// @ts-ignore
const adminRoutes = {
  path: workDirPaths.ROLE_ADMIN,
  element: <BackOfficePageLayout />,
  children: [
    {
      element: <AdminPageMainLayout />,
      children: [
        {
          element: <MediaPlanning />,
          path: AllRoutes.mediaPlaning.path,
          children: [
            {
              path: AllRoutes.protocols.path,
              children: [
                { index: true, element: <ProtocolsListingPage /> },
                {
                  path: AllRoutes.protocolsCreate.path,
                  element: <ProtocolsCreatePage />,
                },
              ],
            },
            {
              path: AllRoutes.companies.path,
              children: [
                {
                  element: <CompaniesListingPage />,
                  index: true,
                },
                {
                  element: <CompanyEditPage />,
                  path: AllRoutes.companyEdit.path,
                },
                {
                  element: <CompanyCreatePage />,
                  path: AllRoutes.companyCreate.path,
                },
              ],
            },
          ],
        },
        {
          path: AllRoutes.employees.path,
          children: [
            { element: <EmployeesListingPage />, index: true },
            {
              element: <EmployeeEditPage />,
              path: AllRoutes.employeeEdit.path,
            },
          ],
        },
        {
          path: AllRoutes.monitoring.path,
          children: [
            {
              element: <MonitoringFrame />,
              path: AllRoutes.monitoringView.path,
            },
          ],
        },

        {
          path: AllRoutes.eventCalendar.path,
          children: [{ element: <EventCalendarPage />, index: true }],
        },
        {
          path: AllRoutes.infoBlocks.path,
          children: [
            { element: <InfoBlocksListingPage />, index: true },
            {
              path: AllRoutes.infoBlockEdit.path,
              children: [{ element: <InfoBlockEditPage />, index: true }],
            },
            {
              element: <InfoBlockCreatePage />,
              path: AllRoutes.elementCreate.path,
            },
          ],
        },
        {
          path: AllRoutes.entity.path,
          children: [
            { element: <AppRedirect path={'../'} />, index: true },
            {
              path: AllRoutes.entityElements.path,
              children: [
                { element: <EntityElements />, index: true },
                {
                  element: <EntityElementCreateUpdate />,
                  path: AllRoutes.entityElementCreate.path,
                },
                {
                  path: AllRoutes.entityElement.path,
                  children: [
                    { element: <EntityDetails />, index: true },
                    {
                      path: AllRoutes.entityRootCreateRelated.path,
                      children: [
                        { index: true, element: <AppRedirect path={'../'} /> },
                        {
                          path: AllRoutes.entityElementCreateRelated.path,
                          element: <EntityElementCreateUpdate />,
                        },
                      ],
                    },
                    {
                      path: AllRoutes.entityRootCreateTask.path,
                      children: [
                        { index: true, element: <AppRedirect path={'../'} /> },
                        {
                          path: AllRoutes.entityElementCreateRelated.path,
                          element: <TaskCreateUpdate />,
                        },
                      ],
                    },
                    {
                      element: <EntityElementCreateUpdate />,
                      path: AllRoutes.entityElementEdit.path,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: AllRoutes.tasksListing.path,
          children: [
            { index: true, element: <Tasks /> },
            {
              path: AllRoutes.tasksCreate.path,
              element: <TaskCreateUpdate />,
            },
            {
              path: AllRoutes.taskDetail.path,
              children: [
                { element: <TaskDetail />, index: true },
                {
                  path: AllRoutes.tasksEdit.path,
                  element: <TaskCreateUpdate />,
                },
              ],
            },
            {
              path: AllRoutes.eventCalendar.path,
              children: [{ element: <TasksCalendarPage />, index: true }],
            },
          ],
        },
        {
          path: AllRoutes.user.path,
          children: [{ element: <UserPage />, index: true }],
        },
        {
          path: AllRoutes.structure.path,
          children: [{ element: <StructureListingPage />, index: true }],
        },
        {
          path: AllRoutes.sources.path,
          children: [
            {
              element: <AppRedirect path={AllRoutes.sourcesList.path} />,
              index: true,
            },
            { element: <Sources />, path: AllRoutes.sourcesList.path },
            { element: <SourcesGroups />, path: AllRoutes.sourcesGroups.path },
          ],
        },
        {
          path: AllRoutes.projects.path,
          children: [
            { element: <ProjectsList />, index: true },
            {
              path: AllRoutes.projectsDetail.path,
              children: [
                { element: <ProjectDetail />, index: true },
                {
                  path: AllRoutes.projectEdit.path,
                  element: <ProjectCreateUpdate />,
                },
                {
                  path: AllRoutes.projectRootCreateRelated.path,
                  children: [
                    { index: true, element: <AppRedirect path={'../'} /> },
                    {
                      path: AllRoutes.projectCreateRelated.path,
                      element: <EntityElementCreateUpdate />,
                    },
                  ],
                },
                {
                  path: AllRoutes.projectRootCreateTask.path,
                  children: [
                    { index: true, element: <AppRedirect path={'../'} /> },
                    {
                      path: AllRoutes.projectCreateRelated.path,
                      element: <TaskCreateUpdate />,
                    },
                  ],
                },
              ],
            },
            {
              path: AllRoutes.projectCreate.path,
              element: <ProjectCreateUpdate />,
            },
          ],
        },
        {
          path: AllRoutes.reports.path,
          children: [{ element: <ReportsListingPage />, index: true }],
        },
      ],
    },
    {
      path: `${AllRoutes.logout.path}`,
      element: <LogoutPage />,
    },
    {
      path: '*',
      element: <AppRedirect path={`${workDirPaths.ROLE_ADMIN}`} />,
    },
    ...uiRoutes,
  ],
}

export const appRoutes = {
  authorized: [adminRoutes],
  notAuthorized: [
    {
      ...loginRoutes,
      path: workDirPaths.ROLE_ADMIN,
    },
  ],
}
