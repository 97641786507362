import { AppPopup } from '../../../common/components/AppPopup/AppPopup'


interface IAddSourcePopup {
  onClose: () => void
  structureItem: number
}

export const StructurePopup = ({ onClose, structureItem }: IAddSourcePopup) => {
  return (
    <AppPopup onClose={onClose} popupWidth={600}>
      <div className='row'>
        <p>{structureItem}</p>
      </div>
    </AppPopup>
  )
}
