import React from 'react'

const UserPlaceholder: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 70 70"
      className="user-placeholder"
    >
      <circle cx="35" cy="35" r="35" />
      <path d="M53.74939,48.28949c-0.75598-0.74872-1.77271-1.65887-2.26318-2.14459 c-2.63391-2.22192-5.73602-3.92212-9.1308-4.92682c4.1167-2.47296,6.88708-6.97467,6.88708-12.09467 C49.24249,21.31781,42.86273,15,34.98053,15s-14.26215,6.33722-14.26215,14.12341c0,5.12,2.75104,9.6217,6.88721,12.09467 c-3.35577,1.0047-6.41888,2.68561-9.03326,4.86884c-0.29785,0.29486-0.70184,0.50177-2.34125,2.12531 c-2.80945,3.01398-4.89709,6.72357-5.97015,10.81958l-0.10785,0.61395C16.49463,66.03876,25.28387,70,35,70 c9.68921,0,18.4577-3.93823,24.79517-10.29999l-0.13409-0.66821C58.58801,54.95508,56.53943,51.30353,53.74939,48.28949z" />
    </svg>
  )
}

export default UserPlaceholder
