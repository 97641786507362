import React, { useMemo, useState } from 'react'
import { ISourceGroupDTO, SourceDTO } from '../../../core/api/dto/SourcesDto'
import { AppPopup } from '../../../common/components/AppPopup/AppPopup'
import { AppInput } from '../../../common/components/AppInput/AppInput'
import { AppCheckbox } from '../../../common/components/AppCheckbox/AppCheckbox'
import {
  AppButton,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import { useCreateSourcesGroupMutation } from '../../../core/api/BaseApiEndpoints/Sources/SourcesApi'
import {
  AppNotification,
  NotificationType,
} from '../../../common/components/Notification/Notification'
import { t } from 'i18next'

export interface ICheckedItems {
  [key: number]: SourceDTO | ISourceGroupDTO | null
}

interface IMakeGroupFromCheckedSources {
  data: ICheckedItems
  onClose: () => void
}

export const MakeGroupFromCheckedSourcesPopup = ({
  data,
  onClose,
}: IMakeGroupFromCheckedSources) => {
  const [createSourcesGroup] = useCreateSourcesGroupMutation()
  const [state, setState] = useState({ name: '', is_public: false })
  const [errorState, setErrorState] = useState({ name: false })
  const sourcesObj = useMemo(() => {
    let result = ''
    const arr: number[] = []
    Object.keys(data).forEach((key) => {
      arr.push(Number(key))
      // @ts-ignore
      result += `${data[key].recipients.reduce((prev, cur) => {
        return `${prev} ${cur} `
      })} `
    })
    return { str: result, arr }
  }, [data])
  const changeHandler = (value: string, key: keyof typeof state) => {
    if (key === 'name' && errorState[key]) {
      setErrorState((prev) => {
        return { ...prev, [key]: false }
      })
    }

    setState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      }
    })
  }

  const onSaveHandler = () => {
    if (state.name === '') {
      setErrorState({ name: !state.name })
    } else {
      createSourcesGroup({
        ...state,
        sources: sourcesObj.arr,
      })
        .unwrap()
        .then(() => {
          AppNotification({
            msg: [t('general__msg_saved')],
            type: NotificationType.success,
          })
          onClose()
        })
    }
  }
  return (
    <AppPopup onClose={onClose} popupWidth={600}>
      <div className="row">
        <div className="col-12">
          <AppInput
            onChange={(value) => changeHandler(value, 'name')}
            value={state.name}
            label={'Название группы'}
            placeholder={'Введите название группы источников'}
            fullWidth={true}
            error={errorState.name}
          />
        </div>
        <div className="col-8">
          <AppInput
            value={sourcesObj.str}
            label={'E-mail источников'}
            disabled={true}
            fullWidth={true}
            onChange={() => null}
            isTextArea={true}
            rows={6}
          />
        </div>
        <div className="col-4">
          <AppCheckbox
            value={state.is_public}
            uniqId={'is_public'}
            label={'Общедоступная'}
            onChange={(value) =>
              setState((prevState) => {
                return { ...prevState, is_public: value }
              })
            }
          />
        </div>
        <div className="col-12">
          <AppButton
            size={AppButtonSize.small}
            onClick={onSaveHandler}
            title={'Сгрупировать'}
          />
        </div>
      </div>
    </AppPopup>
  )
}
