import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import TRANSLATIONS_RU from './translations/ru.json'
import { LOCALES } from './locales'

import { ru } from 'date-fns/locale'
import { format } from 'date-fns'

const Languages = [LOCALES.RUSSIAN]
const locales = { ru }

type localeType = keyof typeof locales

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ru',
    debug: false,
    supportedLngs: Languages,
    resources: {
      [LOCALES.RUSSIAN]: {
        translation: TRANSLATIONS_RU,
      },
    },
  })

i18n.services.formatter?.add(
  'ALL__DATE_LONG_STRING__DD_MMM_YYYY',
  (value, lng) => {
    return format(value, 'dd MMMM yyyy HH:mm', {
      locale: lng ? locales[lng as localeType] : undefined,
    })
  },
)
