import React from 'react'
import {
  AppTranslation,
  IAppTranslation,
  translationLabelTape,
} from '../AppTranslation/AppTranslation'

interface ISectionWidget {
  title: translationLabelTape | IAppTranslation
  icoClassName?: string
  children: JSX.Element
}

export const SectionWidget = ({
  title,
  children,
  icoClassName,
}: ISectionWidget) => {
  return (
    <div className={'section-widget'}>
      {title && (
        <h2>
          {typeof title === 'object' ? (
            <AppTranslation label={title.label} options={title.options} />
          ) : (
            <AppTranslation label={title} />
          )}
        </h2>
      )}
      <div className="widget-content">
        {icoClassName && <i className={`an-ico ${icoClassName}`}></i>}
        <div className="content-children">{children}</div>
      </div>
    </div>
  )
}
