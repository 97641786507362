import React, { useCallback, useMemo } from 'react'
import {
  AppTranslation,
  translationLabelTape,
} from '../AppTranslation/AppTranslation'
import { useGetUsersProfileQuery } from '../../../core/api/BaseApi'
import { ExecutorsGroup } from './ExecutorsGroup'
import { IBaseProfileDto } from '../../../core/api/dto/ProfilesDto'

export interface IExecutorsState {
  executor: IBaseProfileDto[] | number[]
  co_executor: IBaseProfileDto[] | number[]
}

export interface IFormExecutors {
  defaultValue: IExecutorsState | null
  onChange: (
    value: {
      executor: IBaseProfileDto[]
      co_executor: IBaseProfileDto[]
    } | null,
  ) => void
  disabled?: boolean
  label?: translationLabelTape
  error?: string | boolean
}

export type onChangeExecutorsHandlerType = ({
  executors,
  propName,
}: {
  executors: IBaseProfileDto[]
  propName: 'executor' | 'co_executor'
}) => void

const getExecutors = (
  executors: IBaseProfileDto[] | number[],
  profiles: IBaseProfileDto[],
) => {
  if (executors) {
    const result: IBaseProfileDto[] = []
    executors.forEach((item: IBaseProfileDto | number) => {
      const profileData: IBaseProfileDto | undefined = profiles.find(
        (profile) => {
          return profile.id === (typeof item === 'number' ? item : item.id)
        },
      )
      if (profileData) {
        result.push(profileData)
      }
    })
    return result
  } else return []
}

export const FormExecutors = ({
  defaultValue,
  onChange,
  disabled,
  label,
  error,
}: IFormExecutors) => {
  const { data: profiles } = useGetUsersProfileQuery()

  const currentValue = useMemo(() => {
    if (defaultValue && profiles) {
      const result: {
        executor: IBaseProfileDto[]
        co_executor: IBaseProfileDto[]
      } = {
        executor: getExecutors(defaultValue.executor, profiles),
        co_executor: getExecutors(defaultValue.co_executor, profiles),
      }

      return result
    }
    return {
      executor: [],
      co_executor: [],
    }
  }, [defaultValue, profiles])

  const onChangeHandler = useCallback<onChangeExecutorsHandlerType>(
    ({ executors, propName }) => {
      const result = { ...currentValue, [propName]: executors }
      onChange(result)
    },
    [onChange, currentValue],
  )

  return (
    <div className="back-office-input-wrapper ">
      <div className="app-input-wrapper full-width-input">
        <div className="app-input-label">
          {/*<b>
            <AppTranslation
              label={label ? label : 'task_create_page__form_executors'}
            />
  </b>*/}
        </div>
        <div
          className={`app-input executors-block ${error ? 'error' : ''} ${
            !(
              (currentValue?.executor && currentValue?.executor.length > 0) ||
              (currentValue?.co_executor &&
                currentValue?.co_executor.length > 0)
            )
              ? 'empty'
              : ''
          }`}
        >
          <ExecutorsGroup
            executors={currentValue?.executor}
            onChange={onChangeHandler}
            executorsType={'executor'}
            disabled={disabled}
            label={'task_create_page__form_executor'}
          />
          <ExecutorsGroup
            executors={currentValue?.co_executor}
            onChange={onChangeHandler}
            executorsType={'co_executor'}
            disabled={disabled}
            label={'task_create_page__form_co_executor'}
          />
        </div>
      </div>
    </div>
  )
}
