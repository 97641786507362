import React from 'react'

export const DashboardUsers = () => {
  return (
    <div className="dashboard__users dashboard-users dashboard-card">
      <div className="dashboard-users__head">
        <svg
          width="30"
          height="30"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.3477 14.3389C21.0077 14.3389 22.3377 12.9989 22.3377 11.3389C22.3377 9.67887 21.0077 8.33887 19.3477 8.33887C17.6877 8.33887 16.3477 9.67887 16.3477 11.3389C16.3477 12.9989 17.6877 14.3389 19.3477 14.3389ZM11.3477 14.3389C13.0077 14.3389 14.3377 12.9989 14.3377 11.3389C14.3377 9.67887 13.0077 8.33887 11.3477 8.33887C9.68766 8.33887 8.34766 9.67887 8.34766 11.3389C8.34766 12.9989 9.68766 14.3389 11.3477 14.3389ZM11.3477 16.3389C9.01766 16.3389 4.34766 17.5089 4.34766 19.8389V21.3389C4.34766 21.8889 4.79766 22.3389 5.34766 22.3389H17.3477C17.8977 22.3389 18.3477 21.8889 18.3477 21.3389V19.8389C18.3477 17.5089 13.6777 16.3389 11.3477 16.3389ZM19.3477 16.3389C19.0577 16.3389 18.7277 16.3589 18.3777 16.3889C18.3977 16.3989 18.4077 16.4189 18.4177 16.4289C19.5577 17.2589 20.3477 18.3689 20.3477 19.8389V21.3389C20.3477 21.6889 20.2777 22.0289 20.1677 22.3389H25.3477C25.8977 22.3389 26.3477 21.8889 26.3477 21.3389V19.8389C26.3477 17.5089 21.6777 16.3389 19.3477 16.3389Z"
            fill="#38A7DF"
          />
        </svg>
        <div className="dashboard-users__title">Участники</div>
      </div>
      <div className="dashboard-users__wrap">
        <div className="dashboard-users__list">
          <div className="dashboard-users__item">
            <div className="dashboard-users__avatar"></div>
            <div className="dashboard-users__name">Bess Atkins</div>
          </div>
          <div className="dashboard-users__item">
            <div className="dashboard-users__avatar"></div>
            <div className="dashboard-users__name">Brett Foster</div>
          </div>
          <div className="dashboard-users__item">
            <div className="dashboard-users__avatar"></div>
            <div className="dashboard-users__name">Leona Todd</div>
          </div>
          <div className="dashboard-users__item">
            <div className="dashboard-users__avatar"></div>
            <div className="dashboard-users__name">Ann Ortiz</div>
          </div>
          <div className="dashboard-users__item">
            <div className="dashboard-users__avatar"></div>
            <div className="dashboard-users__name">Nicholas Black</div>
          </div>
          <div className="dashboard-users__item">
            <div className="dashboard-users__avatar"></div>
            <div className="dashboard-users__name">Ollie Harmon</div>
          </div>
          <div className="dashboard-users__item">
            <div className="dashboard-users__avatar"></div>
            <div className="dashboard-users__name">Bess Atkins</div>
          </div>
          <div className="dashboard-users__item">
            <div className="dashboard-users__avatar"></div>
            <div className="dashboard-users__name">Brett Foster</div>
          </div>
          <div className="dashboard-users__item">
            <div className="dashboard-users__avatar"></div>
            <div className="dashboard-users__name">Leona Todd</div>
          </div>
        </div>
      </div>
    </div>
  )
}