import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  useMemo,
  useState,
} from 'react'
import {
  AppTranslation,
  translationLabelTape,
} from '../AppTranslation/AppTranslation'
import { useTranslation } from 'react-i18next'

export enum InputMaskType {
  integer = 'integer',
  float = 'float',
  phone = 'phone',
  emailList = 'emailList',
}

type IInputMode = {
  [key: string]:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined
}

const inputModeByMask: IInputMode = {
  [InputMaskType.integer]: 'numeric',
  [InputMaskType.float]: 'decimal',
  [InputMaskType.phone]: 'tel',
}

interface IBaseInput {
  customInput?: JSX.Element | null
  onChange: (value: string) => void
  value: string | null | undefined
  placeholder?: translationLabelTape
  type?: HTMLInputTypeAttribute | undefined
  disabled?: boolean
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void
  fullWidth?: boolean
  autoComplete?: boolean
  inputMaskType?: InputMaskType | null // маска инпута по регулярке
  withOutZero?: boolean // вырезает 0 из инпута
  maxLength?: number
  label?: translationLabelTape
  error?: string | boolean
  inputIcoClassName?: string
  rightTextMsg?: translationLabelTape
  onPressEnter?: () => void
  refInput?: React.Ref<any>
  keyDirectionFn?:
    | null
    | ((value: React.KeyboardEvent<HTMLInputElement>) => void) // использует исключительно для определения нажатия вверх или вниз на клавиатуре
}

interface IAppInput extends IBaseInput {
  onClick?: never
  disableInput?: never
  searchInput?: never
  dropdownInput?: never
  onPressEnter?: () => void
  isTextArea?: never
  rows?: never
}

interface IAppInputDropdown extends IBaseInput {
  onClick: (() => void) | null
  disableInput: boolean
  searchInput?: never
  dropdownInput: boolean
  onPressEnter?: never
  isTextArea?: never
  rows?: never
}

interface IAppInputSearch extends IBaseInput {
  onClick?: () => void
  disableInput: false // передается только в том случае, если нам надо принудительно заблокировать инпут в дропдауне.
  searchInput: boolean
  dropdownInput: boolean
  onPressEnter?: () => void
  isTextArea?: never
  rows?: never
}

interface IAppInputTextArea extends IBaseInput {
  onClick?: never
  disableInput?: never
  searchInput?: never
  dropdownInput?: never
  onPressEnter?: never
  isTextArea?: true
  rows?: number
}

type TProps =
  | IAppInput
  | IAppInputDropdown
  | IAppInputSearch
  | IAppInputTextArea

export const AppInput = ({
  onChange,
  onClick,
  value,
  placeholder,
  type = 'text',
  disabled = false,
  label,
  error,
  inputIcoClassName,
  rightTextMsg,
  autoComplete,
  onPressEnter,
  isTextArea,
  rows = 1,
  inputMaskType,
  withOutZero = false,
  maxLength,
  disableInput,
  keyDirectionFn,
  dropdownInput,
  refInput,
  fullWidth,
  customInput,
  onBlur,
}: TProps) => {
  const { t } = useTranslation()
  const changeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (disabled) {
      return
    }
    let result: string
    switch (inputMaskType) {
      case InputMaskType.integer:
        const integerVal = e.target.value
          .replace(/\D/g, '')
          .replace(/^(0)([0-9])+/g, '$2')
        result = integerVal
        break
      case InputMaskType.float:
        const floatVal = e.target.value
          .replace(/[,]+/g, '.')
          .replace(/^\.+/g, '')
          // .replace(/^0+/g, '')
          .replace(/^(0)([0-9])+/g, '$2')
          .match(/(^[0-9]*(\.)?[0-9]{0,2})*/g)
        // onChange(!!newVal ? (newVal[0] === '' ? '0' : newVal[0]) : '')
        result = !!floatVal ? floatVal[0] : ''
        break
      case InputMaskType.phone:
        const phoneVal = e.target.value
          .replace(/\D/g, '')
          .replace(/^(0)([0-9])+/g, '$2')
        result = phoneVal.length === 0 ? '+' : `+${phoneVal}`
        result = result.length <= 16 ? result : result.slice(0, 16)
        break
      case InputMaskType.emailList:
        const emailList = e.target.value
        result = emailList
        break
      default:
        result = e.target.value
    }
    onChange(
      withOutZero && result === '0'
        ? ''
        : maxLength
        ? result.slice(0, maxLength)
        : result,
    )
  }
  const cleanInput = () => {
    if (disabled) {
      return
    }
    onChange('')
  }
  const [inputType, setInputType] = useState(type)
  const inputTypeChangeHandler = () => {
    setInputType((prev) => {
      return prev === 'text' ? 'password' : 'text'
    })
  }
  const isEmpty = value === ''
  const autoCompleteValue = autoComplete
    ? 'on'
    : type === 'email' || type === 'password'
    ? 'on'
    : 'off'

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (onPressEnter && event.code === 'Enter') {
      event.preventDefault()
      onPressEnter()
    }
  }
  const keyDownPropsWrapper = useMemo(() => {
    return keyDirectionFn
      ? { onKeyDown: keyDirectionFn }
      : onPressEnter
      ? {
          onKeyDown,
        }
      : {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onKeyDown])
  const wrapperProps = onClick ? { onClick } : {}
  const wrapperPropsOnBlur = useMemo(() => (onBlur ? { onBlur } : {}), [onBlur])

  const currentPlaceholder = useMemo(() => {
    return placeholder ? t(placeholder) : '...'
  }, [placeholder, t])

  return (
    <div
      className={`app-input-wrapper ${fullWidth ? 'full-width-input' : ''}`}
      {...wrapperProps}
    >
      {label && (
        <div className={`app-input-label${disabled ? ' disabled' : ''}`}>
          <b>
            <AppTranslation label={label} />
          </b>
        </div>
      )}
      {isTextArea ? (
        <textarea
          rows={rows}
          onChange={changeHandler}
          value={value ? value : ''}
          placeholder={placeholder}
          disabled={disabled}
          className={`${error ? ' error' : ''}${!value ? ' empty' : ''}`}
        />
      ) : (
        <React.Fragment>
          <div
            className={`app-input${disabled ? ' disabled' : ''}${
              error ? ' error' : ''
            }${!value ? ' empty' : ''}`}
          >
            {customInput ? (
              customInput
            ) : disableInput && !!onClick ? (
              <span className={'blocked-input'}>{value ? value : ''}</span>
            ) : (
              <input
                type={inputType}
                ref={refInput ? refInput : null}
                placeholder={currentPlaceholder}
                autoComplete={autoCompleteValue}
                {...wrapperPropsOnBlur}
                disabled={disabled || disableInput}
                className={`${!disabled && disableInput ? 'not-disabled' : ''}`}
                onChange={changeHandler}
                {...keyDownPropsWrapper}
                value={value ? value : ''}
                inputMode={
                  !inputMaskType
                    ? undefined
                    : inputModeByMask[`${inputMaskType}`]
                    ? inputModeByMask[`${inputMaskType}`]
                    : undefined
                }
              />
            )}
            {!customInput && !dropdownInput && !isEmpty && !disabled && (
              <i
                className="an-ico an-ico-cross clear-input"
                onClick={cleanInput}
              />
            )}
            {!disabled && dropdownInput && (
              <i className="an-ico an-ico-arrow-down" />
            )}
            {!isEmpty && type === 'password' && (
              <i
                className="an-ico an-ico-eye"
                onClick={inputTypeChangeHandler}
              />
            )}
            {!!inputIcoClassName && (
              <i className={`an-ico ${inputIcoClassName}`} />
            )}
            {rightTextMsg && (
              <span className="color-Gray-light">
                {<AppTranslation label={rightTextMsg} />}
              </span>
            )}
            <div className="separator" />
          </div>
          {error && typeof error === 'string' && (
            <div className="error-msg">
              <AppTranslation label={error} />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  )
}
