import React from 'react'
import { EntityForeignKeyFormViewExtractor } from '../EntityForeignKeyFormViewExtractor/EntityForeignKeyFormViewExtractor'
import { PriorityWidget } from '../PriorityWidget/PriorityWidget'

interface IEntityDetailsWidget {
  title?: string
  importance?: number[]
  description?: string
  goal?: string
  budget?: string
  location?: number
  recommendation_planning_level?: number
  planned_material_type?: number[]
}

export const EntityDetailsWidget = ({
  title,
  importance,
  description,
  goal,
  budget,
  location,
  recommendation_planning_level,
}: IEntityDetailsWidget) => {
  return (
    <React.Fragment>
      <div className="block-section">
        <div className="detail-widget-row">
          <h2>{title}</h2>
          {/*<div className="form-notifier">
            <i className="an-ico an-ico-clock color-Light-blue"></i>
            <span>Напоминание</span>
            <span className="cursor-pointer color-Light-blue">Напомнить</span>
          </div>*/}
        </div>
        <div className="detail-widget-row">
          {importance && (
            <PriorityWidget
              importanceValue={Number(importance)}
              title={'entity_element_details__txt_priority'}
              dataType={'importance'}
            />
          )}
        </div>
      </div>
      {description && (
        <div className="block-section">
          <b>Описание</b>
          <p className="description-block">{description}</p>
        </div>
      )}
      <div className="block-section">
        <div className="row p-row">
          <div className="col-12">
            <div className="simple-field-view">
              <b className="field-name">Цель:</b>
              <div className="field-value">{goal ? goal : 'Нет данных'}</div>
            </div>
          </div>
          {budget ? (
            <div className="col-12">
              <div className="simple-field-view">
                <b className="field-name">Бюджет:</b>
                <div className="field-value">
                  {budget ? budget : 'Нет данных'}
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {/*<div className="col-4">
            <div className="simple-field-view">
              <div className="field-name">Проект:</div>
              <div className="field-value">не подключено</div>
            </div>
          </div>
          <div className="col-4">
            <div className="simple-field-view">
              <div className="field-name">Сфера:</div>
              <div className="field-value">не подключено</div>
            </div>
          </div>
        */}
        </div>
      </div>
    </React.Fragment>
  )
}
