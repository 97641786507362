import React from 'react'

const IconVisibility: React.FC = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.76953 3.76953C6.11589 3.42318 6.52604 3.25 7 3.25C7.47396 3.25 7.88411 3.42318 8.23047 3.76953C8.57682 4.11589 8.75 4.52604 8.75 5C8.75 5.47396 8.57682 5.88411 8.23047 6.23047C7.88411 6.57682 7.47396 6.75 7 6.75C6.52604 6.75 6.11589 6.57682 5.76953 6.23047C5.42318 5.88411 5.25 5.47396 5.25 5C5.25 4.52604 5.42318 4.11589 5.76953 3.76953ZM4.92188 7.07812C5.50521 7.64323 6.19792 7.92578 7 7.92578C7.80208 7.92578 8.48568 7.64323 9.05078 7.07812C9.63411 6.49479 9.92578 5.80208 9.92578 5C9.92578 4.19792 9.63411 3.51432 9.05078 2.94922C8.48568 2.36589 7.80208 2.07422 7 2.07422C6.19792 2.07422 5.50521 2.36589 4.92188 2.94922C4.35677 3.51432 4.07422 4.19792 4.07422 5C4.07422 5.80208 4.35677 6.49479 4.92188 7.07812ZM3.08984 1.82812C4.25651 1.02604 5.5599 0.625 7 0.625C8.4401 0.625 9.74349 1.02604 10.9102 1.82812C12.0768 2.63021 12.9154 3.6875 13.4258 5C12.9154 6.3125 12.0768 7.36979 10.9102 8.17188C9.74349 8.97396 8.4401 9.375 7 9.375C5.5599 9.375 4.25651 8.97396 3.08984 8.17188C1.92318 7.36979 1.08464 6.3125 0.574219 5C1.08464 3.6875 1.92318 2.63021 3.08984 1.82812Z" />
    </svg>
  )
}

export default IconVisibility
