import React, { useMemo } from 'react'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { AppTable } from '../../../common/components/AppTable/AppTable'
import { useGetAllCompaniesQuery } from '../../../core/api/BaseApiEndpoints/Companies/CompaniesApi'
import { NavLink } from 'react-router-dom'
import { ResponsiblePersonView } from '../../../common/components/AppTable/ResponsiblePersonView'
import { AppTableCell } from '../../../common/components/AppTable/AppTableCell'
import { ICompanyDto } from '../../../core/api/dto/CompaniesDto'
import { HeaderSearchInput } from '../../../common/components/HeaderSearchInput/HeaderSearchInput'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'

const tableConfig = {
  tableHeaders: [
    'companies_page__txt_table_header_id',
    'companies_page__txt_table_header_name',
    'companies_page__txt_table_header_responsible_user',
  ],
  width: ['auto', '50%'],
}

export const CompaniesListingPage = () => {
  const { data } = useGetAllCompaniesQuery()

  const tableSelectors = useMemo(() => {
    return [
      {
        renderItem: (item: ICompanyDto) => (
          <AppTableCell>
            <NavLink to={`${item.id}`}>
              <AppTableCell>{item.id}</AppTableCell>
            </NavLink>
          </AppTableCell>
        ),
      },
      {
        renderItem: (item: ICompanyDto) => (
          <AppTableCell>
            <AppTableCell>{item.name}</AppTableCell>
          </AppTableCell>
        ),
      },
      {
        renderItem: (item: ICompanyDto) => (
          <AppTableCell>
            <ResponsiblePersonView name={`${item.responsible_user}`} />
          </AppTableCell>
        ),
      },
    ]
  }, [])

  if (!data) {
    return null
  }

  return (
    <>
      <div className={'back-office-header'}>
        <h1>
          <AppTranslation label={AllRoutes.companies.name} />
        </h1>
        <div className={'header-actions-block'}>
          <div className="actions-left-block">
            <HeaderSearchInput
              searchParam={'filters[search]'}
              placeholder={'general__txt_search'}
            />
          </div>
          <div className="actions-right-block">
            <AppButton
              path={AllRoutes.companyCreate.path}
              icoClassName={'an-ico-plus-thin'}
              title={'company_create_page__txt_nav_button_title'}
              color={AppButtonColor.lightBlue}
              size={AppButtonSize.small}
            />
          </div>
        </div>
      </div>
      <AppTable
        headerTitles={tableConfig.tableHeaders}
        cellWidth={tableConfig.width}
        tableDataSelectors={tableSelectors}
        data={{ count: 1000, limit: 10, page: 1, total: 10, items: data }}
        lastColAlignLeft={true}
      />
    </>
  )
}
