import React, { useMemo } from 'react'
import { EntityForeignKeyFormViewExtractor } from '../EntityForeignKeyFormViewExtractor/EntityForeignKeyFormViewExtractor'
import { translationLabelTape } from '../AppTranslation/AppTranslation'
import { ResponsiblePersonView } from '../AppTable/ResponsiblePersonView'
import { SectionWidget } from '../SectionWidget/SectionWidget'
import { IBaseProfileDto } from '../../../core/api/dto/ProfilesDto'
import { IReferenceValuesTypeObject } from '../../../core/api/dto/EntityDto'
import { ItemRepresentByDataType } from '../../../pages/AdminPage/Entity/ItemRepresentByDataType'

interface IPersonsRepresentWidgetWithOutFullData {
  data: number | number[]
  label: translationLabelTape
  dataType: string
}

interface IPersonsRepresentWidgetWithFullData {
  data: IBaseProfileDto[]
  label: translationLabelTape
  dataType?: never
}

type PropsType =
  | IPersonsRepresentWidgetWithOutFullData
  | IPersonsRepresentWidgetWithFullData

export const PersonsRepresentWidget = ({
  data,
  label,
  dataType,
}: PropsType) => {
  const extractedData = useMemo(() => {
    const realData = Array.isArray(data) ? data : [data]

    return realData.map((item) =>
      dataType ? (
        <EntityForeignKeyFormViewExtractor
          dataType={dataType}
          value={item as number}
        />
      ) : (
        <ItemRepresentByDataType
          itemData={item as unknown as IReferenceValuesTypeObject}
        />
      ),
    )
  }, [data, dataType])
  return (
    <SectionWidget title={label}>
      <div className="responsible-person-items-form-widget">
        {extractedData.map((item, index) => {
          return dataType ? (
            <ResponsiblePersonView name={item} key={`person-${index}`} />
          ) : (
            <React.Fragment key={`person-${index}`}>{item}</React.Fragment>
          )
        })}
      </div>
    </SectionWidget>
  )
}
