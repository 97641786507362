import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AppInput } from '../../../common/components/AppInput/AppInput'
import { AppButton } from '../../../common/components/AppButton/AppButton'
import {
  useCheckResetPasswordTokenMutation,
  useSetNewPasswordMutation,
} from '../../../core/api/BaseApiEndpoints/Auth/ResetPasswordApi'
import { ICustomErrorDto } from '../../../core/api/BaseApi'
import { setCredentials } from '../../../core/store/auth/authSlice'
import { useAppDispatch, useAppSelector } from '../../../core/store/store'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'

export const ResetPasswordConfirm = () => {
  const [searchParams] = useSearchParams()
  const resetToken = searchParams.get('token')
  const [error, setError] = useState('')
  const { currentRole } = useAppSelector((state) => state.mediaPlanAuth)
  const [checkToken, { isLoading, isSuccess, status }] =
    useCheckResetPasswordTokenMutation()
  const [setNewPassword, { isLoading: isNewPasswordLoading }] =
    useSetNewPasswordMutation()
  const [formState, setFormState] = useState({
    token: `${resetToken}`,
    password: '',
    password_confirmed: '',
  })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    if (!resetToken) {
      navigate(`/${AllRoutes.login.path}`)
    } else {
      checkToken({ token: resetToken })
        .unwrap()
        .catch((error: ICustomErrorDto) =>
          setError(
            typeof error.data === 'string'
              ? error.data
              : error.data.status_text,
          ),
        )
    }
  }, [resetToken, navigate, checkToken, currentRole])
  const formStateChangeHandler = (propName: string, value: string) => {
    setError('')
    setFormState((prev) => {
      return {
        ...prev,
        [propName]: value,
      }
    })
  }
  const setNewPasswordHandler = () => {
    setNewPassword({ ...formState })
      .unwrap()
      .then((result) => dispatch(setCredentials(result)))
      .catch((error: ICustomErrorDto) =>
        setError(
          typeof error.data === 'string' ? error.data : error.data.status_text,
        ),
      )
  }
  if (status !== 'fulfilled' && status !== 'rejected') {
    return (
      <React.Fragment>
        <div className={'form-status-ico confirm'}>
          <i className="an-ico an-ico-time" />
        </div>
        <p>
          <AppTranslation label={'reset_password_confirm__txt_loader_text'} />
        </p>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      {!isSuccess ? (
        <React.Fragment>
          <div className={'form-status-ico error'}>
            <i className="an-ico an-ico-cross" />
          </div>
          <p>{error}</p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>
            <AppTranslation label={'reset_password_confirm__txt_description'} />
          </p>
          <AppInput
            onChange={(value) => formStateChangeHandler('password', value)}
            value={formState.password}
            type={'password'}
            placeholder={'reset_password_confirm__txt_form_field_password'}
            inputIcoClassName={'an-ico-password'}
            error={!!error}
          />
          <AppInput
            onChange={(value) =>
              formStateChangeHandler('password_confirmed', value)
            }
            value={formState.password_confirmed}
            type={'password'}
            placeholder={
              'reset_password_confirm__txt_form_field_confirm_password'
            }
            inputIcoClassName={'an-ico-password'}
            error={error}
          />
          <AppButton
            title={'general__btn_save'}
            onClick={setNewPasswordHandler}
            disabled={
              formState.password === '' ||
              isLoading ||
              isNewPasswordLoading ||
              formState.password !== formState.password_confirmed
            }
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
