import { api } from '../../BaseApi'
import {
  IInfoBlockCreate,
  IInfoBlockCreatePropType,
  IInfoBlocksListing,
  IInfoBlocksListingFilters,
  IPropType,
} from '../../dto/InfoBlockDto'

export const infoBlocksApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getInfoBlocks: builder.query<
      IInfoBlocksListing[],
      { filters: IInfoBlocksListingFilters } | null | void
    >({
      query: (props) => ({
        url: `/info-blocks/`,
        params: props?.filters ? props.filters : {},
      }),
      providesTags: ['InfoBlocks', 'Projects'],
    }),
    getInfoBlockByName: builder.query<
      {
        count: number
        items: { [key: string]: string }[]
        limit: number
        page: number
        total: number
      },
      { infoBlockSymbolLink: string }
    >({
      query: ({ infoBlockSymbolLink }) => ({
        url: `/info-blocks/${infoBlockSymbolLink}/`,
      }),
      providesTags: ['InfoBlocks', 'Projects'],
    }),
    getInfoBlockTypesReference: builder.query<IPropType[], void>({
      query: () => ({
        url: `/get_all_types/`,
      }),
      providesTags: ['InfoBlocks', 'Projects'],
    }),
    createNewInfoBlock: builder.mutation<
      { name: string; data: IInfoBlockCreatePropType[] },
      IInfoBlockCreate
    >({
      query: (data) => ({
        url: `/info-blocks/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['InfoBlocks', 'Projects'],
    }),
  }),
})

export const {
  useGetInfoBlocksQuery,
  useGetInfoBlockByNameQuery,
  useGetInfoBlockTypesReferenceQuery,
  useCreateNewInfoBlockMutation,
} = infoBlocksApi
