import React from 'react'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'

export const ResetPasswordCheckEmail = () => {
  return (
    <React.Fragment>
      <div className={'form-msg'}>
        <small>
          <AppTranslation label={'reset_password_check_email__msg_text'} />
        </small>
      </div>
    </React.Fragment>
  )
}
