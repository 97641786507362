import React, { useState } from 'react'

export const DashboardSummary = () => {
  const [currentPeriod, setCurrentPeriod] = useState<'day' | 'week' | 'month'>(
    'day',
  )
  const data = [
    {
      year: 'Завершённые',
      value: 38,
    },
    {
      year: 'Ожидание',
      value: 52,
    },
    {
      year: 'В разработке',
      value: 61,
    },
    {
      year: 'Просроченные',
      value: 145,
    },
  ]
  const config = {
    data,
    xField: 'value',
    yField: 'year',
    seriesField: 'year',
    maxBarWidth: 10,
    // lineWidth: 5,
    barStyle: {
      radius: [20, 20, 0, 0],
    },
    textAnnotation: {
      maxLength: 200,
    },
  }

  return (
    <div className="dashboard__summary dashboard-summary dashboard-card">
      <div className="dashboard-summary__head">
        <div className="dashboard-summary__title">Статистика</div>
        <div className="dashboard-summary__period dashboard-period">
          <div
            onClick={() => setCurrentPeriod('day')}
            className={`dashboard-period__item${
              currentPeriod === 'day' ? ' dashboard-period__item--active' : ''
            }`}
          >
            Сегодня
          </div>
          <div
            onClick={() => setCurrentPeriod('week')}
            className={`dashboard-period__item${
              currentPeriod === 'week' ? ' dashboard-period__item--active' : ''
            }`}
          >
            Неделя
          </div>
          <div
            onClick={() => setCurrentPeriod('month')}
            className={`dashboard-period__item${
              currentPeriod === 'month' ? ' dashboard-period__item--active' : ''
            }`}
          >
            Месяц
          </div>
        </div>
        <div className="dashboard-summary__menu">
          <svg
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.5391 11C16.6391 11 17.5391 10.1 17.5391 9C17.5391 7.9 16.6391 7 15.5391 7C14.4391 7 13.5391 7.9 13.5391 9C13.5391 10.1 14.4391 11 15.5391 11ZM15.5391 13C14.4391 13 13.5391 13.9 13.5391 15C13.5391 16.1 14.4391 17 15.5391 17C16.6391 17 17.5391 16.1 17.5391 15C17.5391 13.9 16.6391 13 15.5391 13ZM15.5391 19C14.4391 19 13.5391 19.9 13.5391 21C13.5391 22.1 14.4391 23 15.5391 23C16.6391 23 17.5391 22.1 17.5391 21C17.5391 19.9 16.6391 19 15.5391 19Z"
              fill="#C3CAD9"
            />
          </svg>
        </div>
      </div>
      <div
        className="dashboard-summary__wrap"
        style={{ width: '100%', height: '264px', padding: '10px' }}
      >
        {/*<Bar {...config} />*/}
      </div>
    </div>
  )
}
