import { api } from '../../BaseApi'
import {
  IAuthRequestDto,
  IUserCredentialsResponseDto,
  IUserRefreshTokenRequestDto,
} from '../../dto/AuthDto'

export const auth = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<IUserCredentialsResponseDto, IAuthRequestDto>({
      query: ({ ...credentials }) => ({
        url: `/user-login/`,
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: ['User'],
    }),
    logout: builder.mutation<null, IUserRefreshTokenRequestDto>({
      query: ({ refresh }) => {
        return {
          url: `/logout/`,
          method: 'POST',
          body: { refresh },
        }
      },
      invalidatesTags: ['User'],
    }),
    refreshToken: builder.mutation<
      IUserCredentialsResponseDto,
      IUserRefreshTokenRequestDto
    >({
      query: ({ refresh }) => {
        return {
          url: `/token/refresh/`,
          method: 'POST',
          body: { refresh },
        }
      },
      invalidatesTags: ['User'],
    }),
  }),
})

export const { useLoginMutation, useLogoutMutation, useRefreshTokenMutation } =
  auth
