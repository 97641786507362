import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'

export const useGoBack = (ascentDepth = 1) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const goBackHandler = useCallback(() => {
    const pathArr = pathname.split('/')
    pathArr.splice(pathArr.length - ascentDepth, pathArr.length - 1)
    const newPath = pathArr.join('/')
    navigate(newPath)
  }, [navigate, pathname, ascentDepth])
  return { goBackHandler }
}

export const HeaderBackButton = React.memo(
  ({ onClick }: { onClick?: () => void }) => {
    const navigate = useNavigate()
    const backBtnHandler = () => {
      if (onClick) {
        onClick()
      } else {
        navigate(-1)
      }
    }
    return (
      <div className="back-btn" onClick={backBtnHandler}>
        <i className="an-ico an-ico-arrow-left-middle" />
      </div>
    )
  },
)
