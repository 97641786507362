import { AppTranslation } from 'common/components/AppTranslation/AppTranslation'
import { AllRoutes } from 'core/routes/AllRoutes'
import { HeaderSearchInput } from 'common/components/HeaderSearchInput/HeaderSearchInput'
import { FileOutlined } from '@ant-design/icons'
import { useSearchParamsHelper } from 'helpers/setSearchParam'
import { Link } from 'react-router-dom'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { PDF } from './PDF'
import { useGetEntitiesReportQuery } from 'core/api/BaseApiEndpoints/Entity/EntityApi'

export const ReportsListingPage = () => {
  const { searchParamsString } = useSearchParamsHelper({})
  const { data: reportPrevious } = useGetEntitiesReportQuery({
    type: 'previous',
  })

  const { data: reportCurrent } = useGetEntitiesReportQuery({
    type: 'current',
  })
  return (
    <div className="reports-page">
      <div className={'back-office-header'}>
        <h1>
          <AppTranslation label={AllRoutes.reports.name} />
        </h1>
        <div className={'header-actions-block'}>
          <div className="actions-left-block">
            <HeaderSearchInput
              searchParam={'search'}
              placeholder={'general__txt_search'}
            />
          </div>
        </div>
      </div>
      {/*<div className='reports-items'>
          <a className='reports-item' target="_blank" href="https://mp.glassen-it.com/static/media/analytics.pdf">
          <FileOutlined />
          <p className='reports-item_title'>Отчет1.pdf</p>
          <p className='reports-item_description'>Инструкция к разработке портала</p>
        </a>
  </div>*/}
      <div className="reports-items">
        <PDFDownloadLink
          className="reports-item"
          document={<PDF data={reportPrevious} title={'за прошлую неделю'} />}
          fileName="Медиаплан_за_прошлую_неделю.pdf"
        >
          <FileOutlined />
          {({ blob, url, loading, error }) =>
            loading ? 'Загрузка...' : 'Медиаплан_за_текущую_неделю.pdf'
          }
          <p className="reports-item_description">
            Медиаплан за прошлую неделю
          </p>
        </PDFDownloadLink>
        <PDFDownloadLink
          className="reports-item"
          document={<PDF data={reportCurrent} title={'за текущую неделю'} />}
          fileName="Медиаплан_за_текущую_неделю.pdf"
        >
          <FileOutlined />
          {({ blob, url, loading, error }) =>
            loading ? 'Загрузка...' : 'Медиаплан_за_текущую_неделю.pdf'
          }
          <p className="reports-item_description">
            Медиаплан за текущую неделю
          </p>
        </PDFDownloadLink>
      </div>
    </div>
  )
}
