import React, { useCallback, useMemo, useState } from 'react'
import { Breadcrumbs } from '../../../common/components/Breadcrumbs/Breadcrumbs'
import { useParams } from 'react-router'
import { FromDatesWidget } from '../../../common/components/Tasks/FromDatesWidget'
import { SectionWidget } from '../../../common/components/SectionWidget/SectionWidget'
import { PersonsRepresentWidget } from '../../../common/components/PersonsRepresentWidget/PersonsRepresentWidget'
import { Link, useNavigate } from 'react-router-dom'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import {
  AppPopup,
  popupType,
} from '../../../common/components/AppPopup/AppPopup'
import {
  useGetEntitiesSchemasQuery,
  useGetEntityTypesQuery,
} from 'core/api/BaseApiEndpoints/Entity/EntityApi'
import { ItemRepresentByDataType } from '../Entity/ItemRepresentByDataType'
import { AppTranslation } from 'common/components/AppTranslation/AppTranslation'
import { Dashboard } from '../Entity/Dashboard'
import { EntityDetailsWidget } from 'common/components/EntityDetailsWidget/EntityDetailsWidget'
import { TargetAudienceWidget } from 'common/components/TargetAudienceWidget/TargetAudienceWidget'
import { SphereWidget } from 'common/components/SphereWidget.tsx/SphereWidget'
import { EntityRelatedWidget } from 'common/components/EntityRelatedWidget/EntityRelatedWidget'
import { ResponsiblePersonView } from 'common/components/AppTable/ResponsiblePersonView'
import {
  useDelProjectMutation,
  useGetProjectByIdHistoryQuery,
  useGetProjectByIdQuery,
} from 'core/api/BaseApiEndpoints/Projects/ProjectsApi'
import { PaperClipOutlined } from '@ant-design/icons'
import { HistoryMode } from '../Entity/HistoryMode'

export const ProjectDetail = () => {
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { data } = useGetProjectByIdQuery({ projectId: Number(projectId) })
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false)
  const [delProject] = useDelProjectMutation()
  const [isDashboard, setIsDashboard] = useState(false)
  const { data: entitySchema } = useGetEntitiesSchemasQuery()
  const { data: historyItems } = useGetProjectByIdHistoryQuery({ projectId: Number(projectId) })
  const { data: entityNames } = useGetEntityTypesQuery()
  let entityItems = entityNames?.map((item, id) => item.name)
  const [isHistoryMode, setIsHistoryMode] = useState(false)

  const addSubEntityBtn = useMemo(() => {
    return (
      <React.Fragment>
        <div className={'header-action-block'}>
          {data?.is_published && (
            <>
              {entityItems?.map((model: string) => {
                if (model != 'Проект' && !data?.prohibited_entities.includes(model))
                  return (
                    <AppButton
                      key={`add-btn-${model}`}
                      path={`${AllRoutes.entityRootCreateRelated.path}/${model}`}
                      color={AppButtonColor.aquaBlue}
                      title={{
                        label: 'entity_element_edit__txt_add_linked_entity_btn',
                        options: { entityName: model },
                      }}
                      size={AppButtonSize.small}
                    />
                  )
              })}
              <AppButton
                path={`${AllRoutes.entityRootCreateTask.path}/${AllRoutes.tasksListing.path}`}
                color={AppButtonColor.aquaBlue}
                title={'Создать задачу'}
                icoClassName={'an-ico-plus-thin'}
                size={AppButtonSize.small}
              />
              <AppButton
                path={`/${AllRoutes.reports.path}`}
                color={AppButtonColor.aquaBlue}
                title={'Отчеты'}
                size={AppButtonSize.small}
              />
            </>
          )}
          <AppButton
            path={`${AllRoutes.projectEdit.path}`}
            color={AppButtonColor.aquaBlue}
            title={'Редактировать'}
            size={AppButtonSize.small}
          />
        </div>
        <AppButton
          color={AppButtonColor.red}
          title={'Удалить'}
          icoClassName={'an-ico-trash'}
          size={AppButtonSize.small}
          onClick={() => setShowDeleteConfirmPopup(true)}
        />
      </React.Fragment>
    )
  }, [entitySchema, data])

  const companiesBreadcrumbs = useMemo(() => {
    return [
      { name: 'projects_page__txt_title', path: '../' },
      {
        name: {
          label: 'projects_detail_page__txt_nav_button_title',
          options: { projectName: `${data ? data.title : ''}` },
        },
      },
    ]
  }, [data, projectId])

  const delBtnClickHandler = useCallback(() => {
    delProject({ projectId: projectId! })
      .unwrap()
      .then(() => {
        navigate(`/${AllRoutes.projects.path}`)
      })
  }, [])

  if (!data) {
    return null
  }

  return (
    <React.Fragment>
      <div className={'back-office-header'}>
        <Breadcrumbs breadcrumbs={companiesBreadcrumbs} />
        <div
          style={{ display: 'flex', alignItems: 'center', marginTop: '24px' }}
        >
          <h1 className="text-ellipsis" style={{ maxWidth: '600px' }}>
            <AppTranslation label={data?.title as string} />
          </h1>
          <div className="dashboard-tab">
            <div
              onClick={() => {
                setIsHistoryMode(false)
                setIsDashboard(false)
              }}
              className={`dashboard-tab__item${
                !isDashboard && !isHistoryMode ? ' dashboard-tab__item--active' : ''
              }`}
            >
              Информация
            </div>
            <div
                onClick={() => {
                  setIsHistoryMode(true)
                  setIsDashboard(false)
                }}
                className={`dashboard-tab__item${
                  isHistoryMode ? ' dashboard-tab__item--active' : ''
                }`}
              >
                История изменений
              </div>
            <div
              onClick={() => {
                setIsHistoryMode(false)
                setIsDashboard(true)
              }}
              className={`dashboard-tab__item${
                isDashboard ? ' dashboard-tab__item--active' : ''
              }`}
            >
              Дашбоард
            </div>
          </div>
        </div>
        {!isDashboard && !isHistoryMode ? (
          <div className={'header-actions-block'}>
            <div className="actions-left-block header-actions-block_justify">
              {addSubEntityBtn}
            </div>
          </div>
        ) : null}
      </div>
      {isHistoryMode ? (
        <HistoryMode items={historyItems?.history}/>
      ) : isDashboard ? (
        <Dashboard />
      ) : (
        <div className="back-office-form">
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-12">
                  <div className="form-block">
                    {!data?.is_published && (
                      <span className="form-block_draft">Черновик</span>
                    )}
                    <EntityDetailsWidget
                      title={data.title}
                      description={data.description}
                      importance={data?.importance?.id}
                      goal={data.goal}
                    />
                  </div>
                  <div className="form-block"></div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                {data?.target_audience?.length > 0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <TargetAudienceWidget
                          targetAudienceData={data?.target_audience.map(
                            (it: any) => it.id,
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {data?.planned_material_type?.length > 0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SphereWidget
                          targetAudienceData={data?.planned_material_type.map(
                            (it: any) => it.id,
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {data.start_date && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SectionWidget
                          title={'task_section_widget_dates__txt_title'}
                          icoClassName={'an-ico-calendar'}
                        >
                          <FromDatesWidget
                            date_start={data.start_date}
                            date_end={data.end_date}
                          />
                        </SectionWidget>
                      </div>
                    </div>
                  </div>
                )}
                {data.link && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SectionWidget
                          title={'general_widget_header__txt_link'}
                          icoClassName={'an-ico-link'}
                        >
                          <>
                            {data.link ? (
                              <a href={data.link} target={'_blank'}>
                                {data.link}
                              </a>
                            ) : (
                              '---'
                            )}
                          </>
                        </SectionWidget>
                      </div>
                    </div>
                  </div>
                )}
                {data.project_media.length > 0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SectionWidget title={'Медиа'}>
                          <>
                            {data.project_media.map((media: any) => {
                              return (
                                <p key={`media-${media.id}`}>
                                  <PaperClipOutlined
                                    style={{
                                      fontSize: '20px',
                                      color: '#52acc3',
                                    }}
                                  />{' '}
                                  <a href={media.file} target={'_blank'}>
                                    {media.file.split('media/')[1]}
                                  </a>
                                </p>
                              )
                            })}
                          </>
                        </SectionWidget>
                      </div>
                    </div>
                  </div>
                )}

                {data.project_document.length > 0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SectionWidget title={'Документы'}>
                          <>
                            {data.project_document.map((document: any) => {
                              return (
                                <p key={`document-${document.id}`}>
                                  <PaperClipOutlined
                                    style={{
                                      fontSize: '20px',
                                      color: '#52acc3',
                                    }}
                                  />{' '}
                                  <ItemRepresentByDataType
                                    itemData={document}
                                  />
                                </p>
                              )
                            })}
                          </>
                        </SectionWidget>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                {data.creator && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <PersonsRepresentWidget
                          data={data.creator}
                          dataType={'creator'}
                          label={'general_widget_header__txt_creator'}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {data.responsible && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <PersonsRepresentWidget
                          data={data?.responsible?.id}
                          dataType={'responsible'}
                          label={'general_widget_header__txt_responsible'}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {data.co_executor && data.co_executor.length > 0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <SectionWidget
                          title={'general_widget_header__txt_coowner'}
                        >
                          <div className="responsible-person-items-form-widget">
                            {data.co_executor.map(
                              (item: any, index: number) => {
                                return (
                                  <ResponsiblePersonView
                                    name={
                                      item.last_name +
                                      ' ' +
                                      item.first_name +
                                      ' ' +
                                      `${item.patronymic || ''}`
                                    }
                                    img={item.photo}
                                    key={`person-${index}`}
                                  />
                                )
                              },
                            )}
                          </div>
                        </SectionWidget>
                      </div>
                    </div>
                  </div>
                )}
                {data.coowner && data.coowner.length > 0 && (
                  <div className="col-12">
                    <div className="form-block">
                      <div className="block-section">
                        <PersonsRepresentWidget
                          data={data.coowner}
                          dataType={'coowner'}
                          label={'general_widget_header__txt_coowner'}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/*
                data.prohibited_entities &&
                  Array.isArray(data.prohibited_entities) && data.prohibited_entities.length > 0 && (
                    <div className="col-12">
                      <div className="form-block">
                        <div className="block-section">
                          <SectionWidget title={'Недоступные сущности'}>
                            <>
                              {data.prohibited_entities.map((item: any) => {
                                return (
                                  <p key={`item-${item}`}>
                                    <Link
                                      to={`/${AllRoutes.tasksListing.path}/${item.id}`}
                                    >
                                      {item}
                                    </Link>
                                  </p>
                                )
                              })}
                            </>
                          </SectionWidget>
                        </div>
                      </div>
                    </div>
                  )
                            */}
                {data.entities && Object.keys(data.entities).length !== 0 && (
                  <EntityRelatedWidget data={data.entities} />
                )}
                {data.tasks &&
                  Array.isArray(data.tasks) &&
                  data.tasks.length > 0 && (
                    <div className="col-12">
                      <div className="form-block">
                        <div className="block-section">
                          <SectionWidget title={'Связанные задачи'}>
                            <>
                              {data.tasks.map((item: any) => {
                                return (
                                  <p key={`item-${item.id}`}>
                                    <i className="an-ico an-ico-link"></i>
                                    <Link
                                      to={`/${AllRoutes.tasksListing.path}/${item.id}`}
                                    >
                                      <>
                                        <AppTranslation label={'Задача #'} />
                                        {item.id}
                                      </>
                                    </Link>
                                  </p>
                                )
                              })}
                            </>
                          </SectionWidget>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showDeleteConfirmPopup ? (
        <AppPopup
          title={`entity_element_details__popup_delete_title`}
          onClose={() => setShowDeleteConfirmPopup(false)}
          type={popupType.danger}
          description={`entity_element_details__popup_delete_text`}
        >
          <div className="row delete-btns">
            <AppButton
              title={'entity_element_details__popup_delete_btn_yes'}
              onClick={delBtnClickHandler}
              color={AppButtonColor.red}
              size={AppButtonSize.base}
            />
            <AppButton
              title={'entity_element_details__popup_delete_btn_no'}
              onClick={() => setShowDeleteConfirmPopup(false)}
              color={AppButtonColor.blue}
              size={AppButtonSize.base}
            />
          </div>
        </AppPopup>
      ) : null}
    </React.Fragment>
  )
}
