import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { IWithPaginationResponse } from '../../../core/api/dto/BaseDto'
import { AppTableCell } from './AppTableCell'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../AppButton/AppButton'
import { useSearchParamsHelper } from '../../../helpers/setSearchParam'
import { AppTranslation } from '../AppTranslation/AppTranslation'
import { useNavigate } from 'react-router-dom'
import { Pagination } from 'antd'

export enum AppTableLoadType {
  loadMore,
  nextPrevBtn,
}

interface ITableData<T> extends IWithPaginationResponse {
  items: T[] | []
}

interface ISubTableComponent<T> {
  subTableComponentRender?: (item: T) => JSX.Element | string
}

type cellWidth = number | string

interface IAppTable<T, TKey> extends ISubTableComponent<T> {
  headerTitles?: string[]
  cellWidth?: cellWidth[]
  tableDataSelectors: TSelector<T, TKey>[]
  statusErrorPropName?: TKey
  isDataLoading?: boolean
  loadType?: AppTableLoadType
  lastColAlignLeft?: boolean
  isStickyHeader?: boolean
  tableAsTasks?: boolean
  tableAsEntity?: boolean
}

interface IWithLoadMore<T, TKey> extends IAppTable<T, TKey> {
  data: ITableData<T> | null
}

interface ISelector<TKey> {
  propsNameToDisable?: TKey
}

interface ISelectorName<TKey> extends ISelector<TKey> {
  name: TKey | null
  renderItem?: never
}

interface ISelectorVoid<T> extends ISelector<keyof T> {
  name?: never
  renderItem: (item: T) => JSX.Element | string
}

type TSelector<T, TKey> = ISelectorName<TKey> | ISelectorVoid<T>

type TProps<T, TKey> = IWithLoadMore<T, TKey>
// type TProps<T, TKey> = IWithOutLoadMore<T, TKey> // перевел таблицу без loadMore

interface ITableRow<T, TKey> extends ISubTableComponent<T> {
  index: number
  item: T
  cellCount: number
  tableDataSelectors: TSelector<T, TKey>[]
  tableAsEntity?: boolean
}

const typedMemo: <T>(c: T) => T = React.memo

const Row = <T, TKey extends keyof T>({
  index,
  item,
  cellCount,
  tableDataSelectors,
  subTableComponentRender,
  tableAsEntity,
}: ITableRow<T, TKey>) => {
  const navigate = useNavigate()
  const [subTableActive, setSubTableActive] = useState(false)
  const defaultRenderItem = (item: T, name: TKey) => {
    return <AppTableCell>{item[name] ? `${item[name]}` : null}</AppTableCell>
  }

  const clickHandler = () => {
    if (tableAsEntity) {
      // @ts-ignore
      if ('pk' in item)
        // @ts-ignore
        navigate(`${item.pk}`)
      // @ts-ignore
      else navigate(`${item.id}`)
    }
  }

  return (
    <React.Fragment>
      <tr className={``} onClick={clickHandler}>
        {tableDataSelectors.map(
          (
            {
              name,
              renderItem = (item) =>
                name ? defaultRenderItem(item, name!) : '?',
            },
            tdIndex,
          ) => {
            return <td key={`tr-${index}-td-${tdIndex}`}>{renderItem(item)}</td>
          },
        )}
      </tr>
      {subTableComponentRender && (
        <tr>
          <td colSpan={cellCount} className={'table-show-more-wrapper'}>
            <i
              className={`table-show-more-btn an-ico an-ico-arrow-down${
                subTableActive ? ' active' : ''
              }`}
              onClick={() => setSubTableActive((prev) => !prev)}
            />
          </td>
        </tr>
      )}
      {subTableComponentRender &&
        subTableActive &&
        subTableComponentRender(item)}
    </React.Fragment>
  )
}

const TableRow = typedMemo(Row)

const Table = <T, TKey extends keyof T>(
  props: PropsWithChildren<TProps<T, TKey>>,
) => {
  const {
    headerTitles,
    data,
    tableDataSelectors,
    // isDataLoading,
    subTableComponentRender,
    loadType = AppTableLoadType.nextPrevBtn,
    cellWidth = [],
    lastColAlignLeft = false,
    isStickyHeader = false,
    tableAsTasks,
    tableAsEntity,
  } = props
  const [loadMore, setLoadMore] = useState(false)
  const [tableData, setTableData] = useState<T[] | []>([])
  const { setNewSearchParams } = useSearchParamsHelper({})
  const [currentPage, setCurrentPage] = useState(1)
  const [allPages, setAllPages] = useState([1])

  useEffect(() => {
    if (loadMore && data) {
      setTableData((prev) => {
        return [...prev, ...data.items]
      })
    } else if (data) {
      setTableData(data.items)
    } else if (!data) {
      setTableData([])
    }
    setLoadMore(false)
  }, [data, loadMore])

  const nextPage = useMemo(() => {
    return data
      ? data.limit * data.page >= data.total
        ? null
        : data.page + 1
      : null
  }, [data])

  const totalPages = useMemo(() => {
    return data ? Math.ceil(data.total / data.limit) : null
  }, [data])

  useEffect(() => {
    const all = []
    if (totalPages)
      for (let i = 1; i <= totalPages; i++) {
        all.push(i)
      }
    setAllPages(all)
  }, [totalPages])

  useEffect(() => {
    if (loadMore && data) {
      setNewSearchParams([
        { searchParamName: 'page', value: `${nextPage}` },
        { searchParamName: 'limit', value: `${data.limit}` },
      ])
    }
  }, [loadMore, data, nextPage, setNewSearchParams])

  const setNewPage = (newPage: number) => {
    if (data) {
      setCurrentPage(newPage)
      setNewSearchParams([
        { searchParamName: 'page', value: `${newPage}` },
        { searchParamName: 'limit', value: `${data.limit}` },
      ])
    }
  }

  const tableRef = useRef<HTMLTableElement>(null)

  return (
    <div className="app-table-wrapper">
      {isStickyHeader && <div className={'sticky-table-bg'} />}
      <table
        className={`app-table${lastColAlignLeft ? ' last-col-align-left' : ''}${
          tableAsTasks ? ' custom-view-tasks' : ''
        }${tableAsEntity ? ' custom-view-entity' : ''}`}
        ref={tableRef}
      >
        {cellWidth.length > 0 && (
          <colgroup>
            {cellWidth.map((cellWidth, index) => {
              return (
                <col
                  width={cellWidth}
                  key={`cellWidth-${cellWidth}-${index}`}
                />
              )
            })}
          </colgroup>
        )}
        <thead className={`${isStickyHeader ? 'sticky' : ''}`}>
          <tr>
            {headerTitles &&
              headerTitles.map((th, thIndex) => {
                return (
                  <th scope="col" key={`tableTh-${thIndex}-${th}`}>
                    <AppTranslation label={th} />
                  </th>
                )
              })}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((item, itemIndex) => {
            return (
              <TableRow
                index={itemIndex}
                key={`tr-${itemIndex}`}
                item={item}
                cellCount={
                  headerTitles ? headerTitles.length : tableDataSelectors.length
                }
                tableAsEntity={tableAsEntity}
                tableDataSelectors={tableDataSelectors}
                subTableComponentRender={subTableComponentRender}
              />
            )
          })}
        </tbody>
        <tfoot />
      </table>
      {/*data && loadType === AppTableLoadType.nextPrevBtn && (
        <div className={'table-pagination-block'}>
          {data.page > 1 && (
            <AppButton
              title={'<'}
              color={AppButtonColor.lightBlue}
              size={AppButtonSize.base}
              onClick={() => setNewPage(data.page - 1)}
            />
          )}

          {allPages.length > 1 &&
            allPages.map((page, index) => {
              return (
                <AppButton
                  title={page.toString()}
                  color={
                    page == currentPage
                      ? AppButtonColor.aquaBlue
                      : AppButtonColor.lightBlue
                  }
                  size={AppButtonSize.base}
                  onClick={() => setNewPage(page)}
                />
              )
            })}

          {nextPage && (
            <AppButton
              title={'>'}
              color={AppButtonColor.blue}
              size={AppButtonSize.base}
              onClick={() => setNewPage(nextPage)}
            />
          )}
        </div>
          )*/}
      {data?.limit && (
        <>
          <Pagination
            onChange={(value) => setNewPage(value)}
            total={data?.total}
            pageSize={data?.limit || 20}
            current={data?.page}
            hideOnSinglePage={true}
            showSizeChanger={false}
          />
          {nextPage && loadType === AppTableLoadType.loadMore && (
            <div className="table-pagination-block">
              <AppButton
                title={`Show more (${
                  data!.limit * nextPage! >= data!.total
                    ? data!.total - data!.limit * data!.page
                    : data!.limit
                })`}
                onClick={() => setLoadMore(true)}
                color={AppButtonColor.blue}
                size={AppButtonSize.base}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export const AppTable = typedMemo(Table)
