import { Link } from 'react-router-dom'
import { useGetTasksQuery } from 'core/api/BaseApiEndpoints/Tasks/TasksApi'

export const EventsCards = (props: { widthItem: number; date: any[] }) => {
  const { data } = useGetTasksQuery({})

  const sideToCut = (event: any) => {
    const dateStart = new Date(event.date_start)
    const dateEnd = new Date(event.date_end)
    const isStart = props?.date[1] <= dateStart && props?.date[2] >= dateStart
    const isEnd = props?.date[1] <= dateEnd && props?.date[2] >= dateEnd
    return isStart
      ? isEnd
        ? 'noCut'
        : 'cutRight'
      : isEnd
      ? 'cutLeft'
      : 'cutBoth'
  }

  const sideToShow = (event: any) => {
    const dateStart = new Date(event.date_start)
    const dateEnd = new Date(event.date_end)
    const isStart = dateStart <= props?.date[2]
    const isEnd = dateEnd >= props?.date[1] && dateEnd <= props?.date[2]
    return isStart && isEnd
  }

  const sideToEnd = (event: any) => {
    const dateNow = new Date()
    const dateEnd = new Date(event.date_end)
    const isEnd = dateEnd < dateNow
    return isEnd
  }

  const daysDuration = (event: any) => {
    console.log('event => ', event)
    return Math.ceil(
      Math.abs(
        new Date(event?.date_end).getTime() -
          new Date(event?.date_start).getTime(),
      ) /
        (1000 * 3600 * 24),
    )
  }
  const cardWidth = (event: any) => {
    return (
      (Math.ceil(
        Math.abs(
          new Date(event?.date_end).getTime() -
            new Date(event?.date_start).getTime(),
        ) /
          (1000 * 3600),
      ) +
        7) *
      (props.widthItem / props.date[3] / 24)
    )
  }

  const fromStart = (event: any) => {
    return (
      (Math.ceil(
        Math.abs(new Date(event?.date_start).getTime() - props?.date[1]) /
          (1000 * 3600),
      ) +
        7) *
      (props.widthItem / props.date[3] / 24)
    )
  }

  const fromStartMinus = (event: any) => {
    return (
      (Math.ceil(
        Math.abs(new Date(event?.date_start).getTime() - props?.date[1]) /
          (1000 * 3600 * 24),
      ) -
        1) *
      (props.widthItem / props.date[3])
    )
  }

  return (
    <div className="events">
      <div className="events-list tasks-list">
        {data?.items
          ?.filter((event: any) => sideToShow(event))
          .map((event: any, index: number) => {
            return (
              <Link
                key={index}
                className={`calendar-event ${
                  sideToShow(event) ? 'show' : ''
                } ${sideToCut(event)} ${
                  cardWidth(event) / 50 < 3 ? 'small' : ''
                }
                ${
                  sideToEnd(event) ? 'is-end' : ''
                }`}
                style={{
                  width: cardWidth(event),
                  top: 96 + index * 55,
                  left:
                    sideToCut(event) === 'cutBoth' ||
                    sideToCut(event) === 'cutLeft'
                      ? -fromStartMinus(event)
                      : fromStart(event)+10,
                }}
                to={`/tasks/${event.id}`}
              >
                <div
                  className={'eventLeft'}
                  style={{
                    paddingLeft:
                      sideToCut(event) === 'cutBoth' ||
                      sideToCut(event) === 'cutLeft'
                        ? fromStartMinus(event)
                        : '',
                  }}
                >
                  <div
                    className={`'rem_duration ${
                      cardWidth(event) / 50 < 2 && 'rem_small'
                    }`}
                  >
                    {daysDuration(event) + ' д.'}
                  </div>
                </div>
                {cardWidth(event) / 50 > 3 && (
                  <div className={'rem_performer'}>{event.performer}</div>
                )}
              </Link>
            )
          })}
      </div>
    </div>
  )
}
