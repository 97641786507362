import { SectionWidget } from '../SectionWidget/SectionWidget'
import {
  IEntitiesObject,
  IReferenceValuesSimple,
} from '../../../core/api/dto/EntityDto'
import React, { FC, useMemo, useState } from 'react'
import { AppPopup, popupType } from '../AppPopup/AppPopup'
import { Link } from 'react-router-dom'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import { HeaderSearchInput } from '../HeaderSearchInput/HeaderSearchInput'
import { useSearchParamsHelper } from 'helpers/setSearchParam'

type PropsType = {
  data: IEntitiesObject
}

export const EntityRelatedWidget: FC<PropsType> = ({ data }) => {
  const [selectedKey, setSelectedKey] = useState<string | null>(null)
  const { searchParamsString } = useSearchParamsHelper({})

  const relatedKeys = useMemo(() => {
    return Object.keys(data)
  }, [data])

  return (
    <div className="entity-related-widget">
      <div className="col-12">
        <div className="form-block">
          <div className="block-section">
            <SectionWidget title={'entity_related__popup_title'}>
              <>
                {relatedKeys?.map((key) => {
                  const entityItem: IReferenceValuesSimple[] = data[key]
                  return (
                    <div
                      key={`item-${key}`}
                      className="flex align-items-center cursor-pointer"
                      onClick={() => setSelectedKey(key)}
                    >
                      <span>{key}</span>
                      <span className="m-l-auto">{entityItem.length}</span>
                    </div>
                  )
                })}
              </>
            </SectionWidget>
          </div>
        </div>
      </div>
      {selectedKey ? (
        <AppPopup
          title={selectedKey}
          onClose={() => setSelectedKey(null)}
          type={popupType.info}
        >
          <div className="entity-related-widget-popup">
              <HeaderSearchInput
                searchParam={'search'}
                placeholder={'general__txt_search'}
              />
            {data[selectedKey]?.map((item) => {
              return (
                item.title.toUpperCase().includes(decodeURI(searchParamsString.slice(7)).toUpperCase()) && (
                    <Link
                      key={item.id}
                      to={`/${AllRoutes.entity.path}/${selectedKey}/${item.id}`}
                      onClick={() => setSelectedKey(null)}
                      className="entity-related-widget-popup__link"
                    >
                      {item.title}
                    </Link>
                )
              )
            })}
          </div>
        </AppPopup>
      ) : null}
    </div>
  )
}
