import React from 'react'
import { ITaskPriority } from '../../../core/api/dto/TasksDto'
import {
  AppTranslation,
  translationLabelTape,
} from '../AppTranslation/AppTranslation'
import { EntityForeignKeyFormViewExtractor } from '../EntityForeignKeyFormViewExtractor/EntityForeignKeyFormViewExtractor'

interface ITablePriority {
  priority: ITaskPriority
  title?: translationLabelTape
  importanceValue?: never
  dataType?: never
}

interface ITableImportance {
  priority?: never
  title?: translationLabelTape
  importanceValue: number
  dataType: string
}

type propsType = ITablePriority | ITableImportance

type IColorByPriority = {
  [key in ITaskPriority]: string
}

export const colorByPriority: IColorByPriority = {
  HIGH: 'color-Task-label-background-red',
  MEDIUM: 'color-Task-label-background-yellow',
  LOW: 'color-Task-label-background-green',
}

export const PriorityWidget = ({
  priority,
  title = 'task_priority__txt_label',
  importanceValue,
  dataType,
}: propsType) => {
  return (
    <div className="priority-widget">
      <span className="title">
        <AppTranslation label={title} />
      </span>
      {priority && (
        <span className={`value ${colorByPriority[priority]}`}>
          <AppTranslation label={`task_priority_${priority}`} />
        </span>
      )}
      {importanceValue && (
        <span className={`value`}>
          <EntityForeignKeyFormViewExtractor
            value={importanceValue}
            dataType={dataType}
          />
        </span>
      )}
    </div>
  )
}
