import React, { PropsWithChildren } from 'react'
import { useSearchParamsHelper } from '../../../helpers/setSearchParam'
import { NavLink } from 'react-router-dom'
import { AppTranslation } from '../AppTranslation/AppTranslation'

export enum PageNavTabsType {
  searchParams,
  navLink,
}

interface IBaseProps<T, TKey> {
  propToShow: TKey
  valuePropName: TKey
  data: T[]
}

interface IAsSearchParam<T, TKey> extends IBaseProps<T, TKey> {
  searchParam: string
  type: PageNavTabsType.searchParams
}

interface IAsNavLink<T, TKey> extends IBaseProps<T, TKey> {
  searchParam?: never
  type: PageNavTabsType.navLink
}

type IPageNavTabs<T, TKey> = IAsSearchParam<T, TKey> | IAsNavLink<T, TKey>

const typedMemo: <T>(c: T) => T = React.memo

const AsSearchParam = <T, TKey extends keyof T>({
  data,
  searchParam,
  valuePropName,
  propToShow,
}: IAsSearchParam<T, TKey>) => {
  const { setNewSearchParams, getCurrentSearchParamValue } =
    useSearchParamsHelper({})

  const currentSearchParamValue = getCurrentSearchParamValue(
    searchParam,
  ) as unknown as T[TKey]

  const changeHandler = (item: T) => {
    setNewSearchParams(
      [{ searchParamName: searchParam, value: `${item[valuePropName]}` }],
      ['page', 'limit'],
    )
  }

  return (
    <React.Fragment>
      {data.map((item, index) => {
        return (
          <h2
            key={`${searchParam}-${index}`}
            className={`page-nav-tab-item ${
              !currentSearchParamValue && index === 0
                ? 'active'
                : currentSearchParamValue === item[valuePropName]
                ? 'active'
                : ''
            }`}
            onClick={() => changeHandler(item)}
          >
            <AppTranslation label={`${item[propToShow]}`} />
          </h2>
        )
      })}
    </React.Fragment>
  )
}
const TypedAsSearchParam = typedMemo(AsSearchParam)

const AsNavLink = <T, TKey extends keyof T>({
  data,
  propToShow,
  valuePropName,
}: IAsNavLink<T, TKey>) => {
  return (
    <React.Fragment>
      {data.map((item, index) => {
        return (
          <NavLink
            to={`${item[valuePropName]}`}
            key={`${item[valuePropName]}-${index}`}
            className={({ isActive }) =>
              `page-nav-tab-item h2 ${isActive ? 'active' : ''}`
            }
          >
            <AppTranslation label={`${item[propToShow]}`} />
          </NavLink>
        )
      })}
    </React.Fragment>
  )
}
const TypedAsNavLink = typedMemo(AsNavLink)

const PageNavTabsComponent = <T, TKey extends keyof T>(
  props: PropsWithChildren<IPageNavTabs<T, TKey>>,
) => {
  return (
    <div className="page-nav-tabs">
      {props.type === PageNavTabsType.searchParams && (
        <TypedAsSearchParam {...props} />
      )}
      {props.type === PageNavTabsType.navLink && <TypedAsNavLink {...props} />}
    </div>
  )
}

export const PageNavTabs = typedMemo(PageNavTabsComponent)
