import React, { useMemo } from 'react'
import { IReferenceValuesTypeObject } from '../../../core/api/dto/EntityDto'
import { useGetReferenceValuesForeignQuery } from '../../../core/api/BaseApiEndpoints/Entity/EntityApi'
import { ItemRepresentByDataType } from '../../../pages/AdminPage/Entity/ItemRepresentByDataType'

interface IEntityForeignKeyFormViewExtractor {
  dataType: string
  value?: number | string
}

export const EntityForeignKeyFormViewExtractor = ({
  dataType,
  value,
}: IEntityForeignKeyFormViewExtractor) => {
  const { data: referenceValuesForeign } = useGetReferenceValuesForeignQuery(
    {
      ForeignKey: dataType,
    },
    { skip: !value },
  )

  const currentValue = useMemo(() => {
    if (value && referenceValuesForeign) {
      const result = referenceValuesForeign.find((item) => item.id === value)
      return result ? result : null
    } else if (referenceValuesForeign && !value) {
      return null
    }
  }, [value, referenceValuesForeign])

  return (
    <React.Fragment>
      <ItemRepresentByDataType
        itemData={currentValue as unknown as IReferenceValuesTypeObject}
      />
    </React.Fragment>
  )
}
