import React from 'react'

const IconPeople: React.FC = () => {
  return (
    <svg width="16" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.715 4.333a1.992 1.992 0 0 0 1.994-2 1.997 1.997 0 1 0-3.993 0c0 1.107.893 2 2 2zm-5.333 0a1.992 1.992 0 0 0 1.993-2 1.997 1.997 0 1 0-3.993 0c0 1.107.893 2 2 2zm0 1.334C3.829 5.667.715 6.447.715 8v1.667h9.334V8c0-1.553-3.114-2.333-4.667-2.333zm5.333 0c-.193 0-.413.013-.646.033.773.56 1.313 1.313 1.313 2.3v1.667h4V8c0-1.553-3.113-2.333-4.667-2.333z" />
    </svg>
  )
}

export default IconPeople
