import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import {
  useCreateEntityMutation,
  useCreateRelatedEntityMutation,
  useEntityUploadDocumentsMutation,
  useEntityUploadMediaMutation,
  useGetEntityByIdQuery,
  useGetEntitySchemaQuery,
  useUpdateEntityMutation,
} from '../../../core/api/BaseApiEndpoints/Entity/EntityApi'
import { Breadcrumbs } from '../../../common/components/Breadcrumbs/Breadcrumbs'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import {
  IFormRowData,
  inputControllerType,
  inputTypeByFieldType,
  IRowItem,
  PageFormController,
} from '../../../common/components/PageFormController/PageFormController'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import { IFormErrorDto } from '../../../core/api/BaseApi'
import { useGoBack } from '../../../common/components/HeaderBackButton/HeaderBackButton'
import {
  EntityDataType,
  IReferenceValuesTypeObject,
} from '../../../core/api/dto/EntityDto'
import {
  AppNotification,
  NotificationType,
} from '../../../common/components/Notification/Notification'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import {
  useGetProjectByIdQuery,
  useUpdateProjectMutation,
} from 'core/api/BaseApiEndpoints/Projects/ProjectsApi'

export const EntityElementCreateUpdate = () => {
  const navigation = useNavigate()
  const { entitySymbolKey, entityId, relatedEntitySymbolKey, projectId } =
    useParams()
  console.log('projectId', projectId)
  console.log('relatedEntitySymbolKey', relatedEntitySymbolKey)

  const { data: entitySchema } = useGetEntitySchemaQuery({
    entitySymbolKey: relatedEntitySymbolKey
      ? relatedEntitySymbolKey
      : entitySymbolKey!,
  })
  const [createEntity] = useCreateEntityMutation()
  const [updateEntity] = useUpdateEntityMutation()
  const [createRelatedEntity] = useCreateRelatedEntityMutation()
  const [uploadMedia] = useEntityUploadMediaMutation()
  const [uploadDocuments] = useEntityUploadDocumentsMutation()

  const isCreatePage = useMemo(
    () => !entityId || !!relatedEntitySymbolKey,
    [entityId, relatedEntitySymbolKey],
  )
  const { data: detailsData } = useGetEntityByIdQuery(
    { entitySymbolKey: entitySymbolKey!, entityId: entityId! },
    {
      skip: relatedEntitySymbolKey ? false : isCreatePage,
    },
  )
  const { data: projectData } = useGetProjectByIdQuery(
    { projectId: Number(projectId) },
    {
      skip: relatedEntitySymbolKey ? false : isCreatePage,
    },
  )
  const [isPublished, setIsPublished] = useState(false)
  useEffect(() => {
    // @ts-ignore
    detailsData?.is_published && setIsPublished(detailsData?.is_published)
  }, [detailsData])

  const [willSubmit, setWillSubmit] = useState(false)
  const [formError, setFormError] = useState<IFormErrorDto | null>(null)
  const [isFormDirty, setIsFormDirt] = useState(false)
  const [isValidForm, setIsValidForm] = useState(!isCreatePage && isPublished)
  const [updateProject] = useUpdateProjectMutation()

  const { goBackHandler } = useGoBack()
  const backBtnHandler = useCallback(() => {
    if (!isFormDirty) {
      goBackHandler()
    } else alert('Есть несохраненные данные')
  }, [isFormDirty, goBackHandler])

  const projectsBreadcrumbs = useMemo(() => {
    return relatedEntitySymbolKey
      ? [
          {
            name: {
              label: 'entity_elements_listing_page__txt_title',
              options: { entityName: entitySymbolKey },
            },
            path: `/${AllRoutes.entity.path}/${entitySymbolKey}`,
          },
          {
            name: entityId!,
            path: `../../`,
          },
          {
            name: {
              label: 'entity_related_element_create__txt_title',
              options: { relatedEntitySymbolKey, entityName: entitySymbolKey },
            },
          },
        ]
      : [
          {
            name: {
              label: 'entity_elements_listing_page__txt_title',
              options: { entityName: entitySymbolKey },
            },
            path: `/${AllRoutes.entity.path}/${entitySymbolKey}`,
          },
          {
            name: {
              label: isCreatePage
                ? 'entity_element_create__txt_title'
                : 'entity_element_edit__txt_title',
              options: isCreatePage
                ? { entityName: entitySymbolKey }
                : { entityName: entitySymbolKey, entityId },
            },
          },
        ]
  }, [entitySymbolKey, isCreatePage, entityId])

  const initFormState = useMemo(() => {
    if (!isCreatePage && entitySchema) {
      if (!detailsData) {
        return null
      }
      return detailsData
    } else if (isCreatePage && projectId && relatedEntitySymbolKey) {
      if (!projectData) {
        return null
      }
      return projectData
    } else if (isCreatePage && entitySchema && relatedEntitySymbolKey) {
      if (!detailsData) {
        return null
      }
      return detailsData
    } else if (entitySchema) {
      const newState: { [key: string]: EntityDataType } = {}
      Object.keys(entitySchema.fields).forEach((key) => {
        if (!entitySchema.fields[key].is_hidden) {
          newState[key] =
            entitySchema.fields[key].field_type === 'ManyToManyField' ? [] : ''
        }
      })
      return newState
    } else {
      return null
    }
  }, [entitySchema, detailsData, isCreatePage, projectData])

  const elementFormConfig = useMemo<IFormRowData[] | null>(() => {
    if (entitySchema && Object.keys(entitySchema.fields).length > 0) {
      const {
        event_start_date,
        event_end_date,
        title,
        description,
        responsible,
        ...other
      } = entitySchema?.fields
      const baseFieldItems: IRowItem[] = []
      if (event_start_date) {
        if (title) {
          baseFieldItems.push({
            type: inputControllerType.input,
            name: 'title',
            label: title.label,
            colClassName: 'col-12',
            required: 'general__txt_required_field',
          })
        }
        baseFieldItems.push({
          type: inputControllerType.datePicker,
          name: 'event_start_date',
          label: event_start_date.label,
          colClassName: 'col-6',
          required: 'general__txt_required_field',
        })
      }
      if (event_end_date) {
        baseFieldItems.push({
          type: inputControllerType.datePicker,
          name: 'event_end_date',
          label: event_end_date.label,
          colClassName: 'col-6',
          required: 'general__txt_required_field',
        })
      }

      if (description) {
        baseFieldItems.push({
          type: inputControllerType.textarea,
          name: 'description',
          label: description.label,
          colClassName: 'col-12',
          required: 'general__txt_required_field',
          rows: 7,
        })
      }
      if (responsible) {
        baseFieldItems.push({
          type: inputTypeByFieldType[
            responsible.field_type
          ] as unknown as inputControllerType.infoBlockSelectExtractor,
          dataType: 'responsible',
          name: 'responsible',
          label: responsible.label,
          colClassName: 'col-12',
          required: 'general__txt_required_field',
        })
      }
      {
        baseFieldItems.push()
      }
      const rowItems: IRowItem[] = Object.keys(other).map((key) => {
        const { label, field_type, is_required } = entitySchema.fields[key]
        if (entitySchema.fields[key].is_hidden) {
          return {
            type: inputControllerType.hidden,
          }
        }
        if (field_type !== 'ForeignKey' && field_type !== 'ManyToManyField') {
          return {
            type: inputTypeByFieldType[field_type] as unknown as  // TODO change types to auto
              | inputControllerType.underConstruction
              | inputControllerType.input
              | inputControllerType.datePicker
              | inputControllerType.inputFloat
              | inputControllerType.textarea,
            name: key,
            label,
            colClassName: 'col-3',
            required: is_required ? 'general__txt_required_field' : undefined,
          }
        } else {
          return {
            type: inputTypeByFieldType[
              field_type
            ] as unknown as inputControllerType.infoBlockSelectExtractor,
            dataType: key,
            name: key,
            label,
            colClassName: 'col-3',
            required: is_required ? 'general__txt_required_field' : undefined,
          }
        }
      })
      {
        rowItems.push(
          {
            type: inputControllerType.file,
            name: 'entities_document',
            label: 'Документы',
            colClassName: 'col-3',
          },
          {
            type: inputControllerType.file,
            name: 'entities_media',
            label: 'Медиа',
            colClassName: 'col-3',
          },
        )
      }
      return !rowItems
        ? null
        : [
            {
              title: null,
              rowItems: [
                {
                  type: inputControllerType.baseFields,
                  colClassName: 'col-12',
                  rowItems: baseFieldItems,
                },
              ],
            },
            {
              title: null,
              canBeHide: true,
              rowItems,
            },
          ]
    } else return null
  }, [entitySchema])

  const formDataChangeHandler = useCallback(
    (
      {
        entities_document,
        entities_media,
        is_published,
        ...data
      }: {
        [key: string]: EntityDataType
      },
      isValid: boolean,
    ) => {
      if ((isValid || !isPublished) && (entitySymbolKey || projectId)) {
        const fetchArray: string[] = []
        const entityDocument = entities_document as FileList
        const entityMedia = entities_media as FileList
        const published = is_published as unknown as boolean

        if (entityDocument && entityDocument.length > 0) {
          fetchArray.push('isDocumentsDirty')
        }
        if (entityMedia && entityMedia.length > 0) {
          fetchArray.push('isMediaDirty')
        }

        fetchArray.push('published')

        const processArray = async (array: string[], entityId: number) => {
          const result = []
          for (const item of array) {
            switch (item) {
              case 'isDocumentsDirty':
                const formDocData = new FormData()
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < entityDocument.length; i++) {
                  formDocData.append(
                    'file',
                    entityDocument[i],
                    entityDocument[i].name,
                  )
                }
                result.push(
                  await uploadDocuments({
                    entityId: entityId,
                    data: formDocData,
                  }),
                )
                break
              case 'isMediaDirty':
                const formMediaData = new FormData()
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < entityMedia.length; i++) {
                  formMediaData.append(
                    'file',
                    entityMedia[i],
                    entityMedia[i].name,
                  )
                }
                result.push(
                  await uploadMedia({
                    entityId: entityId,
                    data: formMediaData,
                  }),
                )
                break

              default:
                result.push({ is_published: isPublished })
                break
            }
          }
          return result
        }

        const newData: {
          [key: string]: EntityDataType
        } = {}
        Object.keys(data).forEach((key) => {
          if (!!data[key]) {
            if (Array.isArray(data[key])) {
              const tempData: IReferenceValuesTypeObject[] | number[] = data[
                key
              ] as unknown as IReferenceValuesTypeObject[] | number[]
              newData[key] = tempData.map((item) =>
                typeof item === 'number' ? item : item.id,
              )
            } else {
              if (
                typeof data[key] !== 'string' &&
                typeof data[key] !== 'number' &&
                typeof data[key] === 'object' &&
                data[key] instanceof Object &&
                // @ts-ignore
                'id' in data[key]
              ) {
                // @ts-ignore
                newData[key] = data[key].id
              } else {
                newData[key] = data[key]
              }
            }
          }
        })

        newData['is_published'] = isPublished
        if (projectId) newData['projects'] = [projectId]

        !entitySymbolKey
          ? projectId &&
            relatedEntitySymbolKey &&
            createEntity({
              entitySymbolKey: relatedEntitySymbolKey,
              body: newData,
            })
              .unwrap()
              .then((item) => {
                if (fetchArray.length > 0) {
                  processArray(fetchArray, Number(item.pk))
                    .then((result) => {
                      const isAnyError =
                        result.findIndex(
                          (item) => item && 'error' in item && item.error,
                        ) >= 0

                      if (!isAnyError) {
                        setIsFormDirt(false)
                        AppNotification({
                          msg: [t('general__msg_saved')],
                          type: NotificationType.success,
                        })
                      }
                    })
                    .catch((error) => setFormError(error))
                } else {
                  AppNotification({
                    msg: [t('general__msg_saved')],
                    type: NotificationType.success,
                  })
                  navigation(`../${item.pk}`)
                }

                if (item.pk) {
                  if (!relatedEntitySymbolKey) {
                    navigation(`../${item.pk}`)
                  } else {
                    //let projectEntities = projectData?.entities?.map((entity:any)=> entity.id)
                    //projectEntities[projectEntities.length] = item.pk
                    if (projectId) {
                      updateProject({
                        body: { entities: [Number(item.pk)]},
                        projectId: projectId,
                      })
                    }
                  }
                }
              })
          : isCreatePage
          ? createEntity({
              entitySymbolKey: relatedEntitySymbolKey
                ? relatedEntitySymbolKey
                : entitySymbolKey,
              body: newData,
            })
              .unwrap()
              .then((item) => {
                if (fetchArray.length > 0) {
                  processArray(fetchArray, Number(item.pk))
                    .then((result) => {
                      const isAnyError =
                        result.findIndex(
                          (item) => item && 'error' in item && item.error,
                        ) >= 0

                      if (!isAnyError) {
                        setIsFormDirt(false)
                        AppNotification({
                          msg: [t('general__msg_saved')],
                          type: NotificationType.success,
                        })
                      }
                    })
                    .catch((error) => setFormError(error))
                } else {
                  AppNotification({
                    msg: [t('general__msg_saved')],
                    type: NotificationType.success,
                  })
                  navigation(`../${item.pk}`)
                }
                //let projectEntities = projectData?.entities?.map((entity:any)=> entity.id)
                //projectEntities[projectEntities.length] = item.pk
                if (projectId) {
                  console.log('test 1', {
                    entitySymbolKey,
                    entityId: entityId!,
                    relatedEntityId: `${item.pk}`,
                  })
                  //updateProject({
                  //  body: { entities: [`${item.pk}`]},
                  //  projectId: projectId,
                  //})
                }
                if (item.pk) {
                  if (!relatedEntitySymbolKey) {
                    navigation(`../${item.pk}`)
                  } else {
                    console.log('test 1', {
                      entitySymbolKey,
                      entityId: entityId!,
                      relatedEntityId: `${item.pk}`,
                    })
                    createRelatedEntity({
                      entitySymbolKey: relatedEntitySymbolKey,
                      entityId: entityId!,
                      relatedEntityId: `${item.pk}`,
                    })
                      .unwrap()
                      .then((relatedItem) => {
                        createRelatedEntity({
                          entitySymbolKey,
                          entityId: `${item.pk}`,
                          relatedEntityId: entityId!,
                        })
                          .unwrap()
                          .then(() => {
                            if (relatedItem.pk) {
                              navigation(
                                `../../../../${relatedEntitySymbolKey}/${item.pk}`,
                              )
                            }
                          })
                      })
                  }
                }
              })
          : updateEntity({
              entitySymbolKey,
              body: newData,
              entityId: entityId!,
            })
              .unwrap()
              .then((item) => {
                if (fetchArray.length > 0) {
                  processArray(fetchArray, Number(item.pk))
                    .then((result) => {
                      const isAnyError =
                        result.findIndex(
                          (item) => item && 'error' in item && item.error,
                        ) >= 0

                      if (!isAnyError) {
                        setIsFormDirt(false)
                        AppNotification({
                          msg: [t('general__msg_saved')],
                          type: NotificationType.success,
                        })
                      }
                    })
                    .catch((error) => setFormError(error))
                } else {
                  AppNotification({
                    msg: [t('general__msg_saved')],
                    type: NotificationType.success,
                  })
                  navigation(`/entity/${entitySymbolKey}/${item.pk}`)
                }
                if (item.pk) {
                  if (!relatedEntitySymbolKey) {
                    navigation(`/entity/${entitySymbolKey}/${item.pk}`)
                  } else {
                    createRelatedEntity({
                      entitySymbolKey,
                      entityId: entityId!,
                      relatedEntityId: `${item.pk}`,
                    })
                      .unwrap()
                      .then((relatedItem) => {
                        if (relatedItem.pk) {
                          navigation(
                            `../../../../${relatedEntitySymbolKey}/${item.pk}`,
                          )
                        }
                      })
                  }
                }
              })
              .catch((error) => setFormError(error))
      }

      setWillSubmit(false)
    },
    [
      setWillSubmit,
      entitySymbolKey,
      goBackHandler,
      entityId,
      createEntity,
      updateEntity,
      isCreatePage,
      isPublished,
    ],
  )

  let fields: any =
    !isCreatePage && detailsData?.is_published
      ? ['title', 'description', 'start_date', 'end_date', 'responsible']
      : []
  const isValid = useCallback(
    (isValid: any) => {
      if (fields.length == 0) fields.push(Object.keys(isValid).join())
      else {
        let currentField = fields.findIndex(
          (field: any) => Object.keys(isValid).join() == field,
        )
        if (currentField == -1) fields.push(Object.keys(isValid).join())
        else if (Object.values(isValid).join() == '') {
          fields.splice(currentField, 1)
        }
      }
      console.log('', entitySchema?.fields)
      setIsValidForm(
        fields.includes('event_start_date') ||
          detailsData?.event_start_date ||
          fields.includes('event_end_date') ||
          detailsData?.event_end_date ||
          fields.includes('responsible') ||
          detailsData?.responsible ||
          fields.includes('title') ||
          detailsData?.title ||
          fields.includes('description') ||
          detailsData?.description,
      )
    },
    [isPublished],
  )

  const dirtyFormCallBack = useCallback(
    (value: boolean) => {
      setIsFormDirt(value)
    },
    [setIsFormDirt],
  )

  return (
    <React.Fragment>
      <div className={'back-office-header'}>
        <Breadcrumbs breadcrumbs={projectsBreadcrumbs} />
        <h1>
          <AppTranslation
            label={
              relatedEntitySymbolKey
                ? AllRoutes.entityElementCreateRelated.name
                : isCreatePage
                ? AllRoutes.entityElementCreate.name
                : detailsData
                ? detailsData?.title.toString()
                : AllRoutes.entityElementEdit.name
            }
            options={
              relatedEntitySymbolKey
                ? { relatedEntitySymbolKey, entityName: entitySymbolKey }
                : isCreatePage
                ? { entityName: entitySymbolKey }
                : { entityName: entitySymbolKey, entityId }
            }
          />
        </h1>
        <p className="base-field-desc">
          * - звездочкой отмечены обязательные для заполнения поля
        </p>
      </div>
      {elementFormConfig && (
        <>
          <PageFormController
            formData={elementFormConfig}
            willSubmit={willSubmit}
            formDataChangeHandler={formDataChangeHandler}
            fetchError={formError}
            initFormState={initFormState}
            dirtyFormCallBack={dirtyFormCallBack}
            asBlocks={false}
            isValid={isValid}
          />
          <div className={'col-4 btn-block'} style={{ marginTop: '30px' }}>
            <AppButton
              onClick={() => {
                setIsPublished(true)
                setWillSubmit(true)
              }}
              color={AppButtonColor.aquaBlue}
              size={AppButtonSize.base}
              title={'general__button_save'}
              disabled={!isValidForm}
            />
            <div style={{ width: '20px' }} />
            {isPublished == false && (
              <AppButton
                onClick={() => {
                  setIsPublished(false)
                  setWillSubmit(true)
                }}
                color={AppButtonColor.lightBlue}
                size={AppButtonSize.base}
                title={'Сохранить как черновик'}
              />
            )}
            <div style={{ width: '20px' }} />
            <AppButton
              onClick={backBtnHandler}
              color={AppButtonColor.lightRed}
              size={AppButtonSize.base}
              title={'general__button_cancel'}
            />
          </div>
        </>
      )}
    </React.Fragment>
  )
}
