import { IEntityItemsDto } from 'core/api/dto/EntityDto'
import { api } from '../../BaseApi'
import {
  IElementCreateDto,
  IElementFieldTypes,
  IInfoBlockElements,
} from '../../dto/ElementDto'
import { ITask } from '../../dto/TasksDto'

export const projectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFieldTypesInProjectByInfoBlockSymbolLink: builder.query<
      IElementFieldTypes,
      { infoBlockSymbolLink: string }
    >({
      query: ({ infoBlockSymbolLink }) => ({
        url: `/projects/${infoBlockSymbolLink}/types/`,
      }),
      providesTags: ['InfoBlocks', 'Projects'],
    }),
    createNewElementByInfoBlockSymbolLinkName: builder.mutation<
      void,
      {
        infoBlockSymbolLink: string
        body: IElementCreateDto
      }
    >({
      query: ({ infoBlockSymbolLink, body }) => ({
        url: `/projects/${infoBlockSymbolLink}/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InfoBlocks', 'Projects'],
    }),
    getElementsByInfoBlockSymbolLinkName: builder.query<
      IInfoBlockElements,
      {
        infoBlockSymbolLink: string
      }
    >({
      query: ({ infoBlockSymbolLink }) => ({
        url: `/info-blocks/${infoBlockSymbolLink}/`,
      }),
      providesTags: ['InfoBlocks', 'Projects'],
    }),
    getElementDetails: builder.query<
      IInfoBlockElements,
      {
        infoBlockSymbolLink: string
        elementId: string
      }
    >({
      query: ({ infoBlockSymbolLink, elementId }) => ({
        url: `/projects/${infoBlockSymbolLink}/${elementId}`,
      }),
      providesTags: ['InfoBlocks', 'Projects'],
    }),
    getProjectMembersById: builder.query<
      { pk: number; title: string }[],
      { entityId: string }
    >({
      query: ({ entityId }) => ({
        url: `/project/${entityId}/members/`,
      }),
      providesTags: ['Projects'],
    }),
    getProjectItems: builder.query<
      IEntityItemsDto,
      { searchParamsString?: string | void | null }
    >({
      query: ({ searchParamsString }) => ({
        url: `/project/${searchParamsString ? `?${searchParamsString}` : ''}`,
      }),
      providesTags: ['Projects'],
    }),
    getProjectById: builder.query<any, { projectId: number }>({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/`
      }),
      providesTags: ['Projects'],
    }),
    getProjectByIdHistory: builder.query<any, { projectId: number }>({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/history/`,
      }),
      providesTags: ['Projects'],
    }),
    updateProject: builder.mutation<
      any,
      {
        body: any
        projectId: string
      }
    >({
      query: ({ projectId, body }) => ({
        url: `/project/${projectId}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Projects'],
    }),
    createProject: builder.mutation<any, any>({
      query: (data) => ({
        url: `/project/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Projects'],
    }),
    delProject: builder.mutation<
      any,
      {
        projectId: string
      }
    >({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Projects'],
    }),
    projectUploadDocuments: builder.mutation<
      any,
      { data: FormData; projectId: number }
    >({
      query: ({ data, projectId }) => ({
        url: `/project/${projectId}/upload/docs/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Projects'],
    }),
    projectUploadMedia: builder.mutation<
      any,
      { data: FormData; projectId: number }
    >({
      query: ({ data, projectId }) => ({
        url: `/project/${projectId}/upload/images/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Projects'],
    }),
  }),
})

export const {
  useGetFieldTypesInProjectByInfoBlockSymbolLinkQuery,
  useCreateNewElementByInfoBlockSymbolLinkNameMutation,
  useGetElementsByInfoBlockSymbolLinkNameQuery,
  useGetElementDetailsQuery,
  useGetProjectMembersByIdQuery,
  useGetProjectItemsQuery,
  useGetProjectByIdQuery,
  useGetProjectByIdHistoryQuery,
  useUpdateProjectMutation,
  useCreateProjectMutation,
  useDelProjectMutation,
  useProjectUploadDocumentsMutation,
  useProjectUploadMediaMutation,
} = projectsApi
