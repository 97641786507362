import React, {
  CSSProperties,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { AppDropdown } from './AppDropdown'
import { useSearchParams } from 'react-router-dom'

export interface IAppFilterDropdown<T, TKey> {
  searchParam: string
  propToShowInList: TKey
  onChange?: any
  resetValueHandler?: any
  valuePropName: TKey
  placeHolder?: string
  label?: string
  data: T[]
  showInListRepresent?: (item: T) => JSX.Element
  customInput?: (value: T | null) => JSX.Element | null
  style?: CSSProperties | undefined
  withFilter?: boolean
  withoutSearch?: boolean
  valueRows?: number
  value?: any
}

export const AppFilterDropdown = <T, TKey extends keyof T>({
  searchParam,
  data,
  propToShowInList,
  valuePropName,
  label,
  placeHolder,
  onChange,
  resetValueHandler,
  showInListRepresent,
  customInput,
  style,
  withFilter,
  withoutSearch,
  valueRows,
  value,
}: PropsWithChildren<IAppFilterDropdown<T, TKey>>) => {

  const [searchParams, setSearchParams] = useSearchParams()
  const [currentFilterValue, setCurrentFilterValue] = useState<T | null>(null)
  const currentSearchParamValue = searchParams.get(
    searchParam,
  ) as unknown as T[TKey]

  useEffect(() => {
    if (resetValueHandler) resetFilterHandler()
  }, [])

  useEffect(() => {
    if (!!currentSearchParamValue) {
      const current = data.find((item) => {
        return `${item[valuePropName]}` === `${currentSearchParamValue}`
      })
      setCurrentFilterValue(!!current ? current : null)
    } else {
      setCurrentFilterValue(null)
    }
  }, [currentSearchParamValue, data, valuePropName])

  const changeHandler = useCallback(
    (item) => {
      const isParameterEntry = !!searchParams.get(searchParam)
      if (!isParameterEntry && !withoutSearch) {
        searchParams.append(searchParam, item[valuePropName])
      } else if (!withoutSearch && item){
        searchParams.set(searchParam, item[valuePropName])
      } else {
        resetFilterHandler()
      }
      setSearchParams(searchParams.toString())
      const result = []
      result.push(item)
      onChange && onChange(result)
      console.log('item',item)
      console.log('valuePropName',valuePropName)
    },
    [searchParams, setSearchParams, searchParam, valuePropName],
  )

  const resetFilterHandler = useCallback(() => {
    searchParams.delete(searchParam)
    setSearchParams(searchParams.toString())
    resetValueHandler()
  }, [searchParams, setSearchParams, searchParam])

  return (
    <AppDropdown
      data={data}
      label={label}
      propToShowInList={propToShowInList}
      value={value}
      placeholder={
        placeHolder ? placeHolder : 'dropdown_filter__txt_placeholder'
      }
      resetValueHandler={resetFilterHandler}
      onChange={changeHandler}
      showInListRepresent={showInListRepresent}
      customInput={customInput}
      style={style}
      withFilter={withFilter}
      valueRows={valueRows}
      withoutSearch={withoutSearch}
    />
  )
}
