import React, { useState } from 'react'
import { useCreateSourceMutation } from '../../../core/api/BaseApiEndpoints/Sources/SourcesApi'
import { AppPopup } from '../../../common/components/AppPopup/AppPopup'
import {
  AppInput,
  InputMaskType,
} from '../../../common/components/AppInput/AppInput'
import { AppCheckbox } from '../../../common/components/AppCheckbox/AppCheckbox'
import {
  AppButton,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import {
  AppNotification,
  NotificationType,
} from '../../../common/components/Notification/Notification'
import { t } from 'i18next'

interface IAddSourcePopup {
  onClose: () => void
}

export const AddSourcePopup = ({ onClose }: IAddSourcePopup) => {
  const [createSource] = useCreateSourceMutation()
  const [state, setState] = useState({
    name: '',
    url: '',
    recipients: '',
    is_public: false,
  })
  const [errorState, setErrorState] = useState({
    name: false,
    url: false,
    recipients: false,
  })

  const changeHandler = (value: string, key: keyof typeof state) => {
    if (
      (key === 'name' || key === 'url' || key === 'recipients') &&
      errorState[key]
    ) {
      setErrorState((prev) => {
        return { ...prev, [key]: false }
      })
    }

    setState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      }
    })
  }

  const onSaveHandler = () => {
    if (state.name === '' || state.url === '' || state.recipients === '') {
      setErrorState({
        name: !state.name,
        url: !state.url,
        recipients: !state.recipients,
      })
    } else {
      const recipients = state.recipients.split(',').map((item) => item.trim())
      console.log('recipients => ', recipients)
      createSource({
        ...state,
        recipients,
      })
        .unwrap()
        .then(() => {
          AppNotification({
            msg: [t('general__msg_saved')],
            type: NotificationType.success,
          })
          onClose()
        })
      // createSourcesGroup({
      //   ...state,
      //   sources: sourcesObj.arr,
      // })
      //   .unwrap()
      //   .then(() => {
      //     AppNotification({
      //       msg: [t('general__msg_saved')],
      //       type: NotificationType.success,
      //     })
      //     onClose()
      //   })
    }
  }
  return (
    <AppPopup onClose={onClose} popupWidth={600}>
      <div className="row">
        <div className="col-6">
          <AppInput
            onChange={(value) => changeHandler(value, 'name')}
            value={state.name}
            label={'Название'}
            placeholder={'Введите название источника'}
            fullWidth={true}
            error={errorState.name}
          />
        </div>
        <div className="col-6">
          <AppInput
            onChange={(value) => changeHandler(value, 'url')}
            value={state.url}
            label={'URL источника'}
            placeholder={'Введите URL истоника'}
            fullWidth={true}
            error={errorState.url}
          />
        </div>
        <div className="col-8">
          <AppInput
            onChange={(value) => changeHandler(value, 'recipients')}
            value={state.recipients}
            label={'E-mail источников'}
            placeholder={'Введите e-mail источника (через запятую)'}
            fullWidth={true}
            isTextArea={true}
            error={errorState.recipients}
            rows={6}
            inputMaskType={InputMaskType.emailList}
          />
        </div>
        <div className="col-4">
          <AppCheckbox
            value={state.is_public}
            uniqId={'is_public'}
            label={'Общедоступная'}
            onChange={(value) =>
              setState((prevState) => {
                return { ...prevState, is_public: value }
              })
            }
          />
        </div>
        <div className="col-12">
          <AppButton
            size={AppButtonSize.small}
            onClick={onSaveHandler}
            title={'Создать источники'}
          />
        </div>
      </div>
    </AppPopup>
  )
}
