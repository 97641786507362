import React from 'react'
import { ProtectedAvaIco } from '../ProtectedImageIco/ProtectedImageIco'

interface IResponsiblePersonView {
  name?: string | JSX.Element
  shortView?: boolean
  img?: any
  hideIco?: boolean
}

export const ResponsiblePersonView = ({
  name,
  shortView,
  img,
  hideIco,
}: IResponsiblePersonView) => {
  return (
    <div className={'responsible-person-view'}>
      {!hideIco && <ProtectedAvaIco src={img ? img : ''} />}
      {!shortView && name && <b>{name}</b>}
    </div>
  )
}
