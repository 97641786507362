import { DashboardInfo } from './DashboardInfo'
import { DashboardStats } from './DashboardStats'
import { DashboardSummary } from './DashboardSummary'
import { DashboardUsers } from './DashboardUsers'
import { DashboardEvents } from './DashboardEvents'
import React from 'react'

export const Dashboard = () => {
  return (
    <div className="dashboard">
      <div className="dashboard__wrap">
        <div className="dashboard__main">
          <DashboardInfo />
          <DashboardStats />
          <div className="dashboard__row">
            <DashboardSummary />
            <DashboardUsers />
          </div>
        </div>
        <DashboardEvents />
      </div>
    </div>
  )
}