import { useParams } from 'react-router'

export const MonitoringFrame = () => {
  const { monitoringPage } = useParams()

  return (
    <div
      className="monitoring-frame"
      style={{
        flexGrow: 1,
        width: 'calc(100% + 34px)',
        height: 'calc(100% + 52px)',
        margin: '-26px -17px',
      }}
    >
      <iframe
        style={{ width: '100%', height: '100%', border: 'none' }}
        src={`https://lk78.glassen-it.com/${monitoringPage}?login=PSB2@psbank.ru&password=9vjsuJcij&menu=hide`}
      />
    </div>
  )
}
