import React from 'react'

const IconTonality: React.FC = () => {
  return (
    <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g
        clipPath="url(#v)"
        stroke="currentColor"
        strokeWidth="1.143"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M4.004 10.857h8M8.005 15.429a7.429 7.429 0 1 0 0-14.858 7.429 7.429 0 0 0 0 14.858z" />
        <path d="M5.49 6.229a.286.286 0 0 1 0-.572M5.49 6.229a.286.286 0 1 0 0-.572M10.519 6.229a.286.286 0 1 1 0-.572M10.519 6.229a.286.286 0 1 0 0-.572" />
      </g>
      <defs>
        <clipPath id="v">
          <path fill="#fff" transform="translate(.004)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconTonality
