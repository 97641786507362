import React, { useEffect, useState } from 'react'
import { AppDropdown } from './AppDropdown'
import { ITaskStatus } from '../../../core/api/dto/TasksDto'

interface ITaskStatusSelect {
  defaultValue: string | null
  onChange: (value: string) => void
  error?: string | boolean
  valueRows?: number
  label?: string
  disabled?: boolean
}

interface ISelectItem {
  value: ITaskStatus
  name: string
}

export const statusSelectData: ISelectItem[] = [
  { value: ITaskStatus.NEW, name: 'task_status_NEW' },
  { value: ITaskStatus.DOING, name: 'task_status_DOING' },
  { value: ITaskStatus.DONE, name: 'task_status_DONE' },
  { value: ITaskStatus.OVERDUE, name: 'task_status_OVERDUE' },
]

export const TaskStatusSelect = ({
  defaultValue,
  onChange,
  error,
  valueRows,
  label,
  disabled,
}: ITaskStatusSelect) => {
  const [currentValue, setCurrentValue] = useState<ISelectItem | null>(null)

  useEffect(() => {
    if (statusSelectData && defaultValue) {
      const result = statusSelectData.find(
        (item) => item.value === defaultValue,
      )
      setCurrentValue(result ? result : null)
    }
  }, [defaultValue])

  const changeHandler = (value: ISelectItem) => {
    onChange(value.value)
  }

  return (
    <AppDropdown
      data={statusSelectData ? statusSelectData : []}
      label={label ? label : 'general_select_task_status__txt_default_label'}
      propToShowInList={'name'}
      value={currentValue}
      error={error}
      withFilter={true}
      onChange={changeHandler}
      valueRows={valueRows}
      disabled={disabled}
    />
  )
}
