import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import TRANSLATIONS_RU from '../../../core/i18n/translations/ru.json'
import i18n from 'i18next'

export type translationLabelTape = keyof typeof TRANSLATIONS_RU | string

export interface IAppTranslation {
  label: translationLabelTape
  options?: {
    [key: string]: any
  }
}

const Translation = ({ label, options = {} }: IAppTranslation) => {
  const { t } = useTranslation()
  const result = useMemo(() => {
    let translation = t(label, options)
    if (translation.indexOf('&#39;') >= 0) {
      translation = translation.split('&#39;').join('/')
    }
    if (translation.indexOf('&quot;') >= 0) {
      translation = translation.split('&quot;').join('/')
    }
    if (translation.indexOf('&#x2F;') >= 0) {
      translation = translation.split('&#x2F;').join('/')
    }

    return translation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label, options, i18n.language, t])

  const isDev = process.env.REACT_APP_ENV !== 'production'

  return (
    <span
      className={`translation-wrapper ${
        isDev
          ? result === label
            ? 'bg-color-Red-light'
            : 'bg-color-Green-light'
          : ''
      }`}
    >
      {result}
    </span>
  )
}

const typedMemo: <T>(c: T) => T = React.memo
export const AppTranslation = typedMemo(Translation)
