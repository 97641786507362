export interface IRouteItem {
  path: string
  name: string
}

const authRoutes = {
  login: { path: 'login', name: 'system_page_login__txt_title' },
  logout: { path: 'logout', name: 'system_page_logout__txt_title' },
  resetPassword: {
    path: 'reset-password',
    name: 'system_page_resetPassword__txt_title',
  },
  emailCheck: {
    path: 'check-email',
    name: 'system_page_emailCheck__txt_title',
  },
  confirmNewPassword: {
    path: 'confirm-new-password',
    name: 'system_page_confirmNewPassword__txt_title',
  },
  acceptInvitation: {
    path: 'accept-invitation',
    name: 'system_page_acceptInvitation__txt_title',
  },
  employees: {
    path: 'employees',
    name: 'employees_page__txt_title',
  },
  employeeEdit: {
    path: ':employeeId',
    name: 'employee_edit_page__txt_title',
  },
  companies: {
    path: 'companies',
    name: 'companies_page__txt_title',
  },
  companyEdit: {
    path: ':companyId',
    name: 'company_edit_page__txt_title',
  },
  companyCreate: {
    path: 'create',
    name: 'company_create_page__txt_title',
  },
  eventCalendar: {
    path: 'event-calendar',
    name: 'event-calendar_page__txt_title',
  },
  taskCalendar: {
    path: 'event-calendar',
    name: 'task-calendar_page__txt_title',
  },
  infoBlocks: {
    path: 'info-blocks',
    name: 'info_blocks_page__txt_title',
  },
  infoBlockEdit: {
    path: ':infoBlockSymbolLink',
    name: 'info_block_details_page__txt_title',
  },
  infoBlockCreate: {
    path: 'create',
    name: 'info_blocks_create_page__txt_title',
  },
  projects: {
    path: 'projects',
    name: 'projects_page__txt_title',
  },
  projectsDetail: {
    path: ':projectId',
    name: 'project_detail_page__txt_title',
  },
  projectCreate: {
    path: 'create',
    name: 'project_create_page__txt_title',
  },
  projectEdit: {
    path: 'edit',
    name: 'project_edit_page__txt_title',
  },
  dashboardProjects: {
    path: 'dashboardProjects',
    name: '',
  },
  projectRootCreateRelated: {
    path: 'create-related',
    name: '',
  },
  projectRootCreateTask: {
    path: 'create-task',
    name: '',
  },
  projectCreateRelated: {
    path: ':relatedEntitySymbolKey',
    name: 'entity_related_element_create__txt_title',
  },
  structures: {
    path: 'structure',
    name: '',
  },
  elementCreate: {
    path: 'create',
    name: 'info_block_element_create_page__txt_nav_button_title',
  },
  elementEdit: {
    path: ':elementId',
    name: 'info_block_element_edit_page__txt_title',
  },
  tasksListing: {
    path: 'tasks',
    name: 'tasks_page__txt_title',
  },
  tasksCreate: {
    path: 'create',
    name: 'task_create_page__txt_title',
  },
  tasksEdit: {
    path: 'edit',
    name: 'task_edit_page__txt_title',
  },
  taskDetail: {
    path: ':taskId',
    name: 'task_detail_page__txt_title',
  },
  entity: {
    path: 'entity',
    name: '',
  },
  entityElements: {
    path: ':entitySymbolKey',
    name: 'entity_elements_listing_page__txt_title',
  },
  entityElement: {
    path: ':entityId',
    name: '',
  },
  entityElementCreate: {
    path: 'create',
    name: 'entity_element_create__txt_title',
  },
  entityRootCreateRelated: {
    path: 'create-related',
    name: '',
  },
  entityRootCreateTask: {
    path: 'create-task',
    name: '',
  },
  entityElementCreateRelated: {
    path: ':relatedEntitySymbolKey',
    name: 'entity_related_element_create__txt_title',
  },
  entityElementEdit: {
    path: 'edit',
    name: 'entity_element_edit__txt_title',
  },
  structure: {
    path: 'structure',
    name: 'structure__txt_title',
  },
  user: {
    path: 'user',
    name: 'employee_edit_page__txt_title',
  },
  monitoring: {
    path: 'monitoring',
    name: 'monitoring_page__txt_title',
  },
  monitoringView: {
    path: ':monitoringPage',
    name: 'monitoring_page__txt_title',
  },
  sources: {
    path: 'sources',
    name: 'sources_page__txt_title',
  },
  sourcesList: {
    path: 'list',
    name: 'sources_page__txt_title',
  },
  sourcesGroups: {
    path: 'groups',
    name: 'sources_page__txt_title',
  },
  reports: {
    path: 'reports',
    name: 'reports_page__txt_title',
  },
}

const testRoutes = {
  jwtTest: { path: 'jwt-test', name: 'Jwt-test' },
  ui: { path: 'ui', name: 'Ui' },
  uiAll: { path: 'ui-all', name: 'UiAll' },
  uiButtons: { path: 'ui-buttons', name: 'UiButtons' },
  uiImages: { path: 'ui-images', name: 'UiImages' },
  uiDropdowns: { path: 'ui-dropdowns', name: 'UiDropdowns' },
  uiTime: { path: 'ui-time', name: 'UiTime' },
  uiInputs: { path: 'ui-inputs', name: 'UiInputs' },
  uiNavigation: { path: 'ui-navigation', name: 'UiNavigation' },
  uiDriverWidgets: { path: 'ui-driver-widgets', name: 'UiDriverWidgets' },
  uiPopupForms: { path: 'ui-popup-forms', name: 'UiPopupForms' },
}

const adminRoutes = {
  protocols: {
    path: 'protocols',
    name: 'protocols_page__txt_title',
  },
  protocolsCreate: {
    path: 'create',
    name: 'protocols_page__txt_title',
  },
  mediaPlaning: {
    path: 'media-planing',
    name: 'media_planing_page__txt_title',
  },
}

type TAllRoutes = {
  [key in keyof typeof routes]: IRouteItem
}

const routes = {
  ...adminRoutes,
  ...authRoutes,
  ...testRoutes,
}

export const AllRoutes: TAllRoutes = routes
