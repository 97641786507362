import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Breadcrumbs } from '../../../common/components/Breadcrumbs/Breadcrumbs'
import { AppTranslation } from '../../../common/components/AppTranslation/AppTranslation'
import { AllRoutes } from '../../../core/routes/AllRoutes'
import {
  IFormRowData,
  inputControllerType,
  inputTypeByFieldType,
  IRowItem,
  PageFormController,
} from '../../../common/components/PageFormController/PageFormController'
import { IFormErrorDto } from '../../../core/api/BaseApi'
import { useGoBack } from '../../../common/components/HeaderBackButton/HeaderBackButton'
import {
  AppButton,
  AppButtonColor,
  AppButtonSize,
} from '../../../common/components/AppButton/AppButton'
import { ITaskPriority } from '../../../core/api/dto/TasksDto'
import { IExecutorsState } from '../../../common/components/FormExecutors/FormExecutors'
import {
  AppNotification,
  NotificationType,
} from '../../../common/components/Notification/Notification'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'
import { useUpdateEntityMutation } from 'core/api/BaseApiEndpoints/Entity/EntityApi'
import {
  useCreateProjectMutation,
  useGetProjectByIdQuery,
  useProjectUploadDocumentsMutation,
  useProjectUploadMediaMutation,
  useUpdateProjectMutation,
} from 'core/api/BaseApiEndpoints/Projects/ProjectsApi'

const baseFieldItems: IRowItem[] = [
  {
    type: inputControllerType.input,
    name: 'title',
    label: 'Название проекта',
    colClassName: 'col-12',
    required: 'general__txt_required_field',
  },
  {
    type: inputControllerType.datePicker,
    name: 'start_date',
    label: 'general_date_start__txt_default_label',
    colClassName: 'col-6',
    required: 'general__txt_required_field',
  },
  {
    type: inputControllerType.datePicker,
    name: 'end_date',
    label: 'general_date_end__txt_default_label',
    colClassName: 'col-6',
    required: 'general__txt_required_field',
  },
  {
    type: inputTypeByFieldType[
      'ForeignKey'
    ] as unknown as inputControllerType.infoBlockSelectExtractor,
    dataType: 'responsible',
    name: 'responsible',
    label: 'Ответственный',
    colClassName: 'col-12',
    required: 'general__txt_required_field',
  },
  {
    type: inputControllerType.textarea,
    name: 'description',
    rows: 8,
    label: 'task_create_page__form_description',
    colClassName: 'col-12',
    required: 'general__txt_required_field',
  },
]

const baseMediaItems: IRowItem[] = []

const taskFormConfig: IFormRowData[] = [
  {
    title: null,
    rowItems: [
      {
        type: inputControllerType.baseFields,
        colClassName: 'col-12',
        rowItems: baseFieldItems,
      },
      {
        type: inputControllerType.freeSpace,
        colClassName: 'col-12',
      },
      {
        type: inputTypeByFieldType[
          'ManyToManyField'
        ] as unknown as inputControllerType.infoBlockSelectExtractor,
        dataType: 'coowner',
        name: 'coowner',
        label: 'task_create_page__form_co_executor',
        colClassName: 'col-3',
      },
      {
        type: inputControllerType.input,
        name: 'goal',
        label: 'Цель',
        colClassName: 'col-3',
      },
      {
        type: inputTypeByFieldType[
          'ForeignKey'
        ] as unknown as inputControllerType.infoBlockSelectExtractor,
        dataType: 'importance',
        name: 'importance',
        label: 'Важность',
        colClassName: 'col-3',
      },
      {
        type: inputControllerType.file,
        name: 'task_document',
        label: 'Документы',
        colClassName: 'col-3',
      },
      {
        type: inputTypeByFieldType[
          'ManyToManyField'
        ] as unknown as inputControllerType.infoBlockSelectExtractor,
        dataType: 'planned_material_type',
        name: 'planned_material_type',
        label: 'Сфера',
        colClassName: 'col-3',
      },
      {
        type: inputTypeByFieldType[
          'ManyToManyField'
        ] as unknown as inputControllerType.infoBlockSelectExtractor,
        dataType: 'target_audience',
        name: 'target_audience',
        label: 'Целевая Аудитория',
        colClassName: 'col-3',
      },
      {
        type: inputControllerType.input,
        name: 'link',
        label: 'Ссылка',
        colClassName: 'col-3',
      },
      {
        type: inputControllerType.file,
        name: 'task_media',
        label: 'Медиа',
        colClassName: 'col-3',
      },
      {
        type: inputControllerType.baseFields,
        colClassName: 'col-12',
        rowItems: baseMediaItems,
      },
      {
        type: inputTypeByFieldType[
          'ManyToManyField'
        ] as unknown as inputControllerType.infoBlockSelectExtractor,
        name: 'prohibited_entities',
        dataType: 'prohibited_entities',
        label: 'Недоступные сущности',
        colClassName: 'col-3',
      },
    ],
  },
]

export const ProjectCreateUpdate = () => {
  const { projectId } = useParams()
  const isCreatePage = useMemo(() => !projectId, [projectId])
  const navigate = useNavigate()
  const [createProject] = useCreateProjectMutation()
  const [uploadMedia] = useProjectUploadMediaMutation()
  const [updateProject] = useUpdateProjectMutation()
  const [uploadDocuments] = useProjectUploadDocumentsMutation()
  const { data: detailsData } = useGetProjectByIdQuery(
    { projectId: Number(projectId) },
    {
      skip: isCreatePage,
    },
  )

  const [willSubmit, setWillSubmit] = useState(false)
  const [isPublished, setIsPublished] = useState(false)
  useEffect(() => {
    detailsData?.is_published && setIsPublished(detailsData?.is_published)
  }, [detailsData])
  const [isValidForm, setIsValidForm] = useState(!isCreatePage && isPublished)
  const [formError, setFormError] = useState<IFormErrorDto | null>(null)
  const [isFormDirty, setIsFormDirt] = useState(false)
  const [updateEntity] = useUpdateEntityMutation()

  const { goBackHandler } = useGoBack()
  const backBtnHandler = useCallback(() => {
    if (!isFormDirty) {
      goBackHandler()
    } else alert('Есть несохраненные данные')
  }, [isFormDirty, goBackHandler])

  const companiesBreadcrumbs = useMemo(() => {
    return isCreatePage
      ? [
          { name: 'projects_page__txt_title', path: '../' },
          {
            name: {
              label: 'project_create_page__txt_title',
            },
          },
        ]
      : [
          { name: 'projects_page__txt_title', path: '../../' },
          {
            name: {
              label: 'projects_detail_page__txt_nav_button_title',
              options: {
                projectName: `${detailsData ? detailsData.title : ''}`,
              },
            },
            path: '../',
          },
          {
            name: isCreatePage
              ? 'project_create_page__txt_title'
              : 'project_edit_page__txt_title',
          },
        ]
  }, [isCreatePage, projectId])

  const formDataChangeHandler = useCallback(
    (
      {
        executor,
        task_document,
        task_media,
        entity,
        target_audience,
        planned_material_type,
        importance,
        goal,
        link,
        responsible,
        prohibited_entities,
        coowner,
        is_published,
        ...data
      }: {
        name: string
        priority: ITaskPriority
        end_date: Date
        description: string
        executor: IExecutorsState
        task_document: FileList
        task_media: FileList
        entity: any
        target_audience: any
        planned_material_type: any
        importance: any
        goal: string
        link: string
        responsible: any
        prohibited_entities: any
        coowner: any
        is_published: boolean
      },
      isValid: boolean,
    ) => {
      if (isValid || !isPublished) {
        const fetchArray: string[] = []
        if (task_document && task_document.length > 0) {
          fetchArray.push('isDocumentsDirty')
        }
        if (task_media && task_media.length > 0) {
          fetchArray.push('isMediaDirty')
        }

        const processArray = async (array: string[], projectId: number) => {
          const result = []
          for (const item of array) {
            switch (item) {
              case 'isDocumentsDirty':
                const formDocData = new FormData()
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < task_document.length; i++) {
                  formDocData.append(
                    'file',
                    task_document[i],
                    task_document[i].name,
                  )
                }
                result.push(
                  await uploadDocuments({
                    projectId: projectId,
                    data: formDocData,
                  }),
                )
                break
              case 'isMediaDirty':
                const formMediaData = new FormData()
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < task_media.length; i++) {
                  formMediaData.append(
                    'file',
                    task_media[i],
                    task_media[i].name,
                  )
                }
                result.push(
                  await uploadMedia({
                    projectId: projectId,
                    data: formMediaData,
                  }),
                )
                break

              default:
                break
            }
          }
          return result
        }

        const newData = {
          ...data,
          responsible: responsible?.id,
          co_executor: coowner?.map((item: any) =>
            typeof item === 'number' ? item : item.id,
          ),
          //tasks: ['72'],
          //entities: [100, 132],
          prohibited_entities: prohibited_entities?.map((item: any) =>
            typeof item === 'string' ? item : item?.title,
          ),
          target_audience: target_audience?.map((item: any) =>
            typeof item === 'number' ? item : item.id,
          ),
          planned_material_type: planned_material_type?.map((item: any) =>
            typeof item === 'number' ? item : item.id,
          ),
          importance: importance?.id,
          goal,
          link,
          is_published: isPublished,
        }

        isCreatePage
          ? createProject(newData)
              .unwrap()
              .then((item) => {
                if (fetchArray.length > 0) {
                  processArray(fetchArray, item.id)
                    .then((result) => {
                      const isAnyError =
                        result.findIndex(
                          (item) => item && 'error' in item && item.error,
                        ) >= 0

                      if (!isAnyError) {
                        setIsFormDirt(false)
                        AppNotification({
                          msg: [t('general__msg_saved')],
                          type: NotificationType.success,
                        })
                        navigate(`../${item.id}`)
                      }
                    })
                    .catch((error) => setFormError(error))
                } else {
                  AppNotification({
                    msg: [t('general__msg_saved')],
                    type: NotificationType.success,
                  })
                  navigate(`../${item.id}`)
                }
                console.log('fff', entity.entity[0])
                updateEntity({
                  entitySymbolKey: entity.entity[0].nameSchema,
                  body: { tasks: [`${item.id}`] },
                  entityId: entity.entity[0].pk,
                })
              })
              .catch((error) => setFormError(error))
          : updateProject({ body: newData, projectId: projectId! })
              .unwrap()
              .then((item) => {
                if (fetchArray.length > 0) {
                  processArray(fetchArray, item.id)
                    .then((result) => {
                      const isAnyError =
                        result.findIndex(
                          (item) => item && 'error' in item && item.error,
                        ) >= 0

                      if (!isAnyError) {
                        setIsFormDirt(false)
                        AppNotification({
                          msg: [t('general__msg_saved')],
                          type: NotificationType.success,
                        })
                        navigate(`/projects/${item.id}`)
                      }
                    })
                    .catch((error) => setFormError(error))
                } else {
                  AppNotification({
                    msg: [t('general__msg_saved')],
                    type: NotificationType.success,
                  })
                  navigate(`/projects/${item.id}`)
                }
              })
              .catch((error) => setFormError(error))
      }
      setWillSubmit(false)
    },
    [createProject, updateProject, navigate, isPublished],
  )
  console.log('detailsData', isPublished)

  let fields: any =
    !isCreatePage && (detailsData?.is_published)
      ? ['title', 'description', 'start_date', 'end_date', 'responsible']
      : []
  const isValid = useCallback(
    (isValid: any) => {
      if (fields.length == 0) fields.push(Object.keys(isValid).join())
      else {
        let currentField = fields.findIndex(
          (field: any) => Object.keys(isValid).join() == field,
        )
        if (currentField == -1) fields.push(Object.keys(isValid).join())
        else if (Object.values(isValid).join() == '') {
          fields.splice(currentField, 1)
        }
      }
      setIsValidForm(
          (fields.includes('start_date') || detailsData?.start_date) &&
          (fields.includes('end_date') || detailsData?.end_date) &&
          (fields.includes('responsible') || detailsData?.responsible) &&
          (fields.includes('title') || detailsData?.title) &&
          (fields.includes('description') || detailsData?.description) 
      )
    },
    [isPublished],
  )

  const dirtyFormCallBack = useCallback(
    (value: boolean) => {
      setIsFormDirt(value)
    },
    [setIsFormDirt],
  )

  const initFormState = useMemo(() => {
    if (!isCreatePage) {
      if (!detailsData) {
        return null
      }
      return {
        title: detailsData.title,
        end_date: detailsData.end_date,
        start_date: detailsData.start_date,
        description: detailsData.description,
        creator: detailsData.creator,
        tasks: detailsData.tasks,
        target_audience: detailsData.target_audience,
        planned_material_type: detailsData.planned_material_type,
        importance: detailsData.importance,
        goal: detailsData.goal,
        link: detailsData.link,
        responsible: detailsData?.responsible?.id,
        prohibited_entities: detailsData?.prohibited_entities,
        coowner: detailsData?.co_executor?.map((item: any) => item.id),
        is_published: detailsData?.is_published,
        executor: {
          executor: detailsData.executor,
          co_executor: detailsData.co_executor,
        },
      }
    }
    return {
      title: '',
      end_date: null,
      start_date: null,
      creator: '',
      description: '',
      executor: '',
      tasks: '',
      target_audience: [],
      planned_material_type: [],
      importance: '',
      goal: '',
      link: '',
      responsible: null,
      prohibited_entities: [],
      coowner: [],
      is_published: '',
    }
  }, [isCreatePage, detailsData])

  return (
    <React.Fragment>
      <div className={'back-office-header'}>
        <Breadcrumbs breadcrumbs={companiesBreadcrumbs} />
        <h1>
          <AppTranslation
            label={
              isCreatePage
                ? AllRoutes.projectCreate.name
                : detailsData
                ? detailsData?.title?.toString()
                : AllRoutes.projectEdit.name
            }
          />
        </h1>
      </div>
      <PageFormController
        formData={taskFormConfig}
        willSubmit={willSubmit}
        formDataChangeHandler={formDataChangeHandler}
        fetchError={formError}
        dirtyFormCallBack={dirtyFormCallBack}
        initFormState={initFormState}
        isValid={isValid}
      />
      <div className={'col-4 btn-block'} style={{ marginTop: '30px' }}>
        <AppButton
          onClick={() => {
            setIsPublished(true)
            setWillSubmit(true)
          }}
          color={AppButtonColor.aquaBlue}
          size={AppButtonSize.base}
          title={'general__button_save'}
          disabled={!isValidForm}
        />
        <div style={{ width: '20px' }} />
        {!isPublished &&
        <AppButton
          onClick={() => {
            setIsPublished(false)
            setWillSubmit(true)
          }}
          color={AppButtonColor.lightBlue}
          size={AppButtonSize.base}
          title={'Сохранить как черновик'}
        />}
        <div style={{ width: '20px' }} />
        <AppButton
          onClick={backBtnHandler}
          color={AppButtonColor.lightRed}
          size={AppButtonSize.base}
          title={'general__button_cancel'}
        />
      </div>
    </React.Fragment>
  )
}
