import { ProtectedAvaIco } from 'common/components/ProtectedImageIco/ProtectedImageIco'
import { AppPopup } from '../../../common/components/AppPopup/AppPopup'

interface IAddSourcePopup {
  onClose: () => void
  structureItem: any
}

export const EmployeePopup = ({ onClose, structureItem }: IAddSourcePopup) => {
  return (
    <AppPopup onClose={onClose} popupWidth={600}>
      <div className="employee-header-block">
        <div className="employee-ava">
          <ProtectedAvaIco
            src={structureItem.photo ? structureItem.photo : `/assets/images/ico/person.svg`}
          />
        </div>
        <div className="employee-description">
          <div className="employee-description-main">
            <h2>{structureItem.last_name + ' ' + structureItem.first_name + ' ' + `${structureItem.patronymic || ''}`}</h2>
            <div className="business-unit-name">{structureItem.post}</div>
          </div>
          <div className="employee-description-rows">
            <div className="employee-description-col">
              <div className="employee-description-value">
                <i className="an-ico an-ico-pin"></i>Россия, Санкт-Петербург
              </div>
              <div className="employee-description-value">
                {structureItem.phone && (
                  <a href={'tel:' + structureItem.phone}>
                    <i className="an-ico an-ico-phone"></i>
                    {structureItem.phone}
                  </a>
                )}
              </div>
            </div>
            <div className="employee-description-col">
              <div className="employee-description-value">
                <i className="an-ico an-ico-bag"></i>
                {structureItem.organization_structure}
              </div>
              <div className="employee-description-value">
                {structureItem.email && (
                  <a href={'mailto:' + structureItem.email}>
                    <i className="an-ico an-ico-email"></i>
                    {structureItem.email}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppPopup>
  )
}
