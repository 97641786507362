import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AppRouter } from './core/routes/AppRouter'
import './core/i18n/i18n'

function App() {
  return (
    <>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </>
  )
}

export default App
