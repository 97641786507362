import React from 'react'
import { ITaskStatus } from '../../../core/api/dto/TasksDto'
import { NavLink } from 'react-router-dom'

interface IProps {
  status: ITaskStatus
  name: string
  taskId: number
}

type IColorByStatus = {
  [key in ITaskStatus]: string
}

export const colorByStatus: IColorByStatus = {
  DOING: 'bg-color-Task-label-background-lavender',
  NEW: 'bg-color-Task-label-background-blue',
  DONE: 'bg-color-Task-label-background-green',
  OVERDUE: 'bg-color-Task-label-background-red',
}

export const TaskStatus = ({ status, name, taskId }: IProps) => {
  return (
    <div className="table-cell-title-with-status">
      <div className={`status-color-label ${colorByStatus[status]}`}></div>
      <NavLink to={`/tasks/${taskId}`} className="value">
        {name}
      </NavLink>
    </div>
  )
}
