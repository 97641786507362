import React from 'react'

const IconFavorite: React.FC = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 11.4414L5.15234 10.6758C4.1862 9.80078 3.48438 9.15365 3.04688 8.73438C2.60938 8.3151 2.11719 7.79557 1.57031 7.17578C1.04167 6.55599 0.677083 6 0.476562 5.50781C0.276042 4.9974 0.175781 4.47786 0.175781 3.94922C0.175781 3.05599 0.476562 2.29948 1.07812 1.67969C1.69792 1.0599 2.46354 0.75 3.375 0.75C4.43229 0.75 5.30729 1.16016 6 1.98047C6.69271 1.16016 7.56771 0.75 8.625 0.75C9.53646 0.75 10.293 1.0599 10.8945 1.67969C11.5143 2.29948 11.8242 3.05599 11.8242 3.94922C11.8242 4.66016 11.5872 5.39844 11.1133 6.16406C10.6393 6.92969 10.1198 7.59505 9.55469 8.16016C9.00781 8.72526 8.10547 9.57292 6.84766 10.7031L6 11.4414Z" />
    </svg>
  )
}

export default IconFavorite
